import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';

class SiteLoader extends Component {
    render() {

        if (!this.props.showSiteLoading) {
            return <div></div>
        }

        return (
            <div className="site-loader">
                <div className="loader-wrapper">
                    <div className="loader">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader2" className="loader">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>

                    <div id="loader3" className="loader">
                        <div className="roller"></div>
                        <div className="roller"></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showSiteLoading: state.app.showSiteLoading
    }
}

export default connect(mapStateToProps, null)(SiteLoader);
