export default function (state = {
    applications: null
}, action) {
switch (action.type) {
    case 'SET_APPLICATIONS':
        return {
            ...state,
            applications: action.payload
        }
    default:
}
return state;
}
