import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoute } from '../../actions/appActions';
import { mentorApplicationReq } from '../../actions/applicationActions';
import InputField from '../../components/inputField';
import RadioButton from '../../components/radioButton';
import AutoComplete from  '../../components/autoComplete';
import KeywordField from '../../components/keywordField';
import DropDown from '../../components/dropDown';
import CheckBox from '../../components/checkBox';
import Button from '../../components/button';
import Success from '../../components/success';
import Failed from '../../components/failed';
import PageLoader from '../../components/pageLoader';
import getOptions from '../../modules/getOptions';
import { validateForm } from "../../modules/formValidator";
import { applicationSuccess } from "../../data/texts";
import './styles.scss';

class MentorApplication extends Component {

    state = {
        image: null,
        errorMsg: "",
        view: "start"
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("mentorApplication");
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("mentorApplication")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        if(this.props.mentorApplication.acceptCodeOfConduct.value === "" || !this.props.mentorApplication.acceptCodeOfConduct.value) {
            this.setState({
                errorMsg: "You need to accept Supernove Foundation's code of coduct to continue."
            })
            return;
        }

        //check if we got a image uploaded
        if (this.state.image === null) {
            this.setState({
                errorMsg: "Please upload a profile picture."
            })
            return;
        }

        //all good lets submit
        this.props.mentorApplicationReq({
            submit: {
                email: this.props.mentorApplication.email.value,
                firstName: this.props.mentorApplication.firstName.value,
                surname: this.props.mentorApplication.surname.value,
                nationality: this.props.mentorApplication.nationality.value,
                country: this.props.mentorApplication.country.value,
                firstLang: this.props.mentorApplication.firstLang.value,
                university: this.props.mentorApplication.university.value,
                highestQualification: this.props.mentorApplication.highestQualification.value,
                position: this.props.mentorApplication.position.value,
                researchField: this.props.mentorApplication.researchField.value,
                interests: this.props.mentorApplication.interests.value,
                timezone: this.props.mentorApplication.timezone.value,
                bio: this.props.mentorApplication.bio.value,
                showBio: this.props.mentorApplication.showBio.value === true,
                acceptTerms: new Date().getTime(),
                acceptPrivacy: new Date().getTime(),
            },
            files: {
                image: this.state.image
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /**
     * @method uploadProfileReq
     */
    uploadProfileReq() {
        this.refs.profileUpload.click();
    }

    /**
     * @method profileUploaded
     */
    profileUploaded(e) {
        e.stopPropagation();
        e.preventDefault();

        //get file
        let file = e.target.files[0];

        if (file === undefined) {
            return;
        }

        if (!file.type.includes("image")) {
            this.props.setDialog({
                title: "Error",
                text: "You can only upload images files.",
                actions: [
                    {
                        text: "OK",
                        action: () => {}
                    }
                ]
            })
            return;
        }

        this.setState({
            image: file
        })
    }

    /** 
     * @method render
     */
    render() {

        if(this.props.keywords === null) {
            return <PageLoader />
        }

        if (this.state.view === "success") {
            return (
                <div className="mentor-application route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg={applicationSuccess}
                                routeAfter=""
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="mentor-application route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Your application was not sent successfully. Please contact support. Email: admin@supernovafoundation.org"
                                routeAfter=""
                            />
                        </div>
                    </div>
                </div>
            )
        }

        console.log("University: " + this.props.mentorApplication.university.value);

        return (
            <div className="mentor-application route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Mentor Application Form
                            </h1>
                            <div className="line"></div>
                        </div>
                        <p>
                            Please apply to be a mentor if you are a senior academic 
                            in the field of Physics, self-identify as a woman and would 
                            like to support and encourage young undergraduate and 
                            postgraduate women from around the world. Note: some fields are
                            keyword-based meaning that you may need to click on the pop-up 
                            box before it will be entered into the field. Please contact
                            admin@supernovafoundation.org if you have any problems.
                        </p>
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Email address"
                                    form="mentorApplication"
                                    field="email"
                                    data={this.props.mentorApplication.email}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="First Name"
                                    form="mentorApplication"
                                    field="firstName"
                                    data={this.props.mentorApplication.firstName}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Family name (surname)"
                                    form="mentorApplication"
                                    field="surname"
                                    data={this.props.mentorApplication.surname}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <p className="extra-label">
                                    First Language
                                </p>
                                <AutoComplete
                                    label=""
                                    suggestions={this.props.keywords.languages.map((data) => {
                                        return { label: data }
                                    })}
                                    addSuggestion={(value) => {
                                        this.props.addKeyword({
                                            value: value,
                                            keyword: "languages"
                                        })
                                    }}
                                    resetKeywords={() => {
                                        this.props.resetKeywords("languages");
                                    }}
                                    form="mentorApplication"
                                    field="firstLang"
                                    data={this.props.mentorApplication.firstLang}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                    contains
                                />
                            </div>
                            <div className="row">
                                <p className="extra-label">
                                    Nationality
                                </p>
                                <AutoComplete
                                    label=""
                                    suggestions={getOptions("countries")}
                                    noAddKeywords
                                    form="mentorApplication"
                                    field="nationality"
                                    data={this.props.mentorApplication.nationality}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                    contains
                                />
                            </div>
                            <div className="row">
                                <p className="extra-label">
                                    Country of current residence
                                </p>
                                <AutoComplete
                                    label=""
                                    suggestions={getOptions("countries")}
                                    noAddKeywords
                                    form="mentorApplication"
                                    field="country"
                                    data={this.props.mentorApplication.country}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                    contains
                                />
                            </div>
                            <div className="row">
                                <p className="extra-label">
                                    University or Institute
                                </p>
                                <AutoComplete
                                    suggestions={this.props.keywords.universities.map((data) => {
                                        return { label: data }
                                    })}
                                    addSuggestion={(value) => {
                                        this.props.addKeyword({
                                            value: value,
                                            keyword: "universities"
                                        })
                                    }}
                                    resetKeywords={() => {
                                        this.props.resetKeywords("universities");
                                    }}
                                    form="mentorApplication"
                                    field="university"
                                    data={this.props.mentorApplication.university}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row radio">
                                <RadioButton
                                    label="Highest qualification achieved"
                                    form="mentorApplication"
                                    field="highestQualification"
                                    data={this.props.mentorApplication.highestQualification}
                                    update={this.props.updateField}
                                    options={getOptions("highestQualification")}
                                    disabled={this.props.disableUI}
                                    otherOn
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Position"
                                    form="mentorApplication"
                                    field="position"
                                    data={this.props.mentorApplication.position}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <DropDown
                                    label="Research Field"
                                    form="mentorApplication"
                                    field="researchField"
                                    data={this.props.mentorApplication.researchField}
                                    update={this.props.updateField}
                                    options={getOptions("researchField")}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row multi">
                                <p className="extra-label">
                                    keywords describing what you're interested in (e.g. black holes, cosmology, gravitational waves):
                                </p>
                                <KeywordField
                                    suggestions={this.props.keywords.interests.map((data) => {
                                        return { label: data }
                                    })}
                                    form="mentorApplication"
                                    field="interests"
                                    data={this.props.mentorApplication.interests}
                                    addSuggestion={(value) => {
                                        this.props.addKeyword({
                                            value: value,
                                            keyword: "interests"
                                        })
                                    }}
                                    resetKeywords={() => {
                                        this.props.resetKeywords("interests");
                                    }}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <DropDown
                                    label="Your GMT timezone offset"
                                    form="mentorApplication"
                                    field="timezone"
                                    data={this.props.mentorApplication.timezone}
                                    update={this.props.updateField}
                                    options={getOptions("timezone")}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row multi">
                                <p className="extra-label">
                                    {"One paragraph bio for our website. "}
                                    {/* <span
                                        className="link"
                                        onClick={() => {

                                            if (this.props.disableUI) {
                                                return;
                                            }

                                            this.props.changeRoute("mentorbios")
                                        }}
                                    >
                                        Click here to see examples.
                                    </span> */}

                                    <span>
                                        <a href="https://supernovafoundation.org/mentorbios" target="_blank" rel="noopener noreferrer">Click here to see some examples.</a>
                                    </span>
                                        
                                </p>
                                <InputField
                                    type="text"
                                    label=""
                                    form="mentorApplication"
                                    field="bio"
                                    data={this.props.mentorApplication.bio}
                                    update={this.props.updateField}
                                    multiline
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <CheckBox
                                    label={<p>Make bio public (Mentor bios page)</p>}
                                    form="mentorApplication"
                                    field="showBio"
                                    data={this.props.mentorApplication.showBio}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row file-upload">
                                <p>Please upload a profile picture of yourself (preferably square images)</p>
                                {
                                    this.state.image !== null ?
                                        <p className="file-name">
                                            {"File: " + this.state.image.name}
                                        </p>
                                        :
                                        <div></div>
                                }
                                <input
                                    ref="profileUpload"
                                    type="file"
                                    style={{
                                        display: "none"
                                    }}
                                    accept="image/*"
                                    onChange={this.profileUploaded.bind(this)}
                                />
                                <Button
                                    type="button"
                                    text={this.state.image !== null ? "change" : "upload picture"}
                                    disabled={this.props.disableUI}
                                    onClick={this.uploadProfileReq.bind(this)}
                                />
                            </div>
                            <div className="row">
                                <CheckBox
                                    label={
                                        <p>
                                            {"I agree to the Supernova Foundation "}
                                            {/* <span
                                                className="link"
                                                onClick={() => {

                                                    if (this.props.disableUI) {
                                                        return;
                                                    }

                                                    this.props.changeRoute("codeofconduct")
                                                }
                                                }
                                            >
                                                Terms and Conditions
                                            </span>. */}
                                            <span>
                                                <a href="https://supernovafoundation.org/codeofconduct" target="_blank" rel="noopener noreferrer">Terms and Conditions.</a>
                                            </span>
                                        </p>
                                    }
                                    form="mentorApplication"
                                    field="acceptCodeOfConduct"
                                    data={this.props.mentorApplication.acceptCodeOfConduct}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <CheckBox
                                    label={
                                        <p>
                                            {"I agree to the "}
                                            <span
                                                className="link"
                                                onClick={() => {

                                                    if (this.props.disableUI) {
                                                        return;
                                                    }

                                                    this.props.setDialog({
                                                        title: "",
                                                        text: this.props.legalData.privacy.text,
                                                        actions: [
                                                            {
                                                                text: "CLOSE",
                                                                action: () => {}
                                                            }
                                                        ]
                                                    })
                                                }}
                                            >
                                                Privacy Policy
                                            </span>.
                                        </p>
                                    }
                                    form="mentorApplication"
                                    field="privacy"
                                    data={this.props.mentorApplication.privacy}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="submit"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        mentorApplication: state.forms.mentorApplication,
        keywords: state.app.keywords,
        legalData: state.app.legalData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({type: "CLEAR_FORM", payload: from}),
        changeRoute: (route) => dispatch(changeRoute(route)),
        mentorApplicationReq: (data) => dispatch(mentorApplicationReq(data)),
        setDialog: (data) => dispatch({ type: "SET_DIALOG", payload: data }),
        addKeyword: (data) => dispatch({type: "ADD_KEYWORD", payload: data}),
        resetKeywords: (data) => dispatch({type: "RESET_KEYWORDS", payload: data})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MentorApplication);