export default function (state = {
		loggingIn: false,
		loggedIn: false,
		user: null,
		your: null
	}, action) {
	switch (action.type) {
		case 'LOGINING_IN':
			return {
				...state,
				loggingIn: false
			}
		case 'LOGIN_SUCCESS':
			return {
				...state,
				loggedIn: true,
				loggingIn: false,
				user: action.payload
			}
		case 'UPDATE_PROFILE':
			return {
				...state,
				user: action.payload
			}
		case "LOGOUT":
			return {
				...state,
				loggedIn: false,
				loggingIn: false,
				user: null
			}
		case "SET_YOUR":
			return {
				...state,
				your: action.payload
			}
		default:
	}
	return state;
}
