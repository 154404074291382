export default [
    'Akan',
    'Amharic',
    'Arabic',
    'Assamese',
    'Awadhi',
    'Azerbaijani',
    'Balochi',
    'Belarusian',
    'Bengali (Bangla)',
    'Bhojpuri',
    'Burmese',
    'Cebuano (Visayan)',
    'Chewa',
    'Chhattisgarhi',
    'Chittagonian',
    'Czech',
    'Deccan',
    'Dhundhari',
    'Dutch',
    'Eastern Min (inc. Fuzhou dialect)',
    'English',
    'Español',
    'French',
    'Fula',
    'Gan',
    'German',
    'Greek',
    'Gujarati',
    'Haitian Creole',
    'Hakka',
    'Haryanvi',
    'Hausa',
    'Hiligaynon/Ilonggo (Visayan)',
    'Hindi',
    'Hmong',
    'Hungarian',
    'Igbo',
    'Ilocano',
    'Italian',
    'Japanese',
    'Javanese',
    'Jin',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Kirundi',
    'Kiswahili',
    'Konkani',
    'Korean',
    'Kurdish',
    'Lithuanian',
    'Madurese',
    'Magahi',
    'Maithili',
    'Malagasy',
    'Malay (inc. Indonesian and Malaysian)',
    'Malayalam',
    'Mandarin',
    'Marathi',
    'Marwari',
    'Mauritian Creole',
    'Mossi',
    'Nepali',
    'Northern Min[disputed – discuss]',
    'Odia (Oriya)',
    'Oromo',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Romanian',
    'Russian',
    'Saraiki',
    'Serbo-Croatian',
    'Sepedi',
    'Sesotho',
    'SeTswana',
    'Shona',
    'Sindhi',
    'Sinhalese',
    'Siswati',
    'Somali',
    'Southern Min (inc. Hokkien and Teochew)',
    'Spanish',
    'Sundanese',
    'Swahili',
    'Swedish',
    'Sylheti',
    'Tagalog (Filipino)',
    'Tamil',
    'Telugu',
    'Thai',
    'Turkish',
    'Turkmen',
    'Ukrainian',
    'Urdu',
    'Uyghur',
    'Uzbek',
    'Vietnamese',
    'Wu (inc. Shanghainese)',
    'Xhosa',
    'Xiang',
    'Xitsonga',
    'Yoruba',
    'Yue (inc. Cantonese)',
    'Zhuang',
    'Zulu',
]