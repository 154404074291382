import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoute } from '../../actions/appActions';
import { getApplicationsReq, approveMentee, approveMentor, rejectMentor, rejectMentee } from '../../actions/applicationActions';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '../../components/button';
import Distractor from '../../components/distractor';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import './styles.scss';

class Applications extends Component {

    state = {
        view: 0,
        item: 0,
        sentRequest: false
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.props.clearApplications();
        this.getApplications();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.getApplications();
    }

    /**
     * @method getApplications
     */
    getApplications() {
        if (!this.state.sentRequest && this.props.loggedIn) {
            this.props.getApplicationsReq();
            this.setState({
                sentRequest: true
            })
        }
    }

    /**
     * @method getView
     */
    getView() {

        if (this.props.applications === null) {
            return <Distractor />
        }

        if (this.state.view === 0) {
            return this.getMenteeView()
        }

        return this.getMentorView()
    }

    /**
     * @method getPage
     */
    getPage() {

        if (this.props.applications === null) {
            return <div></div>
        }

        let count = 0;
        if (this.state.view === 0) {
            count = this.props.applications.menteeApplications.length
        } else {
            count = this.props.applications.mentorApplications.length
        }
        if (this.state.item >= count) {
            this.setState({item:count-1})
        }
        return (
            <div className="pages">
                <div
                    className="arrow"
                    onClick={() => {
                        if (this.state.item === 0) { return; }
                        this.setState({
                            item: this.state.item - 1
                        })
                    }}
                >
                    <ArrowLeft />
                </div>
                <div className="page-info">
                    {(this.state.item + 1) + " / " + count}
                </div>
                <div
                    className="arrow"
                    onClick={() => {
                        if (this.state.item + 1 >= count) { 
                            this.setState({item: count-1})
                            return; 
                        }
                        this.setState({
                            item: this.state.item + 1
                        })
                    }}
                >
                    <ArrowRight />
                </div>
            </div>
        )
    }

    /**
     * @method getMenteeView
     */
    getMenteeView() {

        if (this.props.applications.menteeApplications.length === 0) {
            return (
                <div className="items">
                    <p className="none">There are no new mentee applications.</p>
                </div>
            )
        }

        return (
            <div className="items">
                {this.getPage()}
                {
                    this.props.applications.menteeApplications
                        .filter((data, index) => index === this.state.item)
                        .map((data, index) => {
                            return (
                                <div key={index} className="paper-inner">
                                    <div className="back"></div>
                                    <div className="content">
                                        <div className="title-details">
                                            <div className="name">
                                                {data.firstName + " " + data.surname}
                                            </div>
                                            <div className="pic">
                                                <img
                                                    key={data.image}
                                                    alt=""
                                                    src={data.image}
                                                />
                                            </div>
                                        </div>
                                        <div className="all-details">
                                            <div className="row">
                                                <div className="col title">
                                                    Time Zone:
                                            </div>
                                                <div className="col">
                                                    {data.timezone}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Country:
                                            </div>
                                                <div className="col">
                                                    {data.country}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Nationality:
                                            </div>
                                                <div className="col">
                                                    {data.nationality}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    University:
                                            </div>
                                                <div className="col">
                                                    {data.university}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    First Language:
                                            </div>
                                                <div className="col">
                                                    {data.firstLang}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Field of study:
                                            </div>
                                                <div className="col">
                                                    {data.fieldOfStudy}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Interests:
                                            </div>
                                                <div className="col">
                                                    {data.interests.toString()}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Degree studying:
                                            </div>
                                                <div className="col">
                                                    {data.degreeStudying}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Email:
                                            </div>
                                                <div className="col">
                                                    {data.email}
                                                </div>
                                            </div>
                                            <div className="row no-flex">
                                                <p className="title">
                                                    About:
                                            </p>
                                                <p>
                                                    {data.aboutYourself}
                                                </p>
                                            </div>
                                            <div className="row no-flex">
                                                <p className="title">
                                                    Why they are studying physics:
                                            </p>
                                                <p>
                                                    {data.whyYouAreStudyingPhysics}
                                                </p>
                                            </div>
                                            <div className="row no-flex">
                                                <p className="title">
                                                    What they want to join:
                                            </p>
                                                <p>
                                                    {data.whyYouWantToJoin}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="buttons">
                                            <Button
                                                type="button"
                                                text="Accept"
                                                disabled={this.props.disableUI}
                                                className="positive-back"
                                                onClick={() => {
                                                    this.confirm("accept", "mentee", data)
                                                }}
                                            />
                                            <Button
                                                type="button"
                                                text="Reject"
                                                disabled={this.props.disableUI}
                                                className="negative-back"
                                                onClick={() => {
                                                    this.confirm("reject", "mentee", data)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
        )
    }

    /**
     * @method calculateAge
     */
    calculateAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    /**
     * @method getMentorView
     */
    getMentorView() {

        if (this.props.applications.mentorApplications.length === 0) {
            return (
                <div className="items">
                    <p className="none">There are no new mentor applications.</p>
                </div>
            )
        }

        return (
            <div className="items">
                {this.getPage()}
                {
                    this.props.applications.mentorApplications
                    .filter((data, index) => index === this.state.item)
                    .map((data, index) => {
                        return (
                            <div key={index} className="paper-inner">
                                <div className="back"></div>
                                <div className="content">
                                    <div className="title-details">
                                        <div className="name">
                                            {data.firstName + " " + data.surname}
                                        </div>
                                        <div className="pic">
                                            <img
                                                key={data.image}
                                                alt=""
                                                src={data.image}
                                            />
                                        </div>
                                    </div>
                                    <div className="all-details">
                                        <div className="row">
                                            <div className="col title">
                                                Time Zone:
                                            </div>
                                            <div className="col">
                                                {data.timezone}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col title">
                                                Country:
                                            </div>
                                            <div className="col">
                                                {data.country}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col title">
                                                Nationality:
                                            </div>
                                            <div className="col">
                                                {data.nationality}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col title">
                                                Position:
                                            </div>
                                            <div className="col">
                                                {data.position}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col title">
                                                First Language:
                                            </div>
                                            <div className="col">
                                                {data.firstLang}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col title">
                                                Research Field:
                                            </div>
                                            <div className="col">
                                                {data.researchField}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col title">
                                                Interests:
                                            </div>
                                            <div className="col">
                                                {data.interests.toString()}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col title">
                                                University:
                                            </div>
                                            <div className="col">
                                                {data.university}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col title">
                                                Email:
                                            </div>
                                            <div className="col">
                                                {data.email}
                                            </div>
                                        </div>
                                        <div className="row no-flex">
                                            <p className="title">
                                                Bio:
                                            </p>
                                            <p>
                                                {data.bio}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="buttons">
                                        <Button
                                            type="button"
                                            text="Accept"
                                            disabled={this.props.disableUI}
                                            className="positive-back"
                                            onClick={() => {
                                                this.confirm("accept", "mentor", data)
                                            }}
                                        />
                                        <Button
                                            type="button"
                                            text="Reject"
                                            disabled={this.props.disableUI}
                                            className="negative-back"
                                            onClick={() => {
                                                this.confirm("reject", "mentor", data)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    /**
     * @method confirm
     */
    confirm(action, type, userData) {

        this.props.setDialog({
            title: "Confirm Action",
            text: "Please confirm that you want to " + action + " " + userData.firstName + " " + userData.surname + ".",
            actions: [
                {
                    text: "CONFIRM",
                    action: () => {
                        if (action === "accept") {
                            if (type === "mentor") {
                                return this.props.approveMentor(userData)
                            }

                            //mentee
                            this.props.approveMentee(userData)

                        } else {
                            if (type === "mentor") {
                                return this.props.rejectMentor(userData)
                            }

                            //mentee
                            this.props.rejectMentee(userData)
                        }
                    }
                },
                {
                    text: "CANCEL",
                    action: () => {}
                }
            ]
        })
    }

    /** 
     * @method render
     */
    render() {

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Applications
                            </h1>
                            <div className="line"></div>
                        </div>
                        <div className="applications">
                            <AppBar position="static" className="tabs">
                                <Tabs
                                    value={this.state.view}
                                    onChange={(event, value) => {
                                        this.setState({
                                            view: value,
                                            item: 0
                                        });
                                    }}
                                    variant="fullWidth"
                                    classes={{
                                        indicator: "line"
                                    }}
                                >
                                    <Tab
                                        label="Mentees"
                                        className="tab"
                                        classes={{
                                            selected: "selected"
                                        }}
                                    />
                                    <Tab
                                        label="Mentors"
                                        className="tab"
                                        classes={{
                                            selected: "selected"
                                        }}
                                    />
                                </Tabs>
                            </AppBar>
                            {this.getView()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        applications: state.applications.applications,
        disableUI: state.applications.disableUI
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeRoute: (route) => dispatch(changeRoute(route)),
        getApplicationsReq: () => dispatch(getApplicationsReq()),
        clearApplications: () => dispatch({ type: "SET_APPLICATIONS", payload: null }),
        setDialog: (data) => dispatch({ type: "SET_DIALOG", payload: data }),
        approveMentor: (data) => dispatch(approveMentor(data)),
        approveMentee: (data) => dispatch(approveMentee(data)),
        rejectMentor: (data) => dispatch(rejectMentor(data)),
        rejectMentee: (data) => dispatch(rejectMentee(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Applications);