import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

/**
 * @class DialogManager
 * @desc handles all dialogs
 */
class DialogManager extends Component {

    /**
     * @method getButtons
     */
    getButtons() {
        return this.props.dialog.actions.map((item, index) => {
            return (
                <Button
                    key={index}
                    onClick={() => {
                        item.action();
                        this.props.setDialog(null);
                    }}
                    color="primary"
                >
                    {item.text}
                </Button>
            )
        })
    }

    createMarkup() {
        return {__html: this.props.dialog.text};
    }

    /** 
     * @method render
     */
    render() {

        if (this.props.dialog === null) {
            return <div></div>
        }

        return (
            <Dialog
                open={true}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
            >
                <DialogTitle id="alert-dialog-title">
                    {this.props.dialog.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        dangerouslySetInnerHTML={this.createMarkup()}
                    >
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {this.getButtons()}
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dialog: state.app.dialog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDialog: (data) => dispatch({ type: "SET_DIALOG", payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogManager);