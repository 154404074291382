import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './styles.scss';

class CheckboxClass extends Component {

    /**
     * @method handleChange
     */
    handleChange(e) {
        this.updateField(e.target.checked);
    }

    /**
     * @method updateField
     */
    updateField(value) {
        this.props.update({
            form: this.props.form,
            field: this.props.field,
            data: {
                ...this.props.data,
                validated: false,
                errorMsg: "",
                value: value
            }
        })
    }

    /** 
     * @method render
     */
    render() {
        return (
            <div className="checkbox-container">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.props.data.value}
                            onChange={this.handleChange.bind(this)}
                            value={this.props.data.value.toString()}
                            disabled={this.props.disabled}
                            className={
                                (this.props.data.value ? "checked" : "") + 
                                " " +
                                (this.props.data.errorMsg !== "" ? "error" : "")
                            }
                        />
                    }
                    label={this.props.label}
                />
            </div>
        );
    }

}

export default CheckboxClass;