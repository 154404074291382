import React from 'react';
import Parser from 'html-react-parser';
import Button from '../components/button';
import store from '../store';
import { changeRoute } from '../actions/appActions';

/**
 * @method generateRandomID
 */
const generateRandomID = () => {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 6; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

/**
 * @method links
 */
const links = (content, replacements) => {
    if (replacements === undefined) {
        return Parser(content);
    }

    let updatedContent = content;
    for (let i = 0; i < replacements.length; i++) {
        let item = JSON.parse(replacements[i]);

        updatedContent = updatedContent.replace(item.key, "<a id=" + generateRandomID() + " class='custom-link' type=" + item.type + " custom-action=" + item.action + ">" + item.text + "</a>")

    }

    return Parser(updatedContent);

}

/**
 * @method getElement
 */
const getElement = (key, data) => {
    switch (data.type) {
        case 'heading': return (
            <div key={key} className="heading">
                <h1>
                    {links(data.text, data.links)}
                </h1>
                <div className="line"></div>
            </div>
        )
        case 'heading_two': return (
            <h2 key={key}>
                {links(data.text, data.links)}
            </h2>
        )
        case 'html': return (
            links(data.text, data.links)
        )
        case 'paragraph': return (
            <p key={key}>
                {links(data.text, data.links)}
            </p>
        )
        case 'image': return (
            <img alt="" src={data.src} className="content-image"/>
        )
        case 'bullets': return (
            <div key={key} className="bullets">
                <div className="bullets-title">{links(data.title, data.links)}</div>
                <ul>
                    {data.bullets.map((bullet, index) => {
                        return (
                            <li key={index}>
                                {links(bullet, data.links)}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
        case 'button':
            let onClick = () => {
                if (data.actionType === "changeRoute") {
                    store.dispatch(changeRoute(data.action));
                }
            }
            return (
                <Button
                    key={key}
                    type="button"
                    text={data.text}
                    onClick={onClick}
                />
            )
        default:
            return (
                <div key={key} className={data.type}>
                    {links(data.text, data.links)}
                </div>
            )
    }
}

/** 
 * @method contentRendering
 * @desc This module will create the JSX from what it received from the DB
 */
export default (content) => {
    return content.map((data, index) => {
        return getElement(index, data);
    })
}