// this is an auto generated file and its contents will be overwritten during build

const defaultConfig = {
  firebase: {
    apiKey: "AIzaSyCMFLnuLYV-fmAPMVf99Mfd9tIvTQkXXSg",
    authDomain: "thesupernovafoundation.firebaseapp.com",
    databaseURL: "https://thesupernovafoundation.firebaseio.com",
    projectId: "thesupernovafoundation",
    storageBucket: "thesupernovafoundation.appspot.com",
    messagingSenderId: "282342753576"
  }
};

let localConfig;

try {
  localConfig = require('./local').default;
} catch (error) {
  //
}

const config = (localConfig && Object.keys(localConfig).length !== 0) ? localConfig : defaultConfig;

export default config;
