import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { getPageContent } from '../../actions/siteActions';
import PageLoader from '../../components/pageLoader';

class Sponsors extends Component {

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.props.getPageContent();
    }

    /** 
     * @method render
     */
    render() {

        if (this.props.sponsors === null || this.props.sponsors === undefined) {
            return <PageLoader />
        }
        
        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Sponsors
                            </h1>
                            <div className="line"></div>
                        </div>
                        <div className="sponsors">
                            {
                                this.props.sponsors.oad.map((data, index) => {
                                    return (
                                        <div key={index} className="sponsor">
                                            <div className="name">
                                                {data.name}
                                            </div>
                                            <div className="pic">
                                                <img alt="" src={data.pic}/>
                                            </div>
                                            <div className="title">
                                                {data.title}
                                            </div>
                                            <div className="bio">
                                                {data.bio}
                                            </div>
                                            <div className="website">
                                                website:
                                                <span>{data.website}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sponsors: state.app.sponsors
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPageContent: () => dispatch(getPageContent('sponsors'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sponsors);