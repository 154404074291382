import React, { Component } from 'react';
import { connect } from 'react-redux';
import Feedback from '@material-ui/icons/Feedback';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import Event from '@material-ui/icons/Event';
import RateReview from '@material-ui/icons/RateReview';
import { exportDataReq } from '../../actions/appActions';
import './styles.scss';

class ExportData extends Component {

    /**
     * @method getOptions
     */
    getOptions() {

        return [
            {
                text: "users",
                action: () => {this.exportData("users")},
                icon: <SupervisorAccount/>
            },
            {
                text: "suggestions",
                action: () => {this.exportData("suggestions")},
                icon: <Feedback/>
            },
            {
                text: "mentoring sessions",
                action: () => {this.exportData("mentoringSessions")},
                icon: <Event/>
            },
            {
                text: "mentor feedback",
                action: () => {this.exportData("mentorFeedback")},
                icon: <RateReview/>
            },
            {
                text: "mentee feedback",
                action: () => {this.exportData("menteeFeedback")},
                icon: <RateReview/>
            },
        ]

    }

    /**
     * @method exportData
     */
    exportData(table) {

        if(this.props.disableUI) {
            return;
        }

        this.props.exportDataReq({
            table: table,
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method success
     */
    success(data) {

        //clean data
        for (let i = 0; i < data.length; i++) {
            for (const key in data[i]) {
                data[i][key] = data[i][key].replace(/;/g,", ")
                data[i][key] = data[i][key].replace(/\r\n/g," ")
                data[i][key] = data[i][key].replace(/[\r\n]/g," ")
            }
        }

        let csvContent = "data:text/csv;charset=utf-8,";

        //add coloums
        let coloums = data[0];
        for (const key in coloums) {
            csvContent += key  + ";";
        }

        //create next row
        csvContent += "\r\n";

        //loop through data
        data.forEach(function(row) {

            for (const key in row) {
                csvContent += row[key] + ";";
            }

            csvContent += "\r\n";
        });

        //download file
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "exported_data.csv");
        document.body.appendChild(link);
        
        link.click();
    }

    /**
     * @method failed
     */
    failed() {
        this.props.setDialog({
            title: "Error",
            text: "There was an error getting your data. Please contact support.",
            actions: [
                {
                    text: "OK",
                    action: () => {}
                }
            ]
        })
    }

    /** 
     * @method render
     */
    render() {

        return (
            <div className="route export-data">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Export Data
                            </h1>
                            <div className="line"></div>
                        </div>
                        <div className={"options " + (this.props.disableUI ? "disbaled" : "")}>
                            {this.getOptions().map((data, index) => {
                                return (
                                    <div key={index} className="option" onClick={data.action}>
                                        <div className="icon">
                                            {data.icon}
                                        </div>
                                        <div className="label">
                                            {data.text}
                                        </div>
                                    </div>        
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        exportDataReq: (data) => dispatch(exportDataReq(data)),
        setDialog: (data) => dispatch({ type: "SET_DIALOG", payload: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportData);