import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoute } from '../../actions/appActions';
import { logoutReq } from '../../actions/userActions';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Close from '@material-ui/icons/Close';
import Home from '@material-ui/icons/Home';
import Help from '@material-ui/icons/Help';
import Grade from '@material-ui/icons/Grade';
import Description from '@material-ui/icons/Description';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import BorderColor from '@material-ui/icons/BorderColor';
import Visibility from '@material-ui/icons/Visibility';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import Favorite from '@material-ui/icons/Favorite';
import Security from '@material-ui/icons/Security';
import Ballot from '@material-ui/icons/Ballot';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Email from '@material-ui/icons/Email';
import Feedback from '@material-ui/icons/Feedback';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import FindInPage from '@material-ui/icons/FindInPage';
import ImportExport from '@material-ui/icons/ImportExport';
import RateReview from '@material-ui/icons/RateReview';
import Event from '@material-ui/icons/Event';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import { LocalAtm } from '@material-ui/icons';
import './styles.scss';

/**
 * @class Menu
 */
class Menu extends Component {

    state = {
        // menu for users not logged in
        siteMenu: [
            {
                text: "home",
                action: () => this.changeRoute(""),
                icon: <Home />
            },
            {
                text: "what to expect from mentoring",
                action: () => this.changeRoute("whattoexpect"),
                icon: <Help />
            },
            {
                text: "testimonials",
                action: () => this.changeRoute("testimonials"),
                icon: <Grade />
            },
            {
                text: "code of conduct",
                action: () => this.changeRoute("codeofconduct"),
                icon: <Description />
            },
            {
                text: "mentor bios",
                action: () => this.changeRoute("mentorbios"),
                icon: <SupervisedUserCircle />
            },
            {
                text: "apply",
                action: () => this.changeRoute("apply"),
                icon: <BorderColor />
            },
            {
                text: "leadership team",
                action: () => this.changeRoute("leadershipteam"),
                icon: <Visibility />
            },
            {
                text: "the origin story",
                action: () => this.changeRoute("theoriginstory"),
                icon: <LocalLibrary />
            },
            {
                text: "in memoriam",
                action: () => this.changeRoute("inmemoriam"),
                icon: <Favorite />
            },
            {
                text: "sponsors",
                action: () => this.changeRoute("sponsors"),
                icon: <LocalAtm/>
            }
        ],
        mentorMenteeMenu: [
            {
                text: "profile",
                action: () => this.changeRoute("profile"),
                icon: <AccountCircle/>
            },
            {
                text: "send suggestion",
                action: () => this.changeRoute("suggestions"),
                icon: <Feedback/>
            },
            {
                text: "webinars",
                action: () => this.changeRoute("webinars"),
                icon: <PlayCircleFilled/>
            },
        ],
        mentorMenu: [
            // {
            //     text: "your mentees",
            //     action: () => this.changeRoute("your?view=mentees"),
            //     icon: <EmojiPeople/>
            // },
            {
                text: "select mentees",
                action: () => this.changeRoute("findmentees"),
                icon: <FindInPage/>
            },
            {
                text: "feedback",
                action: () => this.changeRoute("mentorfeedback"),
                icon: <RateReview/>
            }
        ],
        menteeMenu: [
            // {
            //     text: "your mentor",
            //     action: () => this.changeRoute("your?view=mentor"),
            //     icon: <EmojiPeople/>
            // },
            {
                text: "feedback",
                action: () => this.changeRoute("menteefeedback"),
                icon: <RateReview/>
            },
            {
                text: "Request Mentoring Session",
                action: () => this.changeRoute("requestmentoringsession"),
                icon: <Event/>
            }
        ],
        dashboardMenu: {
            text: "dashboard",
            action: () => this.changeRoute("dashboard"),
            icon: <Ballot/>
        },
        // menu for users logged in
        secureMenuAdmin: [
            {
                text: "applications",
                action: () => this.changeRoute("applications"),
                icon: <LocalLibrary/>
            },
            {
                text: "register admin",
                action: () => this.changeRoute("registeradmin"),
                icon: <Security/>
            },
            {
                text: "send email",
                action: () => this.changeRoute("sendemail"),
                icon: <Email/>
            },
            {
                text: "export data",
                action: () => this.changeRoute("exportdata"),
                icon: <ImportExport/>
            },
            {
                text: "disable user",
                action: () => this.changeRoute("disableuser"),
                icon: <PersonAddDisabled/>
            }
        ],
        loginMenu: {
            text: "login",
            action: () => this.changeRoute("login"),
            icon: <Security/>
        },
        logoutMenu: {
            text: "logout",
            action: () => {
                this.props.logoutReq();
            },
            icon: <ExitToApp/>
        },
        scrollingDirection: "up",
        lastScrollTop: window.pageYOffset || document.documentElement.scrollTop
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        document.addEventListener('click', this.handleClick.bind(this));
        document.addEventListener('scroll', this.handleScroll.bind(this));
    }

    /**
     * @method changeRoute
     */
    changeRoute(route) {
        this.props.changeRoute(route);
        if (this.props.open) {
            this.toggleMenu()
        }
    }

    /**
     * @method handleScroll
     */
    handleScroll() {

        if (this.props.open) {
            return;
        }

        let scrollingDirection = "";
        let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

        if (st > this.state.lastScrollTop) {
            // downscroll code
            scrollingDirection = "down";
        } else {
            // upscroll code
            scrollingDirection = "up";
        }

        this.setState({
            scrollingDirection: scrollingDirection,
            lastScrollTop: st <= 0 ? 0 : st // For Mobile or negative scrolling
        })
    }

    /**
     * @method handleClick
     */
    handleClick(e) {

        if(this.props.disableUI) {
            return;
        }

        if(this.accountCircle !== null) {
            if (!this.accountCircle.contains(e.target) && this.props.accountOpen) {
                this.props.toggleAccountMenu();
            }
        }

        if (this.burger !== null) {
            if (this.burger.contains(e.target)) {
                return;
            }
        }

        if(this.node !== null) {
            if (!this.node.contains(e.target) && this.props.open) {
                this.toggleMenu();
            }
        }


    }

    /**
     * @method toggleMenu
     */
    toggleMenu() {

        if(this.props.disableUI) {
            return;
        }

        if (!this.props.open) {
            document.body.className = "no-scroll";
        } else {
            document.body.className = "scroll";
        }

        this.props.toggleMenu(!this.props.open)
    }

    /**
     * @method getMenu
     */
    getMenu() {

        if(this.props.loggedIn) {

            let currentRoute = window.location.pathname.replace("/","");

            //if the user is logged in but is on the website show those items as well
            switch (currentRoute) {
                case "":
                case "whattoexpect":
                case "theoriginstory":
                case "codeofconduct":
                case "apply":
                case "testimonials":
                case "menteeapplication":
                case "leadershipteam":
                case "mentorapplication":
                case "mentorbios":
                case "inmemoriam":
                case "sponsors":
                    return [
                        this.state.dashboardMenu,
                        ...this.state.siteMenu,
                        this.state.logoutMenu
                    ]
                default:
                    break;
            }

            if(this.props.user.role === "admin") {
                return [
                    this.state.dashboardMenu,
                    ...this.state.secureMenuAdmin,
                    this.state.logoutMenu
                ];
            }

            if(this.props.user.role === "mentor") {
                return [
                    this.state.dashboardMenu,
                    ...this.state.mentorMenteeMenu,
                    ...this.state.mentorMenu,
                    //add mentor only menu
                    this.state.logoutMenu
                ];
            }

            //mentee
            return [
                this.state.dashboardMenu,
                ...this.state.mentorMenteeMenu,
                ...this.state.menteeMenu,
                //add mentee only menu
                this.state.logoutMenu
            ]
            
        }

        //website menu
        return [ ...this.state.siteMenu, this.state.loginMenu ];
    }

    /**
     * @method logoClicked
     */
    logoClicked() {
        if(this.props.loggedIn) {
            return this.changeRoute("dashboard");
        }
        this.changeRoute("");
    }

    /**
     * @method getAccountMenu
     */
    getAccountMenu() {
        if(this.props.loggedIn) {
            return (
                <div className="options">
                    <div
                    className="option" 
                    onClick={() => {
                        this.props.toggleAccountMenu(!this.props.accountOpen);
                        this.changeRoute("dashboard")
                    }}
                >
                    DASHBOARD
                </div>
                <div
                    className="option" 
                    onClick={() => {
                        this.props.toggleAccountMenu(!this.props.accountOpen);
                        this.props.logoutReq();
                    }}
                >
                    LOGOUT
                </div>
                </div>
            )
        }

        return (
            <div className="options">
                <div
                    className="option" 
                    onClick={() => {
                        this.props.toggleAccountMenu(!this.props.accountOpen);
                        this.props.changeRoute("login");
                    }}
                >
                    LOGIN
                </div>
            </div>
        )
    }

    /** 
     * @method render
     */
    render() {

        return (
            <div
                className="menu"
                style={{
                    top: (this.state.scrollingDirection === "up" ? 0 : -140)
                }}
            >
                <div className="header">
                    <div className="text noselect" onClick={this.logoClicked.bind(this)}>
                        <div className="site-name">The Supernova Foundation</div>
                        <div className="slogan">Mentoring For Women In Physics</div>
                    </div>
                </div>
                <div ref={node => this.accountCircle = node} className="account-circle">
                    <AccountCircle
                        onClick={() => {
                            if(this.props.disableUI) {
                                return;
                            }
                            this.props.toggleAccountMenu(!this.props.accountOpen);
                        }}
                    />
                    <div className={"account-menu " + (this.props.accountOpen ? "show" : "")}>
                        {this.getAccountMenu()}
                    </div>
                </div>
                <div ref={node => this.burger = node} className="burger noselect " onClick={e => this.toggleMenu(e)}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <div className={"overlay " + (this.props.open ? "show" : "")}></div>
                <div ref={node => this.node = node} className={"menu-options noselect " + (this.props.open ? "open" : "")}>
                    <div className="back-button" onClick={this.toggleMenu.bind(this)}>
                        <Close />
                    </div>
                    {
                        this.getMenu().map((data, index) => {
                            return (
                                <div key={index} className="option noselect" onClick={data.action}>
                                    <div className="icon">
                                        {data.icon}
                                    </div>
                                    <div className="label">
                                        {data.text}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        user: state.user.user,
        disableUI: state.app.disableUI,
        open: state.app.menuOpen,
        accountOpen: state.app.accountMenuOpen
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeRoute: (route) => dispatch(changeRoute(route)),
        logoutReq: () => dispatch(logoutReq()),
        toggleMenu: (flag) => dispatch({type: "TOGGLE_MENU", payload: flag}),
        toggleAccountMenu: (flag) => dispatch({type: "TOGGLE_ACCOUNT_MENU", payload: flag})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);