import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from '../../components/inputField';
import Button from '../../components/button';
import Success from '../../components/success';
import Failed from '../../components/failed';
import { validateForm } from "../../modules/formValidator";
import * as helpers from '../../modules/helpers';
import { changePasswordReq } from '../../actions/userActions';
import './styles.scss';

class ChangePassword extends Component {

    state = {
        view: "start",
        errorMsg: "",
        pageType: "change-password"
    }

    /**
     * @method com[]
     */
    componentDidMount() {
        let pageType = helpers.getParameterByName("pageType");

        if(pageType !== "" && pageType !== null) {
            this.setState({
                pageType: pageType
            })
        }

    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("changePassword")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        //password do not match
        if(this.props.changePassword.password.value !== this.props.changePassword.confirm.value) {
            return  this.setState({
                errorMsg: "Passwords do not match. Please try again"
            })

        }

        //all good lets submit
        this.props.changePasswordReq({
            submit: {
                password: this.props.changePassword.password.value
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("changePassword");
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /**
     * @method getHeading
     */
    getHeading() {
        if(this.state.pageType === "change-password") {
            return "Change Password"
        }

        return "Set Password"
    }

    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="Password has been set."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Something went wrong. Please contact support."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content send-email">
                        <div className="heading">
                            <h1>
                                {this.getHeading()}
                            </h1>
                            <div className="line"></div>
                        </div>
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="row">
                                <InputField
                                    type="password"
                                    label="New Password"
                                    form="changePassword"
                                    field="password"
                                    data={this.props.changePassword.password}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    type="password"
                                    label="Confirm Password"
                                    form="changePassword"
                                    field="confirm"
                                    data={this.props.changePassword.confirm}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="SAVE"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        changePassword: state.forms.changePassword
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({type: "CLEAR_FORM", payload: from}),
        changePasswordReq: (data) => dispatch(changePasswordReq(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);