import React from "react";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import "./styles.scss";

function renderInput(inputProps) {
  const { InputProps, ref, ...other } = inputProps;

  return (
    <TextField
      className="auto-complete-input"
      InputProps={{
        inputRef: ref,
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  onClick,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

/**
 * @method IntegrationDownshift
 */
class IntegrationDownshift extends React.Component {
  //state
  state = {
    inputValue: "",
  };

  /**
   * @method updateField
   */
  updateField(value) {
    this.props.update({
      form: this.props.form,
      field: this.props.field,
      data: {
        ...this.props.data,
        validated: false,
        errorMsg: "",
        value: value,
      },
    });
  }

  /**
   * @method handleKeyDown
   */
  handleKeyDown = (event) => {
    const { inputValue } = this.state;
    if (
      this.props.data.value.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      this.updateField("");
    }

    if (event.key === "Backspace") {
      this.updateField("");
    }

    if (!this.props.data.value.length && event.key === "Enter") {
      event.preventDefault();
    }
  };

  /**
   * @method addSuggestion
   */
  addSuggestion() {
    if (
      this.getSuggestions(this.state.inputValue).length === 0 &&
      this.state.inputValue !== ""
    ) {
      //we should add this option as we do not have it
      this.props.addSuggestion(this.state.inputValue);
    }
  }

  /**
   * @method handleInputChange
   */
  handleInputChange = (event) => {
    let value = event.target.value;
    if (this.props.data.value.length) {
      this.updateField("");
    }
    this.setState({ inputValue: value });
    this.updateField(this.props.data.value);
  };

  handleBlur = () => {
    if (this.props.noAddKeywords) {
      this.setState({
        inputValue: "",
      });
    } else {
      if (this.state.inputValue) {
        this.updateField(this.state.inputValue);
      }
    }
  }

  /**
   * @method handleChange
   */
  handleChange = (item) => {
    this.setState({
      inputValue: "",
    });

    this.updateField(item);
  };

  /**
   * @method getSuggestions
   */
  getSuggestions(value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : this.props.suggestions.filter((suggestion) => {
          let keep =
            count < 5 &&
            suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

          if (this.props.contains) {
            keep =
              count < 5 && suggestion.label.toLowerCase().includes(inputValue);
          }

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }

  /**
   * @method getLabel
   */
  getLabel() {
    if (this.props.data.errorMsg !== "") {
      return this.props.data.errorMsg;
    }

    return "start typing...";
  }

  /**
   * @method render
   */
  render() {
    const { inputValue } = this.state;

    return (
      <div className="auto-complete">
        <Downshift
          id="downshift-simple"
          onChange={this.handleChange}
          inputValue={inputValue}
          selectedItem={this.props.data.value}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem,
          }) => (
            <div
              className={
                "auto-complete-container " +
                (this.props.data.errorMsg ? "error" : "")
              }
            >
              {renderInput({
                fullWidth: true,
                InputProps: getInputProps({
                  placeholder: this.getLabel(),
                  onChange: this.handleInputChange,
                  onKeyDown: this.handleKeyDown,
                  value:
                    this.props.data.value === ""
                      ? this.state.inputValue
                      : this.props.data.value,
                  onBlur: this.handleBlur,
                }),
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className="auto-complete-paper" square>
                    {this.getSuggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.label }),
                        highlightedIndex,
                        selectedItem,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

export default IntegrationDownshift;
