import countries from '../data/countries';
import languages from '../data/languages';
import degrees from '../data/degrees';
import yearsOfStudy from '../data/yearsOfStudy';
import researchField from '../data/researchField';
import timezones from '../data/timezones';
import highestQualification from '../data/highestQualification';

export default (option) => {
    switch (option) {
        case "countries":
            return countries.map((data) => {
                return {
                    label: data,
                    value: data
                }
            })
        case "languages":
            return languages.map((data) => {
                return {
                    label: data,
                    value: data
                }
            })
        case "degrees":
            return degrees.map((data) => {
                return {
                    label: data,
                    value: data
                }
            })
        case "yearsOfStudy":
            return yearsOfStudy.map((data) => {
                return {
                    label: data,
                    value: data
                }
            })
        case "researchField":
            return researchField.map((data) => {
                return {
                    label: data,
                    value: data
                }
            })
        case "timezone":
            return timezones.map((data) => {
                return {
                    label: data,
                    value: data
                }
            })
        case "highestQualification":
            return highestQualification.map((data) => {
                return {
                    label: data,
                    value: data
                }
            })
        default:
            break;
    }
}