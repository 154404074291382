import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { getPageContent } from '../../actions/siteActions';
import PageLoader from '../../components/pageLoader';

class LeadershipTeam extends Component {

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.props.getPageContent();
    }

    /** 
     * @method render
     */
    render() {

        if (this.props.leadershipTeam === null) {
            return <PageLoader />
        }

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                LEADERSHIP TEAM
                            </h1>
                            <div className="line"></div>
                        </div>
                        <div className="leadership-team">
                            {
                                this.props.leadershipTeam.leaders.map((data, index) => {
                                    return (
                                        <div key={index} className="person">
                                            <div className="name">
                                                {data.name}
                                            </div>
                                            <div className="pic">
                                                <img alt="" src={data.pic}/>
                                            </div>
                                            <div className="title">
                                                {data.title}
                                            </div>
                                            <div className="bio">
                                                {data.bio}
                                            </div>
                                            <div className="email">
                                                email:
                                                <span>{data.email}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <h2>
                                Advisors and Mediators
                            </h2>
                            {
                                this.props.leadershipTeam.advisorsMediators.map((data, index) => {
                                    return (
                                        <div key={index} className="person">
                                            <div className="name">
                                                {data.name}
                                            </div>
                                            <div className="pic">
                                                <img alt="" src={data.pic}/>
                                            </div>
                                            <div className="bio">
                                                {data.title}
                                            </div>
                                            <div className="email">
                                                email:
                                                <span>{data.email}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        leadershipTeam: state.app.leadershipTeam
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPageContent: () => dispatch(getPageContent('leadershipTeam'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadershipTeam);