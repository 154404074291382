import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPageContent } from '../../actions/siteActions';
import contentRendering from '../../modules/contentRendering';
import PageLoader from '../../components/pageLoader';
import './styles.scss';

class WhatToExpect extends Component {

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.props.getPageContent();
    }

    /** 
     * @method render
     */
    render() {

        if(this.props.whattoexpect === null) {
            return <PageLoader />
        }

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        {contentRendering(this.props.whattoexpect)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        whattoexpect: state.app.whattoexpect
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPageContent: () => dispatch(getPageContent('whatToExpect'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatToExpect);