import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VectorMap } from "react-jvectormap"
import { getMenteesLimitedReq } from '../../actions/menteeActions';
import { getMentorsLimitedReq } from '../../actions/mentorActions';
import Distractor from '../distractor';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import countryCode from '../../data/countryCode';
import './styles.scss';

class WorldMap extends Component {

    state = {
        sentRequest: false,
        firstSet: false,
        checked: "mentors",
        data: null
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.fetchAllUsers();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.fetchAllUsers();
        if(!this.state.firstSet && this.state.data === null && this.props.allMentees !== null && this.props.allMentors !== null) {
            this.setState({
                firstSet: true
            });
            this.setData("allMentors");
        }
    }

    /**
     * @method fetchAllUsers
     */
    fetchAllUsers() {
        if (!this.state.sentRequest) {
            this.setState({
                sentRequest: true
            })
            this.props.getMenteesReq();
            this.props.getMentorsReq();
        }
    }

    /** 
     * @method setData
     */
    async setData(filter) {

        this.setState({
            data: null
        })

        let data = {};

        for (let i = 0; i < this.props[filter].length; i++) {
            let item = this.props[filter][i];
            let getCountryCode = countryCode[item.country];
            if(!data[getCountryCode]) {
                data[getCountryCode] = 1;
            } else {
                data[getCountryCode] = data[getCountryCode] + 1;
            }
        }

        //set the new data
        this.setState({
            data: data
        })

    }

    /**
     * @method getView
     */
    getView() {

        if (this.props.allMentees === null || this.props.allMentors === null || this.state.data === null) {
            return <Distractor />
        }

        return (
            <div>
                <div className="title">Map of Members of the Supernova Foundation</div>
                <div className="filter-row">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.checked === "mentors"}
                                    onChange={() => { 
                                        this.setState({ checked: "mentors" });
                                        this.setData("allMentors");
                                    }}
                                    value="mentors"
                                    color="primary"
                                />
                            }
                            label="All Mentors"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.checked === "mentees"}
                                    onChange={(e) => { 
                                        this.setState({ checked: "mentees" });
                                        this.setData("allMentees");
                                    }}
                                    value="mentees"
                                    color="primary"
                                />
                            }
                            label="All Mentees"
                        />
                    </FormGroup>
                </div>
                <VectorMap
                    map={"world_mill"}
                    backgroundColor="transparent" //change it to blue !!!
                    zoomOnScroll={false}
                    containerStyle={{
                        width: "100%",
                        height: "800px"
                    }}
                    onRegionClick={() => { }}  //gets the country code
                    onRegionTipShow={(e, el, code) => {
                        el.html(el.html() +' (Count - '+ (this.state.data[code] ? this.state.data[code] : 0) + ')');
                    }}
                    containerClassName="map"
                    regionStyle={{
                        initial: {
                            fill: "white",
                            "fill-opacity": 0.9,
                            stroke: "none",
                            "stroke-width": 0,
                            "stroke-opacity": 0
                        },
                        hover: {
                            "fill-opacity": 0.8,
                            cursor: 'pointer'
                        },
                        selected: {
                            fill: '#456990'  //what colour clicked country will be
                        },
                        selectedHover: {
                        }
                    }}
                    regionsSelectable={true}
                    series={{
                        regions: [
                            {
                                values: this.state.data,  //this is your data
                                // scale: ["#146804", "#ff0000"],  //your color
                                // game's here
                                // scale: ["#cbc9e2", "#54278f"],  //your color
                                // game's here
                                scale: [ "#a1dab4", "#41b6c4", "#2c7fb8", "#253494"],  //your color game's here
                                normalizeFunction: "polynomial"
                            }
                        ]
                    }}
                />
            </div>
        )

    }

    /**
     * @method render
     */
    render() {

        return (
            <div className="world-map">
                {this.getView()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        allMentees: state.mentees.allLimited,
        allMentors: state.mentors.allLimited,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMenteesReq: () => dispatch(getMenteesLimitedReq()),
        getMentorsReq: () => dispatch(getMentorsLimitedReq()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorldMap);