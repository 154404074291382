import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkIfAlreadySendFeedbackReq, mentorFeedbackReq } from '../../actions/mentorActions';
import { getMenteesReq } from '../../actions/menteeActions';
import DropDown from '../../components/dropDown';
import Button from '../../components/button';
import Success from '../../components/success';
import Failed from '../../components/failed';
import Distractor from '../../components/distractor';
import RadioButton from '../../components/radioButton';
import InputField from '../../components/inputField';
import Divider from '@material-ui/core/Divider';
import { validateForm } from "../../modules/formValidator";
import * as helpers from "../../modules/helpers";
import './styles.scss';

class MentorFeedback extends Component {

    state = {
        errorMsg: "",
        view: "start",
        checkDone: false,
        docID: helpers.getParameterByName("docID"),
        // docID: "0", // Makes debugging easier
        date: helpers.getParameterByName("date")
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("mentorFeedback");
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.checkMentees();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.checkMentees();
    }

    /**
     * @method checkMentees
     */
    checkMentees() {
        if (!this.state.checkDone && this.props.loggedIn) {

            if (this.props.user.additionalInfo.mentees.length === 0) {
                //this user has no mentees linked to their profile
                return this.setState({
                    view: "noMentees",
                    checkDone: true
                })
            }

            this.setState({
                checkDone: true
            })

            if (this.state.docID !== "") {
                this.props.getMenteesReq();
                return;
            }

            //check if we have sent feedback already
            this.props.checkIfAlreadySendFeedbackReq({
                email: this.props.user.email,
                success: this.checkIfAlreadySendFeedbackRes.bind(this),
                failed: this.failed.bind(this)
            });

        }
    }

    /**
     * @method checkIfAlreadySendFeedbackRes
     */
    checkIfAlreadySendFeedbackRes(data) {

        if (data.found) {
            return this.setState({
                view: "alreadySendFeedback"
            })
        }

        this.props.getMenteesReq();
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //ignore rating if no mentoring session
        if(this.props.mentorFeedback.didYouHaveAMentorSession0.value === "No") {
            this.props.addIgnore({ form: "mentorFeedback", field: "rating0" });
        }

        //ignore rating if no mentoring session
        if(this.props.mentorFeedback.didYouHaveAMentorSession1.value === "No") {
            this.props.addIgnore({ form: "mentorFeedback", field: "rating1" });
        }

        //ignore second mentee if only one
        if (this.props.user.additionalInfo.mentees.length === 1) {
            this.props.addIgnore({ form: "mentorFeedback", field: "didYouHaveAMentorSession1" });
            this.props.addIgnore({ form: "mentorFeedback", field: "rating1" });
        }

        //validate form
        const validate = validateForm("mentorFeedback")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        let mentees = this.props.user.additionalInfo.mentees;

        //all good lets submit
        this.props.mentorFeedbackReq({
            docID: this.state.docID,
            date: this.state.date,
            submit: {
                email: this.props.user.email,
                mentee1: {
                    mentee: mentees[0],
                    didYouHaveAMentorSession: this.props.mentorFeedback.didYouHaveAMentorSession0.value,
                    rating: this.props.mentorFeedback.rating0.value,
                    comments: this.props.mentorFeedback.comments0.value,
                },
                mentee2: {
                    mentee: mentees[1] === undefined ? "" : mentees[1],
                    didYouHaveAMentorSession: this.props.mentorFeedback.didYouHaveAMentorSession1.value,
                    rating: this.props.mentorFeedback.rating1.value,
                    comments: this.props.mentorFeedback.comments1.value,
                }
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /**
     * @method findMentee
     */
    findMentee(mentee) {
        const alMentees = this.props.allMentees;
        for (let i = 0; i < alMentees.length; i++) {
            if (alMentees[i].email === mentee) {
                return alMentees[i];
            }
        }
    }

    /**
     * @method 
     */
    getDate() {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const date = new Date(this.state.date === "" ? new Date() : +this.state.date);

        return monthNames[date.getMonth()] + " " + date.getUTCFullYear();

    }

    /**
     * @method getForm
     */
    getForm() {

        if (!this.props.loggedIn) {
            return <div></div>
        }

        let menteesExtraInfo = {};
        const mentees = this.props.user.additionalInfo.mentees;
        for (let i = 0; i < mentees.length; i++) {
            menteesExtraInfo[mentees[i]] = this.findMentee(mentees[i]);
        }

        return (
            mentees.map((data, index) => {

                if(menteesExtraInfo[data] == null) {
                    return <div></div>
                }

                return (
                    <div key={index}>
                        <Divider className="divider" />
                        <div className="mentee">
                            <span>Mentee:</span>{" " + menteesExtraInfo[data].firstName + " " + menteesExtraInfo[data].surname}
                        </div>
                        <div className="row">
                            <DropDown
                                label="Did you have a mentoring session with this mentee this month?"
                                form="mentorFeedback"
                                field={"didYouHaveAMentorSession" + index}
                                data={this.props.mentorFeedback["didYouHaveAMentorSession" + index]}
                                update={this.props.updateField}
                                options={[
                                    {
                                        label: "Yes",
                                        value: "Yes"
                                    },
                                    {
                                        label: "No",
                                        value: "No"
                                    }
                                ]}
                                disabled={this.props.disableUI}
                            />
                        </div>
                        {
                            this.props.mentorFeedback["didYouHaveAMentorSession" + index].value === "Yes" ?
                                (
                                    <div className="row radio">
                                        <RadioButton
                                            label="Rate your satisfaction with this mentoring session"
                                            form="mentorFeedback"
                                            field={"rating" + index}
                                            data={this.props.mentorFeedback["rating" + index]}
                                            update={this.props.updateField}
                                            options={[
                                                {
                                                    label: "5",
                                                    value: "5"
                                                },
                                                {
                                                    label: "4",
                                                    value: "4"
                                                },
                                                {
                                                    label: "3",
                                                    value: "3"
                                                },
                                                {
                                                    label: "2",
                                                    value: "2"
                                                },
                                                {
                                                    label: "1",
                                                    value: "1"
                                                },
                                            ]}
                                            disabled={this.props.disableUI}
                                        />
                                    </div>
                                )
                                :
                                <div></div>
                        }
                        <div className="row multi">
                            <p className="extra-label">
                                Comments about this mentee (optional)
                            </p>
                            <InputField
                                type="text"
                                label=""
                                form="mentorFeedback"
                                field={"comments" + index}
                                data={this.props.mentorFeedback["comments" + index]}
                                update={this.props.updateField}
                                multiline
                                disabled={this.props.disableUI}
                            />
                        </div>
                        <Divider className="divider" />
                    </div>
                )
            })
        )

    }

    /** 
     * @method render
     */
    render() {
        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="Your feedback was sent successfully."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Your feedback was not sent successfully. Please contact support. Email: admin@supernovafoundation.org"
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "noMentees") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Warning"
                                warning
                                msg="You have not selected any mentees. Please go to the select mentee tool to add a mentee to your profile."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "alreadySendFeedback") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Warning"
                                warning
                                msg="You have already sent feedback for this month."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.props.allMentees === null) {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <div className="heading">
                                <h1>
                                    Feedback
                                </h1>
                                <div className="line"></div>
                            </div>
                            <Distractor />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="route mentor-feedback">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Feedback
                            </h1>
                            <div className="line"></div>
                        </div>
                        <p className="helper-text-big">
                            {"Please provide feedback for " + this.getDate()}
                        </p>
                        <form onSubmit={this.submit.bind(this)}>
                            {this.getForm()}
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="submit"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mentorFeedback: state.forms.mentorFeedback,
        user: state.user.user,
        disableUI: state.app.disableUI,
        loggedIn: state.user.loggedIn,
        allMentees: state.mentees.all,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        mentorFeedbackReq: (data) => dispatch(mentorFeedbackReq(data)),
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({ type: "CLEAR_FORM", payload: from }),
        addIgnore: (data) => dispatch({ type: "ADD_IGNORE", payload: data }),
        getMenteesReq: () => dispatch(getMenteesReq()),
        checkIfAlreadySendFeedbackReq: (data) => dispatch(checkIfAlreadySendFeedbackReq(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MentorFeedback);