import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from '../../components/inputField';
import { validateForm } from "../../modules/formValidator";
import Button from '../../components/button';
import { loginReq } from '../../actions/userActions';
import { changeRoute } from '../../actions/appActions';
import './styles.scss';

class Login extends Component {

    state = {
        errorMsg: ""
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("login");
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("login")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        //send request
        this.props.loginReq({
            submit: {
                email: this.props.login.email.value,
                password: this.props.login.password.value,
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method success
     */
    success() {
        this.props.changeRoute("dashboard");
    }

    /**
     * @method failed
     */
    failed(error) {
        this.setState({
            errorMsg: error
        })
    }

    /** 
     * @method render
     */
    render() {
        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Login
                            </h1>
                            <div className="line"></div>
                        </div>
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Email address"
                                    form="login"
                                    field="email"
                                    data={this.props.login.email}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    type="password"
                                    label="Password"
                                    form="login"
                                    field="password"
                                    data={this.props.login.password}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="login"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            <div className="forgot-password" onClick={() => {
                                this.props.changeRoute("passwordreset");
                            }}>Forgot password?</div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        login: state.forms.login
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({type: "CLEAR_FORM", payload: from}),
        changeRoute: (route) => dispatch(changeRoute(route)),
        loginReq: (data) => dispatch(loginReq(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);