import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.scss';

class Distractor extends Component {

    /** 
     * @method render
     */
    render() {
        return (
            <div className="distractor-container">
                 <CircularProgress className="distractor" />
            </div>
        );
    }

}

export default Distractor;