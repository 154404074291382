import firebase from '../firebase';

/** 
 * @method changeRoute
 */
export const changeRoute = (route) => {
	return async (dispatch, getState) => {

		//check if the router has been defined
		if(window.changeRoute) {

			window.scrollTo(0,0);
			
			dispatch({type: "TOGGLE_MENU", payload: false});
			dispatch({type: "TOGGLE_ACCOUNT_MENU", payload: false});

			//check if the user needs to go to the profile page
			const state = getState();
			const loggedIn = state.user.loggedIn;
			
			if(loggedIn) {

				let needToUpdate = false;
				const role = state.user.user.role;
				if(role !== "admin" && route !== "changepassword?pageType=setpassword" && route !== "profile") {
					const additionalInfo = state.user.user.additionalInfo;
	
					//check profile data
					if(
						additionalInfo.nationality === "" ||
						additionalInfo.country === "" ||
						additionalInfo.university === "" ||
						additionalInfo.interests === "" ||
						additionalInfo.firstLang === "" ||
						additionalInfo.timezone === "" ||
						additionalInfo.image === ""
					) {
						route = "profile";
						needToUpdate = true;
					}

					if(role === "mentee") {

						//check profile data
						if(
							additionalInfo.fieldOfStudy === "" ||
							additionalInfo.degreeStudying === "" ||
							additionalInfo.yearOfStudy === "" ||
							additionalInfo.aboutYourself === "" ||
							additionalInfo.whyYouAreStudyingPhysics === "" ||
							additionalInfo.whyYouWantToJoin === ""
						) {
							route = "profile";
							needToUpdate = true;
						}

					}

					if(role === "mentor") {

						//check profile data
						if(
							additionalInfo.position === "" ||
							additionalInfo.researchField === "" ||
                            additionalInfo.bio === "" ||
                            additionalInfo.highestQualification === "" ||
                            additionalInfo.highestQualification === undefined ||
                            additionalInfo.highestQualification === null
						) {
							route = "profile";
							needToUpdate = true;
						}

					}
				}

				if(needToUpdate) {
					dispatch({ 
						type: "SET_DIALOG",
						payload: {
							title: "Warning",
							text: "Your profile contains missing information. Please ensure all fields are completed including profile picture.",
							actions: [
								{
									text: "OK",
									action: () => {}
								}
							]
						}
					})
				}

			}
			
			//change route
			window.changeRoute(route);
			document.body.className = "scroll";
		
		} else {
			//check for when route is defined
			setTimeout(() => {
				dispatch(changeRoute(route));
			},250)
		}
        
	}
}

/**
 * @method sendEmailReq
 */
export const sendEmailReq = (data) => {
    return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add token for security
			data.submit.token = localStorage.getItem("token");

			//add record to the database
			const sendEmail = firebase.functions().httpsCallable('sendEmailReq');
			const sendEmailRes = await sendEmail(data.submit);

			if(sendEmailRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/**
 * @method sendSuggestionReq
 */
export const sendSuggestionReq = (data) => {
    return async (dispatch, getState) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add token for security
			data.submit.token = localStorage.getItem("token");

			//add the user that this is from
			const state = getState();
			const user = state.user.user;
			data.submit.from = user.email;

			//add record to the database
			const sendSuggestion = firebase.functions().httpsCallable('sendSuggestionReq');
			const sendSuggestionRes = await sendSuggestion(data.submit);

			if(sendSuggestionRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method getWebinarsReq
 */
export const getWebinarsReq = () => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get all applications
			const getWebinars = firebase.functions().httpsCallable('getWebinarsReq');
			const getWebinarsRes = await getWebinars({
				token: localStorage.getItem("token")
			});
			
			if(getWebinarsRes.data.success) {
				dispatch({ type: "SET_WEBINARS", payload: getWebinarsRes.data.webinairs });
			} else {
				dispatch({ type: "SET_WEBINARS", payload: [] });
			}

		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({type: "TOGGLE_DISABLE_UI", payload: false});

	}
}

/** 
 * @method exportDataReq
 */
export const exportDataReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get all applications
			const exportData = firebase.functions().httpsCallable('exportDataReq');
			const exportDataRes = await exportData({
				token: localStorage.getItem("token"),
				table: data.table
			});
			
			if(exportDataRes.data.success) {
				data.success(exportDataRes.data.table)
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({type: "TOGGLE_DISABLE_UI", payload: false});

	}
}

/** 
 * @method getMentoringSessionInfo
 */
export const getMentoringSessionInfo = (id, callback) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({type: "timeslots", payload: null})
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get all applications
			const getMentoringSessionInfo = firebase.functions().httpsCallable('getMentoringSessionInfoReq');
			const getMentoringSessionInfoRes = await getMentoringSessionInfo({
				token: localStorage.getItem("token"),
				id: id
			});
			
			if(getMentoringSessionInfoRes.data.success) {
				console.log('appActions, ln287');
				console.log('success');
				console.log(getMentoringSessionInfoRes.data.info);
				dispatch({type: "SET_TIMESLOT", payload: getMentoringSessionInfoRes.data.info})
			} else {
				dispatch({type: "SET_TIMESLOT", payload: []})
			}

		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({type: "TOGGLE_DISABLE_UI", payload: false});
		console.log('About to call gotInfo()');
		callback();

	}
}

/** 
 * @method confirmMentoringSessionReq
 */
export const confirmMentoringSessionReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const confirmMentoringSession = firebase.functions().httpsCallable('confirmMentoringSessionReq');
			const confirmMentoringSessionRes = await confirmMentoringSession({
				token: localStorage.getItem("token"),
				submit: data.submit,
				id: data.id,
				role: data.role,
				name: data.name
			});

			if(confirmMentoringSessionRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method disableUserReq
 */
export const disableUserReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const disableUser = firebase.functions().httpsCallable('disableUserReq');
			const disableUserRes = await disableUser({
				token: localStorage.getItem("token"),
				email: data.email
			});

			if(disableUserRes.data.success) {
				data.success();
			} else {
				data.failed(disableUserRes.data.msg);
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}