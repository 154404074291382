import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoute } from '../../actions/appActions';
import { menteeApplicationReq } from '../../actions/applicationActions';
import InputField from '../../components/inputField';
import KeywordField from '../../components/keywordField';
import AutoComplete from '../../components/autoComplete';
import DropDown from '../../components/dropDown';
import RadioButton from '../../components/radioButton';
import CheckBox from '../../components/checkBox';
import Button from '../../components/button';
import Success from '../../components/success';
import Failed from '../../components/failed';
import PageLoader from '../../components/pageLoader';
import getOptions from '../../modules/getOptions';
import { validateForm } from "../../modules/formValidator";
import { applicationSuccess } from "../../data/texts";
import './styles.scss';

class MenteeApplication extends Component {

    state = {
        image: null,
        errorMsg: "",
        view: "start"
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("menteeApplication");
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("menteeApplication")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        if(this.props.menteeApplication.acceptCodeOfConduct.value === "" || !this.props.menteeApplication.acceptCodeOfConduct.value) {
            this.setState({
                errorMsg: "You need to accept Supernove Foundation's code of coduct to continue."
            })
            return;
        }

        //check if we got a image uploaded
        if (this.state.image === null) {
            this.setState({
                errorMsg: "Please upload a profile picture."
            })
            return;
        }

        //all good lets submit
        this.props.menteeApplicationReq({
            submit: {
                email: this.props.menteeApplication.email.value,
                firstName: this.props.menteeApplication.firstName.value,
                surname: this.props.menteeApplication.surname.value,
                nationality: this.props.menteeApplication.nationality.value,
                firstLang: this.props.menteeApplication.firstLang.value,
                country: this.props.menteeApplication.country.value,
                university: this.props.menteeApplication.university.value,
                fieldOfStudy: this.props.menteeApplication.fieldOfStudy.value,
                interests: this.props.menteeApplication.interests.value,
                degreeStudying: this.props.menteeApplication.degreeStudying.value,
                yearOfStudy: this.props.menteeApplication.yearOfStudy.value,
                timezone: this.props.menteeApplication.timezone.value,
                aboutYourself: this.props.menteeApplication.aboutYourself.value,
                whyYouAreStudyingPhysics: this.props.menteeApplication.whyYouAreStudyingPhysics.value,
                whyYouWantToJoin: this.props.menteeApplication.whyYouWantToJoin.value,
                acceptTerms: new Date().getTime(),
                acceptPrivacy: new Date().getTime(),
            },
            files: {
                image: this.state.image
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /**
     * @method uploadProfileReq
     */
    uploadProfileReq() {
        this.refs.profileUpload.click();
    }

    /**
     * @method profileUploaded
     */
    profileUploaded(e) {
        e.stopPropagation();
        e.preventDefault();

        //get file
        let file = e.target.files[0];

        if (file === undefined) {
            return;
        }

        if (!file.type.includes("image")) {
            this.props.setDialog({
                title: "Error",
                text: "You can only upload images files.",
                actions: [
                    {
                        text: "OK",
                        action: () => {}
                    }
                ]
            })
            return;
        }

        this.setState({
            image: file
        })
    }

    /** 
     * @method render
     */
    render() {

        if(this.props.keywords === null) {
            return <PageLoader />
        }

        if (this.state.view === "success") {
            return (
                <div className="mentee-application route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg={applicationSuccess}
                                routeAfter=""
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="mentee-application route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Your application was not sent successfully. Please contact support. Email: admin@supernovafoundation.org"
                                routeAfter=""
                            />
                        </div>
                    </div>
                </div>
            )
        }

        console.log("University: " + this.props.menteeApplication.university.value);

        return (
            <div className="mentee-application route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Mentee Application Form
                            </h1>
                            <div className="line"></div>
                        </div>
                        <p>
                            Please apply to be a mentee in the Supernova Foundation if
                            you are an undergraduate or postgraduate student in Physics,
                            self-identify as a woman and would like to receive support and
                            encouragement from a senior woman mentor. Note: some fields are
                            keyword-based meaning that you may need to click on the pop-up 
                            box before it will be entered into the field. Please contact
                            admin@supernovafoundation.org if you have any problems.
                        </p>
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Email address"
                                    form="menteeApplication"
                                    field="email"
                                    data={this.props.menteeApplication.email}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="First Name"
                                    form="menteeApplication"
                                    field="firstName"
                                    data={this.props.menteeApplication.firstName}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Family name (surname)"
                                    form="menteeApplication"
                                    field="surname"
                                    data={this.props.menteeApplication.surname}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <p className="extra-label">
                                    Nationality
                                </p>
                                <AutoComplete
                                    label=""
                                    suggestions={getOptions("countries")}
                                    noAddKeywords
                                    form="menteeApplication"
                                    field="nationality"
                                    data={this.props.menteeApplication.nationality}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                    contains
                                />
                            </div>
                            <div className="row">
                                <p className="extra-label">
                                    First Language
                                </p>
                                <AutoComplete
                                    suggestions={this.props.keywords.languages.map((data) => {
                                        return { label: data }
                                    })}
                                    addSuggestion={(value) => {
                                        this.props.addKeyword({
                                            value: value,
                                            keyword: "languages"
                                        })
                                    }}
                                    resetKeywords={() => {
                                        this.props.resetKeywords("languages");
                                    }}
                                    form="menteeApplication"
                                    field="firstLang"
                                    data={this.props.menteeApplication.firstLang}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                    
                                />
                            </div>
                            <div className="row">
                                <p className="extra-label">
                                    Country of current residence
                                </p>
                                <AutoComplete
                                    label=""
                                    suggestions={getOptions("countries")}
                                    noAddKeywords
                                    form="menteeApplication"
                                    field="country"
                                    data={this.props.menteeApplication.country}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                    contains
                                />
                            </div>
                            <div className="row">
                                <p className="extra-label">
                                    University or Institute
                                </p>
                                <AutoComplete
                                    suggestions={this.props.keywords.universities.map((data) => {
                                        return { label: data }
                                    })}
                                    addSuggestion={(value) => {
                                        this.props.addKeyword({
                                            value: value,
                                            keyword: "universities"
                                        })
                                    }}
                                    resetKeywords={() => {
                                        this.props.resetKeywords("universities");
                                    }}
                                    form="menteeApplication"
                                    field="university"
                                    data={this.props.menteeApplication.university}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <DropDown
                                    label="Field of Study"
                                    form="menteeApplication"
                                    field="fieldOfStudy"
                                    data={this.props.menteeApplication.fieldOfStudy}
                                    update={this.props.updateField}
                                    options={getOptions("researchField")}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row multi">
                                <p className="extra-label">
                                    keywords describing what you're interested in (e.g. black holes, cosmology, gravitational waves):
                                </p>
                                <KeywordField
                                    suggestions={this.props.keywords.interests.map((data) => {
                                        return { label: data }
                                    })}
                                    form="menteeApplication"
                                    field="interests"
                                    data={this.props.menteeApplication.interests}
                                    addSuggestion={(value) => {
                                        this.props.addKeyword({
                                            value: value,
                                            keyword: "interests"
                                        })
                                    }}
                                    resetKeywords={() => {
                                        this.props.resetKeywords("interests");
                                    }}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row radio">
                                <RadioButton
                                    label="Degree you are studying towards:"
                                    form="menteeApplication"
                                    field="degreeStudying"
                                    data={this.props.menteeApplication.degreeStudying}
                                    update={this.props.updateField}
                                    options={getOptions("degrees")}
                                    disabled={this.props.disableUI}
                                    otherOn
                                />
                            </div>
                            <div className="row radio">
                                <RadioButton
                                    label="Year of study in your current degree:"
                                    form="menteeApplication"
                                    field="yearOfStudy"
                                    data={this.props.menteeApplication.yearOfStudy}
                                    update={this.props.updateField}
                                    options={getOptions("yearsOfStudy")}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <DropDown
                                    label="Your GMT timezone offset"
                                    form="menteeApplication"
                                    field="timezone"
                                    data={this.props.menteeApplication.timezone}
                                    update={this.props.updateField}
                                    options={getOptions("timezone")}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row multi">
                                <p className="extra-label">
                                    Tell us a bit about yourself, especially your
                                    studies, your interests and the successes and
                                    difficulties you have faced in your career
                                    (maximum 200 words):
                                </p>
                                <InputField
                                    type="text"
                                    label=""
                                    form="menteeApplication"
                                    field="aboutYourself"
                                    data={this.props.menteeApplication.aboutYourself}
                                    update={this.props.updateField}
                                    multiline
                                    wordLimit="200"
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row multi">
                                <p className="extra-label">
                                    Tell us why you chose to study Physics and what
                                    you're hoping to do with your degree (maximum 200
                                    words)
                                </p>
                                <InputField
                                    type="text"
                                    label=""
                                    form="menteeApplication"
                                    field="whyYouAreStudyingPhysics"
                                    data={this.props.menteeApplication.whyYouAreStudyingPhysics}
                                    update={this.props.updateField}
                                    multiline
                                    wordLimit="200"
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row multi">
                                <p className="extra-label">
                                    Tell us why you want to join the Supernova Foundation
                                    and what you hope to get from mentoring (maximum 200
                                    words)
                                </p>
                                <InputField
                                    type="text"
                                    label=""
                                    form="menteeApplication"
                                    field="whyYouWantToJoin"
                                    data={this.props.menteeApplication.whyYouWantToJoin}
                                    update={this.props.updateField}
                                    multiline
                                    wordLimit="200"
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row file-upload">
                                <p>Please upload a profile picture of yourself (preferably square images)</p>
                                {
                                    this.state.image !== null ?
                                        <p className="file-name">
                                            {"File: " + this.state.image.name}
                                        </p>
                                        :
                                        <div></div>
                                }
                                <input
                                    ref="profileUpload"
                                    type="file"
                                    style={{
                                        display: "none"
                                    }}
                                    accept="image/*"
                                    onChange={this.profileUploaded.bind(this)}
                                />
                                <Button
                                    type="button"
                                    text={this.state.image !== null ? "change" : "upload picture"}
                                    disabled={this.props.disableUI}
                                    onClick={this.uploadProfileReq.bind(this)}
                                />
                            </div>
                            <div className="divider">
                                
                            </div>
                            <div className="row">
                                <CheckBox
                                    label={
                                        <p>
                                            {"I agree to the Supernova Foundation "}
                                            {/* <span
                                                className="link"
                                                onClick={() => {

                                                    if (this.props.disableUI) {
                                                        return;
                                                    }

                                                    this.props.changeRoute("codeofconduct")
                                                }
                                                }
                                            >
                                                Terms and Conditions
                                            </span>. */}
                                            <span>
                                                <a href="https://supernovafoundation.org/codeofconduct" target="_blank" rel="noopener noreferrer">Terms and Conditions.</a>
                                            </span>
                                        </p>
                                    }
                                    form="menteeApplication"
                                    field="acceptCodeOfConduct"
                                    data={this.props.menteeApplication.acceptCodeOfConduct}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <CheckBox
                                    label={
                                        <p>
                                            {"I agree to the "}
                                            <span
                                                className="link"
                                                onClick={() => {

                                                    if (this.props.disableUI) {
                                                        return;
                                                    }

                                                    this.props.setDialog({
                                                        title: "",
                                                        text: this.props.legalData.privacy.text,
                                                        actions: [
                                                            {
                                                                text: "CLOSE",
                                                                action: () => {}
                                                            }
                                                        ]
                                                    })
                                                }}
                                            >
                                                Privacy Policy
                                            </span>.
                                        </p>
                                    }
                                    form="menteeApplication"
                                    field="privacy"
                                    data={this.props.menteeApplication.privacy}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="submit"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        menteeApplication: state.forms.menteeApplication,
        keywords: state.app.keywords,
        legalData: state.app.legalData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({type: "CLEAR_FORM", payload: from}),
        changeRoute: (route) => dispatch(changeRoute(route)),
        menteeApplicationReq: (data) => dispatch(menteeApplicationReq(data)),
        setDialog: (data) => dispatch({ type: "SET_DIALOG", payload: data }),
        addKeyword: (data) => dispatch({type: "ADD_KEYWORD", payload: data}),
        resetKeywords: (data) => dispatch({type: "RESET_KEYWORDS", payload: data})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenteeApplication);