import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoute } from '../../actions/appActions';
import ThumbDownAlt from '@material-ui/icons/ThumbDownAlt';
import Warning from '@material-ui/icons/Warning';
import Button from '../button';
import './styles.scss';

class Success extends Component {

    /** 
     * @method render
     */
    render() {
        return (
            <div className="failed-container">
                <h1>
                    {this.props.title}
                </h1>
                <div className={"icon " + (this.props.warning ? "warning" : "")}>
                    {
                        this.props.warning ?
                        <Warning/>
                        :
                        <ThumbDownAlt/>
                    }
                </div>
                <h3>
                    {this.props.msg}
                </h3>
                <Button
                    type="button"
                    text="OK"
                    fullWidth={false}
                    onClick={() => {
                        this.props.changeRoute(this.props.routeAfter)
                    }}
                />
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        changeRoute: (route) => dispatch(changeRoute(route))
    }
}

export default connect(null, mapDispatchToProps)(Success);
