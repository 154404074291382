import uploadFile from "./uploadFile";

export function getParameterByName(name) {
  try {
    let url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
    if (!results) return "";
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  } catch (error) {
    return ""
  }
}

export async function uploadProfilePicture(data) {
  const fileName = (new Date()).getTime();
  const downloadURL = await uploadFile(
    "profilePictures/" + fileName + '.' + data.files.image.name.split('.').pop(),
    data.files.image
  );
  const sub1 = downloadURL.split(fileName)[0];
	const sub2 = downloadURL.split(fileName)[1];
  
  return sub1 + "thumbs%2F" + fileName + "_400x400" + sub2;
}
