import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import './styles.scss';

class DropDown extends Component {

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.props.defaultValue) {
            if (this.props.defaultValue !== "") {
                this.updateField(this.props.defaultValue);
            }
        }
    }

    /**
     * @method handleChange
     */
    handleChange(e) {
        this.updateField(e.target.value);

        if(this.props.onChange) {
            this.props.onChange();
        }
    }

    /**
     * @method updateField
     */
    updateField(value) {
        this.props.update({
            form: this.props.form,
            field: this.props.field,
            data: {
                ...this.props.data,
                validated: false,
                errorMsg: "",
                value: value
            }
        })
    }

    /**
     * @method getLabel
     */
    getLabel() {
        if(this.props.data.errorMsg !== "") {
            if(this.props.label === "") {
                return this.props.data.errorMsg;
            }

            return this.props.label + " - " + this.props.data.errorMsg
        }

        return this.props.label
    }

    /** 
     * @method render
     */
    render() {
        return (
            <div className="dropdown-container">
                <FormControl className={"dropdown " + (this.props.data.errorMsg !== "" ? "error" : "")}>
                    <InputLabel htmlFor="age-simple">{this.getLabel()}</InputLabel>
                    <Select
                        value={this.props.data.value}
                        onChange={this.handleChange.bind(this)}
                        inputProps={{
                            name: this.props.label,
                            id: this.props.id + '-simple',
                        }}
                        disabled={this.props.disabled}
                        error={this.props.data.errorMsg !== ""}
                        className="select"
                    >
                        {
                            this.props.options.map((data, index) => {
                                return (
                                    <MenuItem key={index} value={data.value}>{data.label}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        );
    }

}

export default DropDown;