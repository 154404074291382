import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { getPageContent } from '../../actions/siteActions';
import Slider from "react-slick";
import FormatQuote from '@material-ui/icons/FormatQuote';
import PageLoader from '../../components/pageLoader';

class Testimonials extends Component {

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.props.getPageContent();
    }

    /** 
     * @method render
     */
    render() {

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        }

        if (this.props.testimonialsContent === null) {
            return <PageLoader />
        }

        return (
            <div className="route" >
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">

                        <div className="heading">
                            <h1>
                                Testimonials
                            </h1>
                            <div className="line"></div>
                        </div>
                        <Slider {...settings}>
                            {
                                this.props.testimonialsContent.map((data, index) =>
                                    <div key={index} className="testo">

                                        <div className='image'>
                                            <img
                                                className='image'
                                                alt=""
                                                src={data.image}
                                            />
                                        </div>
                                        <div className="text">
                                            <div className="quotes">
                                                <FormatQuote />
                                            </div>
                                            {data.text}
                                        </div>
                                        <div className="name">{data.name}</div>
                                        <div className="details">{data.details}</div>

                                    </div>
                                )
                            }
                        </Slider>
                    </div>

                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        testimonialsContent: state.app.testimonialsContent
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPageContent: () => dispatch(getPageContent('testimonials'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);