import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoute } from '../../actions/appActions';
import { logoutReq } from '../../actions/userActions';
import LocalLibrary from '@material-ui/icons/LocalLibrary';
import Security from '@material-ui/icons/Security';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Email from '@material-ui/icons/Email';
import Feedback from '@material-ui/icons/Feedback';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import FindInPage from '@material-ui/icons/FindInPage';
import ImportExport from '@material-ui/icons/ImportExport';
import RateReview from '@material-ui/icons/RateReview';
import Event from '@material-ui/icons/Event';
import PersonAddDisabled from '@material-ui/icons/PersonAddDisabled';
import Distractor from '../../components/distractor';
import './styles.scss';

class Dashboard extends Component {

    state = {
        mentorMenteeMenu: [
            {
                text: "profile",
                action: () => this.props.changeRoute("profile"),
                icon: <AccountCircle/>
            },
            {
                text: "send suggestion",
                action: () => this.props.changeRoute("suggestions"),
                icon: <Feedback/>
            },
            {
                text: "webinars",
                action: () => this.props.changeRoute("webinars"),
                icon: <PlayCircleFilled/>
            }
        ],
        // menu for users logged in
        secureMenuAdmin: [
            {
                text: "applications",
                action: () => this.props.changeRoute("applications"),
                icon: <LocalLibrary/>
            },
            {
                text: "register admin user",
                action: () => this.props.changeRoute("registeradmin"),
                icon: <Security/>
            },
            {
                text: "send email",
                action: () => this.props.changeRoute("sendemail"),
                icon: <Email/>
            },
            {
                text: "export data",
                action: () => this.props.changeRoute("exportdata"),
                icon: <ImportExport/>
            },
            {
                text: "disable user",
                action: () => this.props.changeRoute("disableuser"),
                icon: <PersonAddDisabled/>
            }
        ],
        mentorMenu: [
            // {
            //     text: "your mentees",
            //     action: () => this.props.changeRoute("your?view=mentees"),
            //     icon: <EmojiPeople/>
            // },
            {
                text: "select mentees",
                action: () => this.props.changeRoute("findmentees"),
                icon: <FindInPage/>
            },
            {
                text: "feedback",
                action: () => this.props.changeRoute("mentorfeedback"),
                icon: <RateReview/>
            }
        ],
        menteeMenu: [
            // {
            //     text: "your mentor",
            //     action: () => this.props.changeRoute("your?view=mentor"),
            //     icon: <EmojiPeople/>
            // },
            {
                text: "feedback",
                action: () => this.props.changeRoute("menteefeedback"),
                icon: <RateReview/>
            },
            {
                text: "Request Mentoring Session",
                action: () => this.props.changeRoute("requestmentoringsession"),
                icon: <Event/>
            }
        ],
        logoutMenu: {
            text: "logout",
            action: () => {
                this.props.logoutReq();
            },
            icon: <ExitToApp/>
        },
    }

    /**
     * @method getOptions
     */
    getOptions() {

        if(!this.props.loggedIn) {
            return []
        }

        if(this.props.user.role === "admin") {
            return [
                ...this.state.secureMenuAdmin,
                this.state.logoutMenu
            ];
        }

        if(this.props.user.role === "mentor") {
            return [
                ...this.state.mentorMenteeMenu,
                ...this.state.mentorMenu,
                this.state.logoutMenu
            ];
        }

        //mentee
        return [
            ...this.state.mentorMenteeMenu,
            ...this.state.menteeMenu,
            this.state.logoutMenu
        ];

    }

    /** 
     * @method render
     */
    render() {
        return (
            <div className="route dashboard">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Dashboard
                            </h1>
                            <div className="line"></div>
                        </div>
                        {this.props.loggedIn ?
                            <div className="options">
                                {this.getOptions().map((data, index) => {
                                    return (
                                        <div key={index} className="option" onClick={data.action}>
                                            <div className="icon">
                                                {data.icon}
                                            </div>
                                            <div className="label">
                                                {data.text}
                                            </div>
                                        </div>        
                                    )
                                })}
                            </div>
                            :
                            <Distractor />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        loggedIn: state.user.loggedIn,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeRoute: (route) => dispatch(changeRoute(route)),
        logoutReq: () => dispatch(logoutReq()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);