import firebase from '../firebase';
const db = firebase.firestore();

/**
 * @method getSiteContent
 * @desc handles all the logic to fetch the content for the site from the DB
 */
export const getSiteContent = () => {
	return async (dispatch, getState) => {

        document.body.className = "no-scroll";

        //get keywords
        const keywords = await db.collection("keywords").doc("keywords").get();
        const keywordsData = keywords.data();
        dispatch({ type: "SET_KEYWORDS", payload: keywordsData });

        //get legal wording
		const legal = await db.collection("legal").get();
		const legalData = {}
		legal.forEach((doc) => {
			legalData[doc.id] = doc.data();
		});
        dispatch({ type: "SET_LEGAL_WORDS", payload: legalData });
 
        //we are done
		const state = getState();
		const loggingIn = state.user.loggingIn;
		if(!loggingIn) {
            dispatch({type: "TOGGLE_SITE_LOADING", payload: false});
		}
		document.body.className = "scroll";
		
	}
}

/**
 * @method getPageContent
 * @desc handles all the logic to fetch the content for pages site from the DB
 */
export const getPageContent = page => {
	return async (dispatch, getState) => {
        switch (page) {
                
            //get home data
            case "home": {
                const homeContent = await db.collection("siteContent").doc("home").collection("fields").get();
                let homeContentFields = [];
                homeContent.forEach((doc) => {
                    homeContentFields.push(doc.data())
                });
                dispatch({type: "SET_HOME_CONTENT", payload: homeContentFields});
                break;
            }

            //get what to expect
            case "whatToExpect": {
                const whatToExpectContent = await db.collection("siteContent").doc("whattoexpect").collection("fields").get();
                let whatToExpectContentFields = [];
                whatToExpectContent.forEach((doc) => {
                    whatToExpectContentFields.push(doc.data())
                });
                dispatch({type: "SET_WHAT_TO_EXPECT_CONTENT", payload: whatToExpectContentFields});
                break;
            }

            //get code of conduct
            case "codeOfConduct": {
                const codeOfConductContent = await db.collection("siteContent").doc("codeofconduct").collection("fields").get();
                let codeOfConductContentFields = [];
                codeOfConductContent.forEach((doc) => {
                    codeOfConductContentFields.push(doc.data())
                });
                dispatch({type: "SET_CODE_OF_CONDUCT_CONTENT", payload: codeOfConductContentFields});
                break;
            }

            //get the origin story
            case "theOriginStory": {
                const theOriginStoryContent = await db.collection("siteContent").doc("theoriginstory").collection("fields").get();
                let theOriginStoryContentFields = [];
                theOriginStoryContent.forEach((doc) => {
                    theOriginStoryContentFields.push(doc.data())
                });
                dispatch({type: "SET_THE_ORIGINAL_STORY_CONTENT", payload: theOriginStoryContentFields});
                break;
            }
            
            //get in Memoriam
            case "inMemoriam": {
                const inMemoriamContent = await db.collection("siteContent").doc("inmemoriam").collection("fields").get();
                let inMemoriamContentFields = [];
                inMemoriamContent.forEach((doc) => {
                    inMemoriamContentFields.push(doc.data())
                });
                dispatch({type: "SET_IN_MEMORIAM_CONTENT", payload: inMemoriamContentFields});
                break;
            }

            //get apply
            case "apply": {
                const applyContent = await db.collection("siteContent").doc("apply").collection("fields").get();
                let applyContentFields = [];
                applyContent.forEach((doc) => {
                    applyContentFields.push(doc.data())
                });
                dispatch({type: "SET_APPLY_CONTENT", payload: applyContentFields});
                break;
            }

            //get leadership team
            case "leadershipTeam": {
                const leaders = await db.collection("leadershipTeam").doc("team").collection("leaders").get();
                const advisorsMediators = await db.collection("leadershipTeam").doc("team").collection("advisorsMediators").get();
                let leadershipTeam = {
                    leaders: [],
                    advisorsMediators: []
                };
                leaders.forEach((doc) => {
                    leadershipTeam.leaders.push(doc.data())
                });
                advisorsMediators.forEach((doc) => {
                    leadershipTeam.advisorsMediators.push(doc.data())
                });
                dispatch({type: "SET_LEADERSHIP_TEAM", payload: leadershipTeam});
                break;
            }

            //get sponsors
            case "sponsors": {
                const oad = await db.collection("sponsors").doc("page").collection("oad").get();
                let sponsors = {
                    oad: []
                };
                oad.forEach((doc) => {
                    sponsors.oad.push(doc.data())
                });
                dispatch({type: "SET_SPONSORS", payload: sponsors});
                break;
            }

            case "testimonials": {
                //get testimonials
                const testimonialsContent = await db.collection("testimonials").get();
                let testimonialsContentFields = [];
                testimonialsContent.forEach((doc) => {
                    testimonialsContentFields.push(doc.data())
                });
                dispatch({ type: "SET_TESTIMONIALS_CONTENT", payload: testimonialsContentFields });
                break;
            }

            default:
                break;
        }
	}
}
