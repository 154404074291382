import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';

const router = (
	<Switch>
		{
			routes.map((data, key) => {
				return (
					<Route key={key} exact={data.exact} path={data.route} component={data.component}/>
				)
			})
		}
	</Switch>
)

export default router;
