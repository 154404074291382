import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as helpers from '../../modules/helpers';
import Distractor from '../../components/distractor';
import { changeRoute } from '../../actions/appActions';
import { getYoursReq } from '../../actions/userActions';
import './styles.scss';

class Your extends Component {

    state = {
        view: helpers.getParameterByName("view")
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        return this.props.changeRoute("dashboard")
        /*
        if(this.state.view !== "mentor" && this.state.view !== "mentees") {
            return this.props.changeRoute("dashboard")
        }

        this.props.getYoursReq(this.state.view);
        */
    }

    /**
     * @method getYour
     */
    getYour() {
        if(this.props.your === null) {
            return <Distractor />
        }

        if(this.props.your.length === 0) {
            return <div className="no-yours">
                You have no {this.state.view} assigned to you.
            </div>
        }


    }

    /** 
     * @method render
     */
    render() {
        return (
            <div className="route dashboard">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Your {this.state.view}
                            </h1>
                            <div className="line"></div>
                        </div>
                        <div>
                            {
                               this.getYour()
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        your: state.user.your
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeRoute: (route) => dispatch(changeRoute(route)),
        getYoursReq: (type) => dispatch(getYoursReq(type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Your);