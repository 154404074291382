export default function (state = {
    all: null,
    allLimited: null
}, action) {
switch (action.type) {
    case 'SET_MENTEES':
        return {
            ...state,
            all: action.payload
        }
    case 'SET_MENTEES_LIMITED':
        return {
            ...state,
            allLimited: action.payload
        }
    default:
}
return state;
}
