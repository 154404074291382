import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from '../../components/inputField';
import { validateForm } from "../../modules/formValidator";
import Button from '../../components/button';
import { resetPasswordReq } from '../../actions/userActions';
import Success from '../../components/success';
import Failed from '../../components/failed';
import './styles.scss';

class PasswordReset extends Component {

    state = {
        view: "",
        errorMsg: ""
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("passwordReset");
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("passwordReset")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        //send request
        this.props.resetPassword({
            submit: {
                email: this.props.passwordReset.email.value
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="Please check your email for a link to change your password."
                                routeAfter="login"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="We were unable to reset your password. Please contact admin@supernovafoundation.org."
                                routeAfter="login"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Password Reset
                            </h1>
                            <div className="line"></div>
                        </div>
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Email address"
                                    form="passwordReset"
                                    field="email"
                                    data={this.props.passwordReset.email}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="Reset"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        passwordReset: state.forms.passwordReset
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (emailAddress) => dispatch(resetPasswordReq(emailAddress)),
        clearForm: (from) => dispatch({type: "CLEAR_FORM", payload: from}),
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);