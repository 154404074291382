import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from '../../components/inputField';
import { validateForm } from "../../modules/formValidator";
import Button from '../../components/button';
import { registerAdminReq } from '../../actions/userActions';
import Success from '../../components/success';
import Failed from '../../components/failed';
import './styles.scss';

class RegisterAdmin extends Component {

    state = {
        view: "start",
        errorMsg: ""
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("registerAdmin");
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("registerAdmin")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        //send request
        this.props.registerAdminReq({
            submit: {
                email: this.props.registerAdmin.email.value,
                password: this.props.registerAdmin.password.value,
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="User successfuly created."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Error registering user. Please contact developer."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Register Admin User
                            </h1>
                            <div className="line"></div>
                        </div>
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Email address"
                                    form="registerAdmin"
                                    field="email"
                                    data={this.props.registerAdmin.email}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row">
                                <InputField
                                    type="password"
                                    label="Password"
                                    form="registerAdmin"
                                    field="password"
                                    data={this.props.registerAdmin.password}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="register"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        registerAdmin: state.forms.registerAdmin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({type: "CLEAR_FORM", payload: from}),
        registerAdminReq: (data) => dispatch(registerAdminReq(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAdmin);