import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import './styles.scss';

class CheckBoxMultiple extends Component {

    state = {
        otherClicked: false
    }

    /**
     * @method handleChange
     */
    handleChange(e, item) {
        this.updateField(e.target.checked, item);
    }

    /**
     * @method handleChangeOther
     */
    handleChangeOther(e) {
        let value = e.target.value;
        let nextValue = this.removeUnknown([...this.props.data.value]);

        nextValue.push(value);

        this.props.update({
            form: this.props.form,
            field: this.props.field,
            data: {
                ...this.props.data,
                validated: false,
                errorMsg: "",
                value: nextValue
            }
        })
    }

    /**
     * @method removeUnknown
     */
    removeUnknown(array) {
        let newArray = [];
        for (let i = 0; i < array.length; i++) {
            let found = false;
            const check = array[i];
            for (let b = 0; b < this.props.options.length; b++) {
                const element = this.props.options[b];
                if(element === check) {
                    found = true;
                }
            }
            if(found) {
                newArray.push(check)
            }
        }
        return newArray;
    }

    /**
     * @method updateField
     */
    updateField(value, item) {

        let currentValue = [...this.props.data.value];
        let nextValue = [];
        if (currentValue.includes(item) && !value) {

            for (let i = 0; i < currentValue.length; i++) {
                let check = currentValue[i];
                if (item !== check) {
                    nextValue.push(check);
                }
            }

        } else {

            if (value) {
                nextValue.push(item)
                nextValue = nextValue.concat(currentValue);
            }

        }

        if(!this.state.otherClicked) {
            nextValue = this.removeUnknown(nextValue);
        }

        this.props.update({
            form: this.props.form,
            field: this.props.field,
            data: {
                ...this.props.data,
                validated: false,
                errorMsg: "",
                value: nextValue
            }
        })
    }

    /** 
     * @method render
     */
    render() {
        return (
            <div className="checkbox-multiple-container">
                {
                    this.props.options.map((data, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={this.props.data.value.includes(data)}
                                        onChange={(e) => this.handleChange(e, data)}
                                        value={data}
                                        disabled={this.props.disabled}
                                        className={
                                            (this.props.data.value.includes(data) ? "checked" : "") +
                                            " " +
                                            (this.props.data.errorMsg !== "" ? "error" : "")
                                        }
                                    />
                                }
                                label={data}
                            />
                        )
                    })
                }
                {
                    this.props.otherOn ?
                        <FormControlLabel
                            key={this.props.options.length}
                            value="Other"
                            control={
                                <Checkbox
                                    checked={this.state.otherClicked}
                                    onChange={(e) => { 
                                        this.setState({
                                            otherClicked: e.target.checked
                                        })

                                        if(!e.target.checked) {
                                            this.props.update({
                                                form: this.props.form,
                                                field: this.props.field,
                                                data: {
                                                    ...this.props.data,
                                                    validated: false,
                                                    errorMsg: "",
                                                    value: this.removeUnknown(this.props.data.value)
                                                }
                                            })
                                        }
                                    }}
                                    value={"Other"}
                                    disabled={this.props.disabled}
                                    className={
                                        (this.state.otherClicked ? "checked" : "") +
                                        " " +
                                        (this.props.data.errorMsg !== "" ? "error" : "")
                                    }
                                />
                            }
                            label="Other"
                            disabled={this.props.disabled}
                        />
                        :
                        <div></div>
                }
                {
                    this.state.otherClicked ?
                        <TextField
                            label="Other"
                            className={"input-field " + (this.props.data.errorMsg !== "" ? "error" : "")}
                            placeholder="Other"
                            onChange={this.handleChangeOther.bind(this)}
                            margin="normal"
                            type={"text"}
                            disabled={this.props.disabled}
                            error={this.props.data.errorMsg !== ""}
                            fullWidth
                        />
                        :
                        <div></div>
                }
            </div>
        );
    }

}

export default CheckBoxMultiple;