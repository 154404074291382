import React, { Component } from 'react';
import { connect } from 'react-redux';
import { menteeFeedbackReq, checkIfAlreadySendFeedbackReq } from '../../actions/menteeActions';
import DropDown from '../../components/dropDown';
import Button from '../../components/button';
import Success from '../../components/success';
import Failed from '../../components/failed';
import RadioButton from '../../components/radioButton';
import CheckBoxMultiple from '../../components/checkBoxMultiple';
import InputField from '../../components/inputField';
import { validateForm } from "../../modules/formValidator";
import * as helpers from "../../modules/helpers";
import './styles.scss';

class MenteeFeedback extends Component {

    state = {
        errorMsg: "",
        view: "start",
        checkDone: false,
        docID: helpers.getParameterByName("docID"),
        date: helpers.getParameterByName("date")
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("menteeFeedback");
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.checkMentor();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.checkMentor();
    }

    /**
     * @method checkMentor
     */
    checkMentor() {
        if (!this.state.checkDone && this.props.loggedIn) {

            if (this.props.user.additionalInfo.mentor.length === 0) {
                //this user has no mentees linked to their profile
                return this.setState({
                    view: "noMentor",
                    checkDone: true
                })
            }

            this.setState({
                checkDone: true
            });

            if (this.state.docID !== "") {
                return;
            }

            //check if we have sent feedback already
            this.props.checkIfAlreadySendFeedbackReq({
                email: this.props.user.email,
                success: this.checkIfAlreadySendFeedbackRes.bind(this),
                failed: this.failed.bind(this)
            });
        }
    }

    /**
     * @method checkIfAlreadySendFeedbackRes
     */
    checkIfAlreadySendFeedbackRes(data) {

        if (data.found) {
            return this.setState({
                view: "alreadySendFeedback"
            })
        }

    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //add ignore based on did the mentee have a session this month
        if (this.props.menteeFeedback.didYouHaveAMentorSession.value === "No") {
            this.props.addIgnore({ form: "menteeFeedback", field: "whatTopics" });
            this.props.addIgnore({ form: "menteeFeedback", field: "rating" });
            this.props.removeIgnore({ form: "menteeFeedback", field: "ifNotWhyNot" });
        } else {
            this.props.removeIgnore({ form: "menteeFeedback", field: "whatTopics" });
            this.props.removeIgnore({ form: "menteeFeedback", field: "rating" });
            this.props.addIgnore({ form: "menteeFeedback", field: "ifNotWhyNot" });
        }

        //validate form
        const validate = validateForm("menteeFeedback")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        //all good lets submit
        this.props.menteeFeedbackReq({
            docID: this.state.docID,
            date: this.state.date,
            submit: {
                email: this.props.user.email,
                mentor: this.props.user.additionalInfo.mentor,
                didYouHaveAMentorSession: this.props.menteeFeedback.didYouHaveAMentorSession.value,
                ifNotWhyNot: this.props.menteeFeedback.ifNotWhyNot.value,
                rating: this.props.menteeFeedback.rating.value,
                whatTopics: this.props.menteeFeedback.whatTopics.value,
                commentsAboutMentor: this.props.menteeFeedback.commentsAboutMentor.value,
                commentsAboutUs: this.props.menteeFeedback.commentsAboutUs.value,
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /**
     * @method 
     */
    getDate() {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const date = new Date(this.state.date === "" ? new Date() : +this.state.date);

        return monthNames[date.getMonth()] + " " + date.getUTCFullYear();

    }

    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="Your feedback was sent successfully."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Your feedback was not sent successfully. Please contact support. Email: admin@supernovafoundation.org"
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "noMentor") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Warning"
                                warning
                                msg="You have not been matched to a mentor yet."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "alreadySendFeedback") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Warning"
                                warning
                                msg="You have already sent feedback for this month."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Feedback
                            </h1>
                            <div className="line"></div>
                        </div>
                        <p className="helper-text-big">
                            {"Please provide feedback for " + this.getDate()}
                        </p>
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="row">
                                <DropDown
                                    label="Did you have a mentoring session this month?"
                                    form="menteeFeedback"
                                    field="didYouHaveAMentorSession"
                                    data={this.props.menteeFeedback.didYouHaveAMentorSession}
                                    update={this.props.updateField}
                                    options={[
                                        {
                                            label: "Yes",
                                            value: "Yes"
                                        },
                                        {
                                            label: "No",
                                            value: "No"
                                        }
                                    ]}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            {
                                this.props.menteeFeedback.didYouHaveAMentorSession.value === "No" ?
                                    <div className="row radio">
                                        <RadioButton
                                            label="If not, why not?"
                                            form="menteeFeedback"
                                            field="ifNotWhyNot"
                                            data={this.props.menteeFeedback.ifNotWhyNot}
                                            update={this.props.updateField}
                                            options={[
                                                {
                                                    label: "I didn't contact my mentor",
                                                    value: "I didn't contact my mentor"
                                                },
                                                {
                                                    label: "My mentor didn't show up",
                                                    value: "My mentor didn't show up"
                                                },
                                                {
                                                    label: "My mentor didn't confirm a time",
                                                    value: "My mentor didn't confirm a time"
                                                },
                                                {
                                                    label: "My mentor and I both agreed not to meet",
                                                    value: "My mentor and I both agreed not to meet"
                                                },
                                            ]}
                                            disabled={this.props.disableUI}
                                            otherOn
                                        />
                                    </div>
                                    :
                                    <div></div>
                            }
                            {
                                this.props.menteeFeedback.didYouHaveAMentorSession.value === "Yes" ?
                                    <div>
                                        <div className="row radio">
                                            <RadioButton
                                                label="Rate your satisfaction with this mentoring session"
                                                form="menteeFeedback"
                                                field="rating"
                                                data={this.props.menteeFeedback.rating}
                                                update={this.props.updateField}
                                                options={[
                                                    {
                                                        label: "5",
                                                        value: "5"
                                                    },
                                                    {
                                                        label: "4",
                                                        value: "4"
                                                    },
                                                    {
                                                        label: "3",
                                                        value: "3"
                                                    },
                                                    {
                                                        label: "2",
                                                        value: "2"
                                                    },
                                                    {
                                                        label: "1",
                                                        value: "1"
                                                    },
                                                ]}
                                                disabled={this.props.disableUI}
                                            />
                                        </div>
                                        <div className="row multi">
                                            <p className="extra-label">
                                                What topics did you discuss?
                                            </p>
                                            <CheckBoxMultiple
                                                form="menteeFeedback"
                                                field="whatTopics"
                                                data={this.props.menteeFeedback.whatTopics}
                                                update={this.props.updateField}
                                                disabled={this.props.disableUI}
                                                options={[
                                                    "Getting to know each other",
                                                    "My career",
                                                    "Confidence issues",
                                                    "Personal problems",
                                                    "Advice on how to work more effectively",
                                                ]}
                                                otherOn
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div></div>
                            }
                            <div className="row multi">
                                <p className="extra-label">
                                    Comments about your mentor (optional)
                                </p>
                                <InputField
                                    type="text"
                                    label=""
                                    form="menteeFeedback"
                                    field="commentsAboutMentor"
                                    data={this.props.menteeFeedback.commentsAboutMentor}
                                    update={this.props.updateField}
                                    multiline
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            <div className="row multi">
                                <p className="extra-label">
                                    Comments about the Supernova Foundation (optional)
                                </p>
                                <InputField
                                    type="text"
                                    label=""
                                    form="menteeFeedback"
                                    field="commentsAboutUs"
                                    data={this.props.menteeFeedback.commentsAboutUs}
                                    update={this.props.updateField}
                                    multiline
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="submit"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menteeFeedback: state.forms.menteeFeedback,
        user: state.user.user,
        disableUI: state.app.disableUI,
        loggedIn: state.user.loggedIn
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        menteeFeedbackReq: (data) => dispatch(menteeFeedbackReq(data)),
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({ type: "CLEAR_FORM", payload: from }),
        addIgnore: (data) => dispatch({ type: "ADD_IGNORE", payload: data }),
        removeIgnore: (data) => dispatch({ type: "REMOVE_IGNORE", payload: data }),
        checkIfAlreadySendFeedbackReq: (data) => dispatch(checkIfAlreadySendFeedbackReq(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MenteeFeedback);