import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.scss';

class PageLoader extends Component {

    /** 
     * @method render
     */
    render() {
        return (
            <div className="route">
                <div className="paper">
                    <CircularProgress className="page-loader" />
                </div>
            </div>
        );
    }

}

export default PageLoader;