import React, { Component } from 'react';
import { connect } from 'react-redux';
import { disableUserReq } from '../../actions/appActions'
import Success from '../../components/success';
import Failed from '../../components/failed';
import InputField from '../../components/inputField';
import Button from '../../components/button';
import { validateForm } from "../../modules/formValidator";
import './styles.scss';

class DisableUser extends Component {

    state = {
        view: "start",
        errorMsg: ""
    }
    
    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("disableUser");
    }

    /** 
     * @method submit
     */
    submit(e) {
        // console.log('disableUser submit called');
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("disableUser")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            });
            return;
        }
        // console.log('disable user calling disableUserReq..');
        //all good lets submit
        this.props.disableUserReq({
            email: this.props.disableUser.email.value,
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })

    }

    /**
     * @method success
     */
    success() {
        console.log('disableUser submit successful');
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed(msg) {
        console.log('disableUser submit failed');
        console.log('error message',msg)
        if(msg !== "") {
            this.setState({
                errorMsg: msg
            })
        }

        this.setState({
            view: "failed"
        })
    }

    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="User disabled."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="There was an issue updating this user. Please contact support."
                                routeAfter=""
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="route export-data">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Disable User
                            </h1>
                            <div className="line"></div>
                        </div>
                        <form onSubmit={this.submit.bind(this)}>
                            <div className="row">
                                <InputField
                                    type="text"
                                    label="Email address"
                                    form="disableUser"
                                    field="email"
                                    data={this.props.disableUser.email}
                                    update={this.props.updateField}
                                    disabled={this.props.disableUI}
                                />
                            </div>
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="submit"
                                    fullWidth={true}
                                    disabled={this.props.disableUser.email.value === "" || this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        disableUser: state.forms.disableUser,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({type: "CLEAR_FORM", payload: from}),
        disableUserReq: (data) => dispatch(disableUserReq(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisableUser);