import React, { Component } from 'react';
import './styles.scss';

class Footer extends Component {

    /** 
     * @method render
     */
    render() {
        return (
            <div
                className="footer"
            >
                <p>© 2019 The Supernova Foundation</p>
            </div>
        );
    }
}

export default Footer;