import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import './styles.scss';

/** 
 * @method renderInput
 */
function renderInput(inputProps, errorMsg) {
    const { InputProps, ref, ...other } = inputProps;

    return (
        <TextField
            multiline
            className={"keyword-input " + (errorMsg !== "" ? "error" : "")}
            InputProps={{
                ...InputProps,
            }}
            {...other}
        />
    );
}

/** 
 * @method renderSuggestion
 */
function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.label}
            selected={isHighlighted}
            component="div"
            className={(isHighlighted ? "highlighted" : "") + " " + (isSelected ? "selected" : "")}
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.label}
        </MenuItem>
    );
}
renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

/** 
 * @method DownshiftMultiple
 */
class DownshiftMultiple extends React.Component {

    //state
    state = {
        inputValue: '',
        selectedItem: []
    };

    /**
     * @method updateField
     */
    updateField(value) {
        this.props.update({
            form: this.props.form,
            field: this.props.field,
            data: {
                ...this.props.data,
                validated: false,
                errorMsg: "",
                value: value
            }
        })
    }

    /** 
     * @method handleKeyDown
     */
    handleKeyDown = event => {

        if(!this.props.noAddKeywords) {
            this.props.resetKeywords();

            if (this.searchTimeout !== undefined) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(this.addSuggestion.bind(this), 1000);
        }

        const { inputValue } = this.state;
        if (this.props.data.value.length && !inputValue.length && event.key === 'Backspace') {
            this.updateField(this.props.data.value.slice(0, this.props.data.value.length - 1));
        }

    };

    /**
     * @method addSuggestion
     */
    addSuggestion() {
        if(this.getSuggestions(this.state.inputValue).length === 0) {
            //we should add this option as we do not have it
            this.props.addSuggestion(this.state.inputValue);
        }
    }

    /** 
     * @method handleInputChange
     */
    handleInputChange = event => {
        
        let value = event.target.value.toLowerCase();
        this.setState({ inputValue: value });
        this.updateField([...this.props.data.value]);

    };

    /** 
     * @method handleChange
     */
    handleChange = item => {

        let newValue = [...this.props.data.value]
        if (this.props.data.value.indexOf(item) === -1) {
            newValue.push(item);
        }

        this.setState({
            inputValue: '',
        });

        this.updateField(newValue);
    };

    /** 
     * @method handleDelete
     */
    handleDelete = item => () => {
        let newValue = [...this.props.data.value];
        newValue.splice(newValue.indexOf(item), 1);
        this.updateField(newValue);
    };

    /**
     * @method getPlacholder
     */
    getPlacholder() {
        if (this.props.data.value.length === 0) {
            return "start typing..."
        }

        return ""
    }

    /**
     * @method getSuggestions
     */
    getSuggestions(value) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;

        return inputLength === 0
            ? []
            : this.props.suggestions.filter(suggestion => {
                const keep =
                    count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

                if (keep) {
                    count += 1;
                }

                return keep;
            });
    }

    /** 
     * @method render
     */
    render() {
        const { inputValue } = this.state;

        return (
            <Downshift
                id="downshift-multiple"
                inputValue={inputValue}
                onChange={this.handleChange}
                selectedItem={this.props.data.value}
            >
                {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue: inputValue2,
                    selectedItem: selectedItem2,
                    highlightedIndex,
                }) => (
                        <div className="downshift-multiple-container">
                            {renderInput({
                                fullWidth: true,
                                InputProps: getInputProps({
                                    startAdornment: this.props.data.value.map(item => (
                                        <Chip
                                            key={item}
                                            tabIndex={-1}
                                            label={item}
                                            className="chip"
                                            onDelete={this.handleDelete(item)}
                                        />
                                    )),
                                    onChange: this.handleInputChange,
                                    onKeyDown: this.handleKeyDown,
                                    onBlur: () => {
                                        this.setState({
                                            inputValue: ""
                                        })
                                    },
                                    placeholder: this.getPlacholder(),
                                }),
                                label: "",
                            }, this.props.data.errorMsg)}
                            {isOpen ? (
                                <Paper className="paper-options" square>
                                    {this.getSuggestions(inputValue2).map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion.label }),
                                            highlightedIndex,
                                            selectedItem: selectedItem2,
                                        }),
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    )}
            </Downshift>
        );
    }
}

export default DownshiftMultiple;
