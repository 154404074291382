import React, { Component } from 'react';
import { withRouter } from 'react-router';

/**
 * @class AppRouter
 * @desc handles all the updating of browser history and changes route
 */
class AppRouter extends Component {

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        //register router
        window.changeRoute = this.props.history.push;
    }

    /** 
     * @method render
     */
    render() {
        return (
            <div></div>
        );
    }
}

export default withRouter(AppRouter);