const defaultField = {
    value: "",
    validated: false,
    errorMsg: "",
    validations: [
        "required"
    ]
}

const getForm = (form) => {
    switch (form) {
        case "menteeApplication":
            return {
                email: {
                    ...defaultField,
                    validations: [
                        "required",
                        "email"
                    ]
                },
                firstName: {
                    ...defaultField
                },
                surname: {
                    ...defaultField
                },
                nationality: {
                    ...defaultField
                },
                firstLang: {
                    ...defaultField
                },
                country: {
                    ...defaultField
                },
                university: {
                    ...defaultField
                },
                fieldOfStudy: {
                    ...defaultField
                },
                interests: {
                    ...defaultField,
                    value: []
                },
                degreeStudying: {
                    ...defaultField
                },
                yearOfStudy: {
                    ...defaultField
                },
                timezone: {
                    ...defaultField
                },
                aboutYourself: {
                    ...defaultField
                },
                whyYouAreStudyingPhysics: {
                    ...defaultField
                },
                whyYouWantToJoin: {
                    ...defaultField
                },
                acceptCodeOfConduct: {
                    ...defaultField
                },
                privacy: {
                    ...defaultField
                }
            }
        case "mentorApplication":
            return {
                email: {
                    ...defaultField,
                    validations: [
                        "required",
                        "email"
                    ]
                },
                firstName: {
                    ...defaultField
                },
                surname: {
                    ...defaultField
                },
                nationality: {
                    ...defaultField
                },
                firstLang: {
                    ...defaultField
                },
                country: {
                    ...defaultField
                },
                university: {
                    ...defaultField
                },
                highestQualification:
                {
                    ...defaultField
                },
                position: {
                    ...defaultField
                },
                researchField: {
                    ...defaultField
                },
                interests: {
                    ...defaultField,
                    value: []
                },
                timezone: {
                    ...defaultField
                },
                bio: {
                    ...defaultField
                },
                acceptCodeOfConduct: {
                    ...defaultField
                },
                showBio: {
                    ...defaultField,
                    value: false
                },
                privacy: {
                    ...defaultField
                }
            }
        case "login":
            return {
                email: {
                    ...defaultField,
                    validations: [
                        "required",
                        "email"
                    ]
                },
                password: {
                    ...defaultField
                }
            }
        case "passwordReset":
            return {
                email: {
                    ...defaultField,
                    validations: [
                        "required",
                        "email"
                    ]
                },
            }
        case "registerAdmin":
            return {
                email: {
                    ...defaultField,
                    validations: [
                        "required",
                        "email"
                    ]
                },
                password: {
                    ...defaultField
                }
            }
        case "sendEmail":
            return {
                to: {
                    ...defaultField,
                    ignore: true
                },
                subject: {
                    ...defaultField
                },
                msg: {
                    ...defaultField,
                    ignore: true
                },
                email: {
                    ...defaultField,
                    ignore: true
                }
            }
        case "suggestion":
            return {
                msg: {
                    ...defaultField
                },
            }
        case "profile":
            return {
                //same for mentors and mentees
                email: {
                    ...defaultField,
                    validations: [
                        "required",
                        "email"
                    ]
                },
                firstName: {
                    ...defaultField
                },
                surname: {
                    ...defaultField
                },
                nationality: {
                    ...defaultField
                },
                firstLang: {
                    ...defaultField
                },
                country: {
                    ...defaultField
                },
                university: {
                    ...defaultField
                },
                highestQualification: {
                    ...defaultField
                },
                timezone: {
                    ...defaultField,
                },
                image: {
                    ...defaultField,
                },
                //mentee only
                fieldOfStudy: {
                    ...defaultField
                },
                degreeStudying: {
                    ...defaultField
                },
                yearOfStudy: {
                    ...defaultField
                },
                aboutYourself: {
                    ...defaultField
                },
                whyYouAreStudyingPhysics: {
                    ...defaultField
                },
                whyYouWantToJoin: {
                    ...defaultField
                },
                //mentor only
                bio: {
                    ...defaultField
                },
                position: {
                    ...defaultField
                },
                researchField: {
                    ...defaultField
                },
                interests: {
                    ...defaultField,
                    value: []
                },
                showBio: {
                    ...defaultField,
                    value: false
                }
            }
        case "changePassword":
            return {
                password: {
                    ...defaultField,
                    validations: [
                        "required",
                        "min-6"
                    ]
                },
                confirm: {
                    ...defaultField,
                    validations: [
                        "required",
                        "min-6"
                    ]
                },
            }
        case "findMentee":
            return {
                interests: {
                    ...defaultField,
                    value: []
                },
                firstLang: {
                    ...defaultField
                },
                researchField: {
                    ...defaultField
                },
                timezone: {
                    ...defaultField,
                },
                shortlist: {
                    ...defaultField,
                }
            }
        case "findMentors":
            return {
                interests: {
                    ...defaultField,
                    value: []
                },
                firstLang: {
                    ...defaultField
                },
                researchField: {
                    ...defaultField
                },
                timezone: {
                    ...defaultField,
                },
                country: {
                    ...defaultField,
                }
            }
        case "menteeFeedback":
            return {
                didYouHaveAMentorSession: {
                    ...defaultField
                },
                ifNotWhyNot: {
                    ...defaultField
                },
                rating: {
                    ...defaultField
                },
                whatTopics: {
                    ...defaultField,
                    value: []
                },
                commentsAboutMentor: {
                    ...defaultField,
                    validations: []
                },
                commentsAboutUs: {
                    ...defaultField,
                    validations: []
                }
            }
        case "mentorFeedback":
            return {
                didYouHaveAMentorSession0: {
                    ...defaultField
                },
                didYouHaveAMentorSession1: {
                    ...defaultField
                },
                rating0: {
                    ...defaultField
                },
                rating1: {
                    ...defaultField
                },
                comments0: {
                    ...defaultField,
                    validations: []
                },
                comments1: {
                    ...defaultField,
                    validations: []
                },
            }
        case "requestMentoringSession":
            return {
                timeslots: {
                    ...defaultField,
                    value: [],
                    validations: [
                        "required",
                        "requiredArray"
                    ]
                },
                timezone: {
                    ...defaultField,
                    validations: [
                        "required"
                    ]
                }
            }
        case "confirmMentoringSession":
            return {
                confirmedTime: {
                    ...defaultField,
                },
                comments: {
                    ...defaultField,
                    ignore: true,
                    validations: []
                },
                timeslots: {
                    ...defaultField,
                    value: [],
                    validations: [
                        "required",
                        "requiredArray"
                    ]
                },
                timezone: {
                    ...defaultField
                }
            }
        case "disableUser":
            return {
                email: {
                    ...defaultField,
                    validations: [
                        "required",
                        "email"
                    ]
                },
            }
        default:
            break;
    }
}

export default function (state = {
    menteeApplication: getForm("menteeApplication"),
    mentorApplication: getForm("mentorApplication"),
    login: getForm("login"),
    registerAdmin: getForm("registerAdmin"),
    sendEmail: getForm("sendEmail"),
    suggestion: getForm("suggestion"),
    profile: getForm("profile"),
    changePassword: getForm("changePassword"),
    findMentee: getForm("findMentee"),
    findMentors: getForm("findMentors"),
    menteeFeedback: getForm("menteeFeedback"),
    mentorFeedback: getForm("mentorFeedback"),
    requestMentoringSession: getForm("requestMentoringSession"),
    confirmMentoringSession: getForm("confirmMentoringSession"),
    disableUser: getForm("disableUser"),
    passwordReset: getForm("passwordReset")
}, action) {
    let form;
    switch (action.type) {
        case 'UPDATE_FIELD':

            form = {
                ...state[action.payload.form]
            };
            form[action.payload.field].value = action.payload.data.value;
            form[action.payload.field].errorMsg = action.payload.data.errorMsg;
            form[action.payload.field].validated = action.payload.data.validated;

            return {
                ...state,
                [action.payload.form]: form
            }
        case "CLEAR_FORM":

            return {
                ...state,
                [action.payload]: getForm(action.payload)
            }
        case "ADD_IGNORE":

            form = {
                ...state[action.payload.form]
            };

            form[action.payload.field].ignore = true;

            return {
                ...state,
                [action.payload.form]: form
            }
        case "REMOVE_IGNORE":

            form = {
                ...state[action.payload.form]
            };

            form[action.payload.field].ignore = false;

            return {
                ...state,
                [action.payload.form]: form
            }
        default:
    }
    return state;
}
