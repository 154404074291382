import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMentorsReq } from '../../actions/mentorActions';
import Distractor from '../../components/distractor';
import AutoComplete from '../../components/autoComplete';
import Button from '../../components/button';
import KeywordField from '../../components/keywordField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import getOptions from '../../modules/getOptions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.scss';

class MentorBios extends Component {

    state = {
        firstLoad: true,
        mentors: null,
        item: 0,
        filterExpanded: false
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.props.getMentorsReq();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        if(this.state.firstLoad && this.state.mentors === null && this.props.allMentors !== null) {
            this.setState({
                firstLoad: false,
                mentors: this.props.allMentors
            })
        }
    }

    /**
     * @method getView
     */
    getView() {

        if(this.props.allMentors === null || this.state.mentors === null) {
            return <Distractor />
        }

        let mentors = this.state.mentors
            .filter(data => data.showBio)
            .map(data => data)

        if (mentors.length === 0) {
            return <p className="helper-text">There are no mentors that match this search.</p>
        }

        console.log(mentors)

        return (
            <div className="mentors-container">
                <div className="items">
                    {
                        mentors
                            .map((data, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="mentor"
                                        onClick={() => {
                                            this.props.setDialog({
                                                title: "BIO",
                                                text: data.bio,
                                                actions: [
                                                    {
                                                        text: "CLOSE",
                                                        action: () => {}
                                                    }
                                                ]
                                            })
                                        }}
                                    >
                                        <div className="title-details">
                                            <div className="name">
                                                {data.firstName + " " + data.surname}
                                            </div>
                                            <div className="pic">
                                                <img
                                                    key={data.image}
                                                    id={data.surname + "image"}
                                                    alt=""
                                                    src={data.image}
                                                />
                                            </div>
                                        </div>
                                        <div className="all-details">
                                            <div className="row">
                                                <div className="col title">
                                                    Country:
                                                </div>
                                                <div className="col">
                                                    {data.country}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Research Field:
                                                </div>
                                                <div className="col">
                                                    {data.researchField}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Institute:
                                                </div>
                                                <div className="col">
                                                    {data.university}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Position:
                                                </div>
                                                <div className="col">
                                                    {data.position}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
        )

    }

    /**
     * @method calculateAge
     */
    calculateAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    /**
     * @method getFilter
     */
    getFilter() {

        if (this.props.keywords === null || this.state.mentors === null) {
            return <div></div>
        }

        return (
            <ExpansionPanel
                className="filters"
                expanded={this.state.filterExpanded}
                onChange={() => {
                    this.setState({
                        filterExpanded: !this.state.filterExpanded
                    })
                }}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Filters
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="filter-container">
                    <div className="row">
                        <p className="extra-label">
                            Country
                        </p>
                        <AutoComplete
                            label=""
                            suggestions={getOptions("countries")}
                            noAddKeywords
                            form="findMentors"
                            field="country"
                            data={this.props.findMentors.country}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                            contains
                        />
                    </div>
                    <div className="row">
                        <p className="extra-label">
                            First Language
                        </p>
                        <AutoComplete
                            label=""
                            suggestions={this.props.keywords.languages.map((data) => {
                                return { label: data }
                            })}
                            noAddKeywords
                            form="findMentors"
                            field="firstLang"
                            data={this.props.findMentors.firstLang}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                            contains
                        />
                    </div>
                    {/* <div className="row">
                        <DropDown
                            label="Research Field"
                            form="findMentors"
                            field="researchField"
                            data={this.props.findMentors.researchField}
                            update={this.props.updateField}
                            options={getOptions("researchField")}
                            disabled={this.props.disableUI}
                        />
                    </div> */}
                    
                    <div className="row multi">
                        <p className="extra-label">
                            Keywords describing interests:
                        </p>
                        <KeywordField
                            suggestions={this.props.keywords.interests.map((data) => {
                                return { label: data }
                            })}
                            form="findMentors"
                            field="interests"
                            data={this.props.findMentors.interests}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                            noAddKeywords
                        />
                    </div>
                    {/* <div className="row">
                        <DropDown
                            label="GMT timezone offset"
                            form="findMentors"
                            field="timezone"
                            data={this.props.findMentors.timezone}
                            update={this.props.updateField}
                            options={getOptions("timezone")}
                            disabled={this.props.disableUI}
                        />
                    </div> */}
                    <div className="row buttons">
                        <Button
                            type="button"
                            text="apply filter"
                            disabled={this.props.disableUI}
                            className="positive-back"
                            onClick={this.applyFilter.bind(this)}
                        />
                        <Button
                            type="button"
                            text="clear filter"
                            disabled={this.props.disableUI}
                            className="negative-back"
                            onClick={this.clearFilter.bind(this)}
                        />
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    /**
     * @method applyFilter
     */
    applyFilter() {

        let mentors = [];

        for (let i = 0; i < this.props.allMentors.length; i++) {
            let firstLang = true;
            let researchField = true;
            let interests = true;
            let timezone = true;
            let country = true;
            let item = this.props.allMentors[i];

            if (this.props.findMentors.firstLang.value !== "") {
                if (this.props.findMentors.firstLang.value !== item.firstLang) {
                    firstLang = false;
                }
            }

            if (this.props.findMentors.country.value !== "") {
                if (this.props.findMentors.country.value !== item.country) {
                    country = false;
                }
            }

            if (this.props.findMentors.researchField.value !== "") {
                if (this.props.findMentors.researchField.value !== item.researchField) {
                    researchField = false;
                }
            }

            if (this.props.findMentors.interests.value.length !== 0) {
                let found = false;
                for (let a = 0; a < this.props.findMentors.interests.value.length; a++) {
                    for (let b = 0; b < item.interests.length; b++) {
                        if (item.interests[b] === this.props.findMentors.interests.value[a]) {
                            found = true;
                        }
                    }
                }
                if (!found) {
                    interests = false;
                }
            }

            if (this.props.findMentors.timezone.value !== "") {
                if (this.props.findMentors.timezone.value !== item.timezone) {
                    timezone = false;
                }
            }

            //check if this item passed all filters
            if (firstLang && researchField && interests && timezone && country) {
                mentors.push(item)
            }
        }

        this.setState({
            filterExpanded: false,
            mentors: mentors
        })
    }

    /**
     * @method clearFilter
     */
    clearFilter() {
        this.props.clearForm("findMentors");
        this.setState({
            filterExpanded: false,
            mentors: this.props.allMentors
        })
    }

    /** 
     * @method render
     */
    render() {

        return (
            <div className="route find-mentors">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Mentors
                            </h1>
                            <div className="line"></div>
                        </div>
                        <div className="p">
                            Please note not all of our mentors have chosen to make their bios public.
                            <p></p>
                            
                        </div>
                        {this.getFilter()}
                        {this.getView()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        allMentors: state.mentors.all,
        findMentors: state.forms.findMentors,
        disableUIL: state.app.disableUI,
        keywords: state.app.keywords,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMentorsReq: () => dispatch(getMentorsReq()),
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({ type: "CLEAR_FORM", payload: from }),
        setDialog: (data) => dispatch({ type: "SET_DIALOG", payload: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MentorBios);