import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../components/button';
import Success from '../../components/success';
import Failed from '../../components/failed';
import DropDown from '../../components/dropDown';
import { validateForm } from "../../modules/formValidator";
import * as helpers from "../../modules/helpers";
import { getMentoringSessionInfo, confirmMentoringSessionReq } from '../../actions/appActions';
import Distractor from '../../components/distractor';
import RadioButton from '../../components/radioButton';
import InputField from '../../components/inputField';
import AddCircle from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';
import Close from '@material-ui/icons/Close';
import './styles.scss';
import getOptions from '../../modules/getOptions';

class ConfirmMentoringSession extends Component {

    state = {
        view: "start",
        errorMsg: "",
        sentRequest: false,
        docID: helpers.getParameterByName("docID"),
        noneOfTheseTimesWork: false,
        warningMessage: ""
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {

        if (this.state.docID === null || this.state.docID === "") {
            return this.setState({
                sentRequest: true,
                view: "warning",
                warningMessage: "No id for the session request has been provided."
            })
        }

        this.getInfo();
    }

    /**
     * @method componentDidUpdate
     */
    componentDidUpdate() {
        this.getInfo();
    }

    /**
     * @method getInfo
     */
    getInfo() {
        if (!this.state.sentRequest && this.props.loggedIn) {
            this.setState({
                sentRequest: true
            });
            this.props.getMentoringSessionInfo(this.state.docID, this.gotInfo.bind(this));
        }
    }

    /**
     * gotInfo
     */
    gotInfo() {
        console.log('confirmMentoringSession, gotInfo, ln69');
        console.log(this.props.timeslots);

        if(this.props.timeslots.status === "confirmed") {
            return this.setState({
                view: "warning",
                warningMessage: "This session has already been confirmed."
            })
        }

        let forWho = this.props.timeslots.status.split("-")
        if(forWho[2] !== this.props.user.role) {
            this.setState({
                view: "warning",
                warningMessage: "We are waiting for the other participate to confirm one of the times you provided."
            })
        }   
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        });

        //check what validations we need
        if (this.state.noneOfTheseTimesWork) {
            this.props.addIgnore({ form: "confirmMentoringSession", field: "confirmedTime" });
            this.props.removeIgnore({ form: "confirmMentoringSession", field: "timeslots" });
        } else {
            this.props.removeIgnore({ form: "confirmMentoringSession", field: "confirmedTime" });
            this.props.addIgnore({ form: "confirmMentoringSession", field: "timeslots" });
            this.props.addIgnore({ form: "confirmMentoringSession", field: "timezone" });
        }

        //validate form
        const validate = validateForm("confirmMentoringSession")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        let status = "confirmed";
        if (this.state.noneOfTheseTimesWork) {
            status = this.props.user.role === "mentor" ? "waiting-on-mentee" : "waiting-on-mentor"
        }

        //all good lets submit
        this.props.confirmMentoringSessionReq({
            submit: {
                status: status,
                mentee: this.props.timeslots.mentee,
                mentor: this.props.timeslots.mentor,
                confirmedTime: this.props.confirmMentoringSession.confirmedTime.value,
                comments: this.props.confirmMentoringSession.comments.value,
                timeslots: this.props.confirmMentoringSession.timeslots.value,
                timezone: this.props.confirmMentoringSession.timezone.value
            },
            name: this.props.user.additionalInfo.firstName + " " + this.props.user.additionalInfo.surname,
            role: this.props.user.role,
            id: this.state.docID,
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("confirmMentoringSession");
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }


    /**
     * @method updateField
     */
    updateField(value) {
        this.props.updateField({
            form: "confirmMentoringSession",
            field: "timeslots",
            data: {
                ...this.props.data,
                validated: false,
                errorMsg: "",
                value: value
            }
        })
    }

    /**
     * @method getForm
     */
    getForm() {
        // console.log('getForm, confirmMentoringSession, timeslots');
        // console.log(this.props.timeslots)

        

        // console.log(this.props.confirmMentoringSession.timeslots.value)
        
        if (this.props.timeslots === null) {
            return <Distractor />
        }

        if (this.state.noneOfTheseTimesWork) {
            return (
                <form onSubmit={this.submit.bind(this)}>
                    <div className="go-back" onClick={() => {
                        this.setState({
                            noneOfTheseTimesWork: false,
                            errorMsg: ""
                        });
                    }}>
                        {"Go Back"}
                    </div>
                    <div className="row multi">
                        <p className="extra-label">
                            Comments (Optional):
                        </p>
                        <InputField
                            type="text"
                            label=""
                            form="confirmMentoringSession"
                            field="comments"
                            data={this.props.confirmMentoringSession.comments}
                            update={this.props.updateField}
                            multiline
                            wordLimit="200"
                            disabled={this.props.disableUI}
                        />
                    </div>
                    {
                        this.props.confirmMentoringSession.timeslots.value.map((data, index) => {
                            return (
                                <div key={index} className="row custom">
                                    <div className="input-field-container">
                                        <TextField
                                            id={"datetime-local-" + index}
                                            label="Proposed time"
                                            type="datetime-local"
                                            className={"input-field " + (this.props.confirmMentoringSession.timeslots.errorMsg !== "" ? "error" : "")}
                                            value={data}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={(e) => {
                                                let currentValue = [...this.props.confirmMentoringSession.timeslots.value];
                                                currentValue[index] = e.target.value;
                                                this.updateField(currentValue);
                                                this.setState({
                                                    errorMsg: ""
                                                });
                                            }}
                                        />
                                    </div>
                                    <Close
                                        className="close"
                                        onClick={() => {
                                            let currentValue = [...this.props.confirmMentoringSession.timeslots.value];
                                            currentValue.splice(index, 1);
                                            this.updateField(currentValue);
                                            this.setState({
                                                errorMsg: ""
                                            });
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                    <div className="row add-timeslot" onClick={() => {
                        let currentValue = [...this.props.confirmMentoringSession.timeslots.value]
                        currentValue.push("");
                        this.updateField(currentValue);
                        this.setState({
                            errorMsg: ""
                        });
                    }}>
                        <AddCircle />
                        <span>Add a timeslot</span>
                    </div>
                    {
                        this.state.errorMsg !== "" ?
                            <p className="error">
                                {this.state.errorMsg}
                            </p>
                            :
                            <div></div>
                    }
                    <div className="row">
                        <DropDown
                            label="Please indicate the GMT offset of your timezone"
                            field="timezone"
                            form="confirmMentoringSession"
                            data={this.props.confirmMentoringSession.timezone}
                            update={this.props.updateField}
                            options={getOptions("timezone")}
                            disabled={this.props.disableUI}
                            
                        />
                    </div>
                    <div className="form-button">
                        <Button
                            type="submit"
                            text="send"
                            fullWidth={true}
                            disabled={this.props.disableUI}
                            loading={this.props.disableUI}
                        />
                    </div>
                </form>
            )
        }
        
        console.log('getForm, confirmMentoringSession, ln199');
        console.log(this.props.timeslots);
        let tz = this.props.timeslots.timezone;
        console.log(tz);
        if (tz.length !==0 && tz[0] !== "-"){
            tz = "+" + tz
        }

        return (
            
            <form onSubmit={this.submit.bind(this)}>
                {
                    this.props.timeslots.comments !== "" && this.props.timeslots.comments !== null && this.props.timeslots.comments !== undefined ?
                    <div className="row multi comments">
                        <span>{"Comments: "}</span>
                        {this.props.timeslots.comments}
                    </div>
                    :
                    <div></div>
                }
                
                <p className="helper-text">Please note all times are in the timezone GMT {tz}</p>
                <div className="row radio">
                    <RadioButton
                        label="Proposed Times"
                        form="confirmMentoringSession"
                        field="confirmedTime"
                        data={this.props.confirmMentoringSession.confirmedTime}
                        update={this.props.updateField}
                        options={this.props.timeslots.timeslots.map((data => {

                            let dateTime = new Date(data).toLocaleString();
                            // console.log('confirmMentoringSession index, ln 309');
                            // console.log(data);
                            // console.log(dateTime);
                            if (dateTime === "Invalid Date"){
                                dateTime = data;
                            }

                            return {
                                label: dateTime,
                                value: dateTime
                            }
                        }))}
                        disabled={this.props.disableUI}
                    />
                </div>
                {
                    this.state.errorMsg !== "" ?
                        <p className="error">
                            {this.state.errorMsg}
                        </p>
                        :
                        <div></div>
                }
                <div className="row multi">
                <div className="form-button">
                    <Button
                        type="button"
                        text="none of these times work for me"
                        fullWidth={true}
                        disabled={this.props.disableUI}
                        loading={this.props.disableUI}
                        className="negative-back"
                        onClick={() => {
                            this.setState({
                                noneOfTheseTimesWork: true,
                                errorMsg: ""
                            })
                        }}
                    />
                </div>

                <div className="form-button">
                    <Button
                        type="submit"
                        text="confirm"
                        fullWidth={true}
                        disabled={this.props.disableUI || this.props.confirmMentoringSession.confirmedTime.value === ""}
                        loading={this.props.disableUI}
                    />
                </div>
                </div>
                
            </form>
        )

    }

    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="An email has been sent to confirm the time for this session."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Something went wrong. Please contact support."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "warning") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Warning"
                                warning
                                msg={this.state.warningMessage}
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="route confirm-mentoring-session">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Confirm Mentoring Session
                            </h1>
                            <div className="line"></div>
                        </div>
                        <p className="helper-text">Please see the proposed times below and confirm what time works for you.</p>
                        
                        {this.getForm()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        confirmMentoringSession: state.forms.confirmMentoringSession,
        loggedIn: state.user.loggedIn,
        user: state.user.user,
        timeslots: state.app.timeslots
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({ type: "CLEAR_FORM", payload: from }),
        getMentoringSessionInfo: (data, callback) => dispatch(getMentoringSessionInfo(data, callback)),
        confirmMentoringSessionReq: (data) => dispatch(confirmMentoringSessionReq(data)),
        addIgnore: (data) => dispatch({ type: "ADD_IGNORE", payload: data }),
        removeIgnore: (data) => dispatch({ type: "REMOVE_IGNORE", payload: data }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmMentoringSession);