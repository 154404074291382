import store from '../store';

/**
 * @method validateForm
 */
export const validateForm = (formName) => {
    let validated = true;
    const forms = store.getState().forms;
    const form = forms[formName];

    for(let key in form) {
        
        let field = form[key];

        //check if we should ignore this field
        if(!field.ignore) {
            let value = field.value;
            let validations = field.validations;
            let validate = validateField(formName, key, validations, value);
    
            if(!validate) {
                //we have a problem
                validated = false;
                
            }
        }

    }

    return validated;
}

/**
 * @method validateField
 */
export const validateField = (formName, field, validations, value) => {
    // console.log('formValidator, ln37');
    // console.log(formName);
    // console.log(field);
    let validated = true;
    for (let i = 0; i < validations.length; i++) {

        let errorMsg = "";
        let validation = validations[i];
        let check = validate(validation, value);

        //we have to do this for date fields as we set the default value to this
        if(validation === "required" && value === "yyyy-mm-dd") {
            check = false;
        }

        if(!check) {
            //we have a problem
            validated = false;
            errorMsg = validateError(validation);
        }
        
        //update field
        store.dispatch({
            type: "UPDATE_FIELD",
            payload: {
                form: formName,
                field: field,
                data: {
                    validated: validated,
                    validations: validations,
                    errorMsg: errorMsg,
                    value: value
                }
            }
        })

    }

    return validated;
}

/**
 * @method validate
 */
export const validate = (validation, value) => {

    let check = validation.split("-")[0];
    let checkData = validation.split("-")[1];
    let checkResult = true;

    switch (check) {
        case "required":
            return value.length !== 0;
        case "requiredArray":
            for (let i = 0; i < value.length; i++) {
                if(value[i] === "") {
                    checkResult = false;
                }
            }
            return checkResult;
        case "email":
            /* eslint-disable */
            let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            /* eslint-enable */
            return re.test(value);
        case "min":
            return value.length >= +checkData
        default:
            break;
    }
}

/**
 * @method validateError
 */
export const validateError = (validation) => {

    let check = validation.split("-")[0];
    let checkData = validation.split("-")[1];

    switch (check) {
        case "required":
        case "requiredArray":
            return "Required";
        case "email": return "Not a valid email"
        case "min": return "Minimum " + checkData + " characters"
        default:
            break;
    }
}