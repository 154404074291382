import firebase from '../firebase';

/** 
 * @method getMenteesReq
 */
export const getMenteesReq = () => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "SET_MENTEES", payload: null });
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get all applications
			const getMentees = firebase.functions().httpsCallable('getMenteesReq');
			const getMenteesRes = await getMentees({
				token: localStorage.getItem("token")
			});
			
			if(getMenteesRes.data.success) {
				dispatch({ type: "SET_MENTEES", payload: getMenteesRes.data.mentees });
			} else {
				dispatch({ type: "SET_MENTEES", payload: [] });
			}


		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method getMenteesLimitedReq
 */
export const getMenteesLimitedReq = () => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "SET_MENTEES_LIMITED", payload: null });
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get all applications
			const getMentees = firebase.functions().httpsCallable('getMenteesLimitedReq');
			const getMenteesRes = await getMentees();
			
			if(getMenteesRes.data.success) {
				dispatch({ type: "SET_MENTEES_LIMITED", payload: getMenteesRes.data.mentees });
			} else {
				dispatch({ type: "SET_MENTEES_LIMITED", payload: [] });
			}


		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method addMenteeReq
 */
export const addMenteeReq = (data) => {
	return async (dispatch, getState) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get state
			const state = getState();
			const user = state.user.user;

			//get all applications
			const addMentee = firebase.functions().httpsCallable('addMenteeReq');
			const addMenteeRes = await addMentee({
				email: user.email,
				mentee: data.submit.email,
				token: localStorage.getItem("token")
			});
			
			if(addMenteeRes.data.success) {
				dispatch({ type: "UPDATE_PROFILE", payload: addMenteeRes.data.user });
				dispatch({ type: "SET_MENTEES", payload: null });
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method menteeFeedbackReq
 */
export const menteeFeedbackReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const menteeFeedback = firebase.functions().httpsCallable('menteeFeedbackReq');
			const menteeFeedbackRes = await menteeFeedback({
				token: localStorage.getItem("token"),
				docID: data.docID,
				date: data.date,
				submit: data.submit
			});

			if(menteeFeedbackRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method checkIfAlreadySendFeedbackReq
 */
export const checkIfAlreadySendFeedbackReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const checkIfAlreadySendFeedback = firebase.functions().httpsCallable('checkIfAlreadySendFeedbackReq');
			const checkIfAlreadySendFeedbackRes = await checkIfAlreadySendFeedback({
				token: localStorage.getItem("token"),
				email: data.email,
				type: "mentee"
			});

			if(checkIfAlreadySendFeedbackRes.data.success) {
				data.success(checkIfAlreadySendFeedbackRes.data);
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method requestMentoringSessionReq
 */
export const requestMentoringSessionReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const requestMentoringSession = firebase.functions().httpsCallable('requestMentoringSessionReq');
			const requestMentoringSessionRes = await requestMentoringSession({
				token: localStorage.getItem("token"),
				submit: data.submit,
				name: data.name
			});

			if(requestMentoringSessionRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}
