import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoute, getWebinarsReq } from '../../actions/appActions';
import Distractor from '../../components/distractor';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import { Player } from 'video-react';
import './styles.scss';

class Webinars extends Component {

    state = {
        sentRequest: false,
        play: null
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.getWebinars();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.getWebinars();
    }

    /**
     * @method getWebinars
     */
    getWebinars() {
        if (!this.state.sentRequest && this.props.loggedIn) {
            this.props.getWebinarsReq();
            this.setState({
                sentRequest: true
            })
        }
    }

    /**
     * @method checkForPlayer
     */
    checkForPlayer() {
        let loop = setInterval(() => {
            if (this.refs.player) {
                clearInterval(loop);
                this.handleStateChangeProxy = this.handleStateChange.bind(this);
                this.refs.player.subscribeToStateChange(this.handleStateChangeProxy);
                this.refs.player.toggleFullscreen();
            }
        }, 0);
    }

    /**
     * @method handleStateChange
     */
    handleStateChange(state, prevState) {
        if (state.isFullscreen !== prevState.isFullscreen) {
            //this.props.setWindowDimensions();
        }
    }

    /** 
     * @method render
     */
    render() {

        if (this.state.play !== null) {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div id="videoPlayer" className="content">
                            <div className="go-back" onClick={() => {
                                this.setState({
                                    play: null
                                });
                            }}>
                                {"Go Back"}
                            </div>
                            <Player
                                ref="player"
                                playsInline
                                src={this.state.play}
                                autoPlay={true}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Webinars
                            </h1>
                            <div className="line"></div>
                        </div>
                        <div className="webinars">
                            {
                                this.props.webinars === null ?
                                    <Distractor />
                                    :
                                    this.props.webinars.map((data, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="webinar"
                                                onClick={() => {
                                                    this.setState({
                                                        play: data.url
                                                    }, () => {
                                                        document.getElementById('videoPlayer').addEventListener('contextmenu', e => {
                                                            e.preventDefault();
                                                        });
                                                    });
                                                    this.checkForPlayer()
                                                }}
                                            >
                                                <div className="icon">
                                                    <PlayCircleFilled />
                                                </div>
                                                <div className="label">
                                                    {data.name}
                                                </div>
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        disableUI: state.applications.disableUI,
        webinars: state.app.webinars,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeRoute: (route) => dispatch(changeRoute(route)),
        getWebinarsReq: () => dispatch(getWebinarsReq()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Webinars);