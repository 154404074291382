import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPageContent } from '../../actions/siteActions';
import contentRendering from '../../modules/contentRendering';
import WorldMap from '../../components/worldMap';
import PageLoader from '../../components/pageLoader';
import './styles.scss';

class Home extends Component {

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.props.getPageContent();
    }

    /** 
     * @method render
     */
    render() {

        if(this.props.homeContent === null) {
            return <PageLoader />
        }

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        {contentRendering(this.props.homeContent)}
                        <div className="content-image">
                        <img
                            alt=""
                            width="50%"
                            src="https://firebasestorage.googleapis.com/v0/b/thesupernovafoundation.appspot.com/o/home%2Ffront_page.jpg?alt=media&token=42105a2e-b7b1-4732-9e7d-b90c212316b8"
                            position="relative"
                        />
                        </div>
                        <WorldMap/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        homeContent: state.app.homeContent,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPageContent: () => dispatch(getPageContent('home'))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);