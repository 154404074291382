import firebase from '../firebase';
import { changeRoute } from './appActions';
import routes from '../routes';
import { uploadProfilePicture } from '../modules/helpers';

//storageRef
const storageRef = firebase.storage().ref();

/** 
 * @method autoLoginReq
 */
export const autoLoginReq = (alterRoute) => {
	return async (dispatch) => {

		try {
			dispatch({ type: "LOGINING_IN", payload: true });

			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const autoLogin = firebase.functions().httpsCallable('autoLoginReq');
			const autoLoginRes = await autoLogin(localStorage.getItem("token"));

			if (autoLoginRes.data.success) {
				localStorage.setItem("token", autoLoginRes.data.token);
				dispatch({ type: "LOGIN_SUCCESS", payload: autoLoginRes.data.user });
				dispatch({ type: "SHOW_MENU", payload: false });

				//if this user needs to set thier user force them to the correct screen
				if(autoLoginRes.data.user.status === "pending-create-password") {
					dispatch(changeRoute("changepassword?pageType=setpassword"))
				} else {
					//check if this user is allowed to access this route
					let requestedRoute = window.location.pathname;
					for (let i = 0; i < routes.length; i++) {
						let item = routes[i];
						if (requestedRoute === item.route) {

							if (item.secure) {
								if (item.role) {
									if (item.role !== autoLoginRes.data.user.role) {
										dispatch(changeRoute("login"))
									}
								}
							}

						}
					}

					if(alterRoute) {
						//change if we want to force a logged in user to dashboard
						//dispatch(changeRoute("dashboard"))
					}

				}

			} else {
				dispatch(changeRoute("login"));
			}

		} catch (error) {
			dispatch(changeRoute("login"));
			console.log(error);
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method loginReq
 */
export const loginReq = (data) => {
	return async (dispatch) => {

		try {

			dispatch({ type: "LOGINING_IN", payload: true });

			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const login = firebase.functions().httpsCallable('loginReq');
			const loginRes = await login(data.submit);

			if (loginRes.data.success) {
				localStorage.setItem("token", loginRes.data.token);
				dispatch({ type: "LOGIN_SUCCESS", payload: loginRes.data.user });
				data.success();
			} else {
				data.failed("Incorrect details");
			}

		} catch (error) {
			console.log(error);
			data.failed("Internal server error. Please contact support.");
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method resetPasswordReq
 */
export const resetPasswordReq = (data) => {
	return async (dispatch) => {

		try {

			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			const resetPasswordReq = firebase.functions().httpsCallable('resetPasswordReq');
			const resetPasswordRes = await resetPasswordReq(data.submit.email);

			if (resetPasswordRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed("Internal server error. Please contact support.");
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method registerAdminReq
 */
export const registerAdminReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add token for security
			data.submit.token = localStorage.getItem("token");

			//add record to the database
			const registerAdmin = firebase.functions().httpsCallable('registerAdminReq');
			const registerAdminRes = await registerAdmin(data.submit);

			if (registerAdminRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method updateProfileReq
 */
export const updateProfileReq = (data) => {
	return async (dispatch, getState) => {

		try {

			//get state
			const state = getState();
			const user = state.user.user;
			const pp = user.additionalInfo.image;

			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//check if we have a new profile pic
			data.submit.image = pp;
			if (data.files.image !== null) {
				//upload profile pic
				data.submit.image = await uploadProfilePicture(data);

				//delete old one
				if(pp !== "") {
					const oldPicFileName = pp.substr(0, pp.indexOf("?")).substring(pp.indexOf("profilePictures"), pp.substr(0, pp.indexOf("?")).length).replace("%2F","/")
					const oldPicFileRef = storageRef.child(oldPicFileName);

					try {
						await oldPicFileRef.delete();
					} catch (e) {
						console.log('File not found: ' + oldPicFileName);
					}
				}

			}

			//add token for security
			data.submit.token = localStorage.getItem("token");

			//add email
			data.submit.email = user.email;

			//add record to the database
			const endpoint = data.submit.emailChanged ? "updateEmailReq" : "updateProfileReq";
			const updateProfile = firebase.functions().httpsCallable(endpoint);
			const updateProfileRes = await updateProfile(data.submit);

			if (updateProfileRes.data.success) {
				data.success();

				//update state
				dispatch({ type: "UPDATE_PROFILE", payload: updateProfileRes.data.user });

			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method changePasswordReq
 */
export const changePasswordReq = (data) => {
	return async (dispatch, getState) => {

		try {

			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get state
			const state = getState();
			const user = state.user.user;

			//add token for security
			data.submit.token = localStorage.getItem("token");

			//add email
			data.submit.email = user.email;

			//add record to the database
			const changePassword = firebase.functions().httpsCallable('changePasswordReq');
			const changePasswordRes = await changePassword(data.submit);

			if (changePasswordRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed("Internal server error. Please contact support.");
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method logoutReq
 */
export const logoutReq = (data) => {
	return async (dispatch) => {

		localStorage.removeItem("token");
		dispatch(changeRoute("login"));
		dispatch({ type: "LOGOUT", payload: {} });

	}
}

/**
 * @method getYoursReq
 */
export const getYoursReq = (type) => {
	return async (dispatch, getState) => {




	}
}