import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropDown from '../../components/dropDown';
import InputField from '../../components/inputField';
import Button from '../../components/button';
import Success from '../../components/success';
import Failed from '../../components/failed';
import { validateForm } from "../../modules/formValidator";
import { sendEmailReq } from '../../actions/appActions';
import AutoComplete from '../../components/autoComplete';
import { getMenteesReq } from '../../actions/menteeActions';
import { getMentorsReq } from '../../actions/mentorActions';
import Distractor from '../../components/distractor';
import './styles.scss';

class SendEmail extends Component {

    state = {
        view: "start",
        sentRequest: false,
        errorMsg: ""
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.fetchAllUsers();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.fetchAllUsers();
    }

    /**
     * @method fetchAllUsers
     */
    fetchAllUsers() {
        if (!this.state.sentRequest && this.props.loggedIn) {
            this.setState({
                sentRequest: true
            })
            this.props.getMenteesReq();
            this.props.getMentorsReq();
        }
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("sendEmail")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }

        //all good lets submit
        this.props.sendEmailReq({
            submit: {
                to: this.props.sendEmail.to.value,
                email: this.props.sendEmail.email.value,
                subject: this.props.sendEmail.subject.value,
                msg: this.props.sendEmail.msg.value
            },

            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("sendEmail");
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /**
     * @method getUsers
     */
    getUsers() {
        const users = [ ...this.props.allMentees, ...this.props.allMentors ];
        return users.map((user) => ({
            label: `${user.email} (${user.firstName} ${user.surname})`,
        }));
    }

    /**
     * @method getForm
     */
    getForm() {

        if (this.props.allMentees === null || this.props.allMentors === null) {
            return <Distractor />
        }

        return (
            <form onSubmit={this.submit.bind(this)}>
                <div className="row">
                    <DropDown
                        label="To Who?"
                        form="sendEmail"
                        field="to"
                        data={this.props.sendEmail.to}
                        update={this.props.updateField}
                        options={[
                            {
                                label: "ALL",
                                value: "ALL"
                            },
                            {
                                label: "Mentors Only",
                                value: "Mentors Only"
                            },
                            {
                                label: "Mentees Only",
                                value: "Mentees Only"
                            },
                            {
                                label: "Single User",
                                value: "Single User"
                            }
                        ]}
                        disabled={this.props.disableUI}
                    />
                </div>
                {
                    this.props.sendEmail.to.value === "Single User" ?
                        <div className="row">
                            <p className="extra-label">
                                To user:
                            </p>
                            <AutoComplete
                                label=""
                                suggestions={this.getUsers()}
                                noAddKeywords
                                form="sendEmail"
                                field="email"
                                data={this.props.sendEmail.email}
                                update={this.props.updateField}
                                disabled={this.props.disableUI}
                                contains
                            />
                        </div>
                        :
                        <div></div>
                }
                <div className="row">
                    <InputField
                        type="text"
                        label="Subject"
                        form="sendEmail"
                        field="subject"
                        data={this.props.sendEmail.subject}
                        update={this.props.updateField}
                        disabled={this.props.disableUI}
                    />
                </div>
                <div className="row multi">
                    <p className="extra-label">
                        Message:
                </p>
                    <InputField
                        type="text"
                        label=""
                        form="sendEmail"
                        field="msg"
                        data={this.props.sendEmail.msg}
                        update={this.props.updateField}
                        multiline
                        disabled={this.props.disableUI}
                    />
                </div>
                {
                    this.state.errorMsg !== "" ?
                        <p className="error">
                            {this.state.errorMsg}
                        </p>
                        :
                        <div></div>
                }
                <div className="form-button">
                    <Button
                        type="submit"
                        text="send"
                        fullWidth={true}
                        disabled={this.props.disableUI}
                        loading={this.props.disableUI}
                    />
                </div>
            </form>
        )

    }

    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="Emails have been sent."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Something went wrong. Please contact support."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="route">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content send-email">
                        <div className="heading">
                            <h1>
                                Send Email
                            </h1>
                            <div className="line"></div>
                        </div>
                        {this.getForm()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        disableUI: state.app.disableUI,
        sendEmail: state.forms.sendEmail,
        allMentees: state.mentees.all,
        allMentors: state.mentors.all,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({ type: "CLEAR_FORM", payload: from }),
        sendEmailReq: (data) => dispatch(sendEmailReq(data)),
        getMenteesReq: () => dispatch(getMenteesReq()),
        getMentorsReq: () => dispatch(getMentorsReq()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);