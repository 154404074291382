import React, { Component } from 'react';
import { connect } from 'react-redux';
import AutoComplete from '../../components/autoComplete';
import Success from '../../components/success';
import Button from '../../components/button';
import Failed from '../../components/failed';
import DropDown from '../../components/dropDown';
import Distractor from '../../components/distractor';
import KeywordField from '../../components/keywordField';
import { getMenteesReq, addMenteeReq } from '../../actions/menteeActions';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Star from '@material-ui/icons/Star';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import getOptions from '../../modules/getOptions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.scss';

class FindMentees extends Component {

    state = {
        view: "start",
        sentRequest: false,
        item: 0,
        mentees: null,
        filterExpanded: false,
        shortlist: localStorage.getItem("shortlist") === null ? [] : localStorage.getItem("shortlist").split(",")
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.getMentees();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.getMentees();
    }

    /**
     * @method getMentees
     */
    getMentees() {
        if (!this.state.sentRequest && this.props.loggedIn) {
            this.props.getMenteesReq();
            this.setState({
                sentRequest: true
            })
        }

        if (this.state.mentees === null && this.props.allMentees !== null) {
            this.setState({
                mentees: this.props.allMentees.filter((data) => (data.mentor === "" && data.status === "active")).map((data) => data)
                // mentees: this.props.allMentees.filter((data) => data.mentor === "").map((data) => data)
            })
        }
    }

    /** 
     * @method getView
     */
    getView() {
        if (this.props.user === null){
            return <Distractor />
        }
        
        if (this.state.mentees === null || this.props.allMentees === null) {
            return <Distractor />
        }

        let menteeLeft = this.props.allMentees.filter((data) => data.mentor === "").map((data) => data);
        if (menteeLeft.length === 0) {
            return <p className="helper-text">There are no mentees left that require mentors.</p>
        }

        if (this.state.mentees.length === 0) {
            return <p className="helper-text">There are no mentees that match this search.</p>
        }

        return (
            <div className="mentees-container">
                <div className="items">
                    {this.state.mentees
                        .filter((data, index) => index === this.state.item)
                        .map((data, index) => {
                            return (
                                <div key={index} className="paper-inner">
                                    <div className="back"></div>
                                    <div className="content">
                                        <div className={"star " + (this.state.shortlist.includes(data.email) ? "show" : "hide")}>
                                            <Star/>
                                        </div>
                                        <div className="title-details">
                                            <div className="name">
                                                {data.firstName + " " + data.surname}
                                            </div>
                                            <div className="pic">
                                                <img
                                                    key={data.image}
                                                    alt=""
                                                    src={data.image}
                                                />
                                            </div>
                                        </div>
                                        <div className="all-details">
                                            <div className="row">
                                                <div className="col title">
                                                    GMT Timezone offset:
                                            </div>
                                                <div className="col">
                                                    {data.timezone}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Country:
                                            </div>
                                                <div className="col">
                                                    {data.country}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Nationality:
                                            </div>
                                                <div className="col">
                                                    {data.nationality}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    University:
                                            </div>
                                                <div className="col">
                                                    {data.university}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    First Language:
                                            </div>
                                                <div className="col">
                                                    {data.firstLang}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Field of study:
                                            </div>
                                                <div className="col">
                                                    {data.fieldOfStudy}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Interests:
                                            </div>
                                                <div className="col">
                                                    {data.interests.toString()}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Degree studying:
                                            </div>
                                                <div className="col">
                                                    {data.degreeStudying}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col title">
                                                    Email:
                                            </div>
                                                <div className="col">
                                                    {data.email}
                                                </div>
                                            </div>
                                            <div className="row no-flex">
                                                <p className="title">
                                                    About:
                                            </p>
                                                <p>
                                                    {data.aboutYourself}
                                                </p>
                                            </div>
                                            <div className="row no-flex">
                                                <p className="title">
                                                    Why they are studying physics:
                                            </p>
                                                <p>
                                                    {data.whyYouAreStudyingPhysics}
                                                </p>
                                            </div>
                                            <div className="row no-flex">
                                                <p className="title">
                                                    What they want to join:
                                            </p>
                                                <p>
                                                    {data.whyYouWantToJoin}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="buttons">
                                            {this.getShortlistButton(data)}
                                            <Button
                                                type="button"
                                                text="select this mentee"
                                                disabled={this.props.disableUI}
                                                loading={this.props.disableUI}
                                                className="positive-back"
                                                onClick={() => {
                                                    this.addMentee(data)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </div>
        )

    }

    /**
     * @method getShortlistButton
     */
    getShortlistButton(data) {

        const { shortlist } = this.state;

        let color = "second";
        let buttonText = "add to shortlist";
        let buttonAction = () => {
            localStorage.setItem("shortlist", [data.email].concat(shortlist).toString());
            this.setState({
                shortlist: [data.email].concat(shortlist)
            })
        }
        
        if(shortlist.length !== 0) {
            for (let i = 0; i < shortlist.length; i++) {
                let item = shortlist[i];
                if(item === data.email) {

                    //add logic to remove item from shortlist
                    color = "negative-back"
                    buttonText = "remove from shortlist"
                    buttonAction = () => {
                        let newArray = [];
                        for (let a = 0; a < shortlist.length; a++) {
                            let check = shortlist[a];
                            if(check !== item) {
                                newArray.push(item)
                            }
                        }
                        localStorage.setItem("shortlist", newArray.toString());
                        
                        this.setState({
                            shortlist: newArray
                        }, () => {
                            if(this.props.findMentee.shortlist.value === "Yes") {
                                this.applyFilter()
                            }
                        });


                    }

                }
            }
        }

        //return button
        return (
            <Button
                type="button"
                text={buttonText}
                className={color}
                disabled={this.props.disableUI}
                loading={this.props.disableUI}
                onClick={buttonAction}
            />
        )
    }

    /**
     * @method addMentee
     */
    addMentee(data) {
        //if the user already has 2 or more mentees then tell the user that they cannot add more
        if (this.props.user.additionalInfo.mentees.length >= 2) {
            this.props.setDialog({
                 title: "Warning",
                 text: "You have already selected the maximum number of mentees. Please contact admin@supernovafoundation.org if you want to change mentees.",
                 actions: [
                     {
                         text: "OK",
                         action: () => {}
                     }
                 ]
            })
        } else {
            this.props.setDialog({
                title: "Confirm",
                text: "Please confirm your selection as the only way to change this is by contacting support.",
                actions: [
                    {
                        text: "CONFIRM",
                        action: () => {
                            this.props.addMenteeReq({
                                submit: data,
                                success: this.success.bind(this),
                                failed: this.failed.bind(this)
                            })
                        }
                    },
                    {
                        text: "CANCEL",
                        action: () => { }
                    }
                ]
            })
        }
    }


    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success",
            mentees: null
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed",
            mentees: null
        })
    }

    /**
     * @method calculateAge
     */
    calculateAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    /**
     * @method getPage
     */
    getPage() {

        if (this.state.allMentees === null || this.state.mentees === null) {
            return <div></div>
        }

        const count = this.state.mentees.length;
        let page = (this.state.item + 1);

        if (count === 0) {
            page = 0;
        }

        return (
            <div className="pages">
                <div
                    className="arrow"
                    onClick={() => {
                        if (this.props.disableUI) { return; }
                        if (this.state.item === 0) { return; }
                        this.setState({
                            item: this.state.item - 1
                        })
                    }}
                >
                    <ArrowLeft />
                </div>
                <div className="page-info">
                    {page + " / " + count}
                </div>
                <div
                    className="arrow"
                    onClick={() => {
                        if (this.props.disableUI) { return; }
                        if (this.state.item + 1 === count) { return; }
                        this.setState({
                            item: this.state.item + 1
                        })
                    }}
                >
                    <ArrowRight />
                </div>
            </div>
        )
    }

    /**
     * @method getFilter
     */
    getFilter() {

        if (this.props.keywords === null || this.state.mentees === null) {
            return <div></div>
        }

        return (
            <ExpansionPanel
                className="filters"
                expanded={this.state.filterExpanded}
                onChange={() => {
                    this.setState({
                        filterExpanded: !this.state.filterExpanded
                    })
                }}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Filters
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="filter-container">
                    <div className="row">
                        <DropDown
                            label="Shortlist Only"
                            form="findMentee"
                            field="shortlist"
                            data={this.props.findMentee.shortlist}
                            update={this.props.updateField}
                            options={[
                                {
                                    label: "Yes",
                                    value: "Yes"
                                },
                                {
                                    label: "No",
                                    value: "No"
                                }
                            ]}
                            disabled={this.props.disableUI}
                        />
                    </div>
                    <div className="row">
                        <p className="extra-label">
                            First Language
                        </p>
                        <AutoComplete
                            label=""
                            suggestions={this.props.keywords.languages.map((data) => {
                                return { label: data }
                            })}

                            noAddKeywords
                            form="findMentee"
                            field="firstLang"
                            data={this.props.findMentee.firstLang}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                            contains
                        />
                    </div>
                    <div className="row">
                        <DropDown
                            label="Research Field"
                            form="findMentee"
                            field="researchField"
                            data={this.props.findMentee.researchField}
                            update={this.props.updateField}
                            options={getOptions("researchField")}
                            disabled={this.props.disableUI}
                        />
                    </div>
                    <div className="row multi">
                        <p className="extra-label">
                            keywords describing interests:
                        </p>
                        <KeywordField
                            suggestions={this.props.keywords.interests.map((data) => {
                                return { label: data }
                            })}
                            form="findMentee"
                            field="interests"
                            data={this.props.findMentee.interests}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                            noAddKeywords
                        />
                    </div>
                    <div className="row">
                        <DropDown
                            label="GMT timezone offset"
                            form="findMentee"
                            field="timezone"
                            data={this.props.findMentee.timezone}
                            update={this.props.updateField}
                            options={getOptions("timezone")}
                            disabled={this.props.disableUI}
                        />
                    </div>
                    <div className="row buttons">
                        <Button
                            type="button"
                            text="apply filter"
                            disabled={this.props.disableUI}
                            className="positive-back"
                            onClick={this.applyFilter.bind(this)}
                        />
                        <Button
                            type="button"
                            text="clear filter"
                            disabled={this.props.disableUI}
                            className="negative-back"
                            onClick={this.clearFilter.bind(this)}
                        />
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    /**
     * @method applyFilter
     */
    applyFilter() {

        let mentees = [];

        for (let i = 0; i < this.props.allMentees.length; i++) {
            let firstLang = true;
            let researchField = true;
            let interests = true;
            let timezone = true;
            let shortlist = true;
            let item = this.props.allMentees[i];
            let applicant = false; // Is this actually someone without a mentor
            let isactive = false; // Check they've activated their account

            if (this.props.findMentee.firstLang.value !== "") {
                if (this.props.findMentee.firstLang.value !== item.firstLang) {
                    firstLang = false;
                }
            }

            if (this.props.findMentee.researchField.value !== "") {
                if (this.props.findMentee.researchField.value !== item.fieldOfStudy) {
                    researchField = false;
                }
            }

            if (this.props.findMentee.interests.value.length !== 0) {
                let found = false;
                for (let a = 0; a < this.props.findMentee.interests.value.length; a++) {
                    for (let b = 0; b < item.interests.length; b++) {
                        if (item.interests[b] === this.props.findMentee.interests.value[a]) {
                            found = true;
                        }
                    }
                }
                if (!found) {
                    interests = false;
                }
            }

            if (this.props.findMentee.timezone.value !== "") {
                if (this.props.findMentee.timezone.value !== item.timezone) {
                    timezone = false;
                }
            }

            if(this.props.findMentee.shortlist.value === "Yes") {
                let found = false;
                for (let c = 0; c < this.state.shortlist.length; c++) {
                    let shortlistItem = this.state.shortlist[c];
                    if(shortlistItem === item.email) {
                        found = true;
                    }
                }
                if (!found) {
                    shortlist = false;
                }
            }
            // console.log("findMentees - item", item);
            if(item.mentor === ""){
                applicant = true;
            }

            if(item.status === "active"){
                isactive = true;
            }

            //check if this item passed all filters
            if (firstLang && researchField && interests && timezone && shortlist && applicant && isactive) {
                mentees.push(item)
            }
        }

        this.setState({
            filterExpanded: false,
            mentees: mentees,
            item: 0
        })
    }

    /**
     * @method clearFilter
     */
    clearFilter() {
        this.props.clearForm("findMentee");
        this.setState({
            filterExpanded: false,
            mentees: this.props.allMentees.filter((data) => data.mentor === "" && data.status === "active").map((data) => data)
        })
    }

    /** 
     * @method render
     */
    render() {
        
        if (this.state.view === "success") {
            let msg = "The selected mentee has been assigned to you.";
            if (this.props.user.additionalInfo.mentees.length < 2) {
                msg = msg + " You may return to this page to select another mentee if you have availability."
            }
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg={msg}
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Something went wrong. Please contact support."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        let theText = "Find and select the two mentees " +
        "that you would like to mentor. Please keep in mind that " +
        "students from developing countries and historically " +
        "disadvantaged groups should be prioritised if possible. " + 
        "Remember also to keep in mind practicalities such as " +
        "timezone when considering mentees to ensure you will be " +
        "able to have comfortable sessions."

        if(this.props.user != null && this.props.user.additionalInfo.mentees.length >= 2) {
            theText = "You have already selected the maximum number of mentees. " +
            "Please contact admin@supernovafoundation.org if you want to change mentees."
        }
        
        return (
            <div className="route find-mentees">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Select Mentees
                            </h1>
                            <div className="line"></div>
                        </div>
                        <p className="helper-text">{theText}</p>
                        {this.getFilter()}
                        {this.getPage()}
                        {this.getView()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        allMentees: state.mentees.all,
        user: state.user.user,
        findMentee: state.forms.findMentee,
        keywords: state.app.keywords,
        disableUI: state.app.disableUI
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getMenteesReq: () => dispatch(getMenteesReq()),
        setDialog: (data) => dispatch({ type: "SET_DIALOG", payload: data }),
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({ type: "CLEAR_FORM", payload: from }),
        addMenteeReq: (data) => dispatch(addMenteeReq(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindMentees);