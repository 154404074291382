import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import config from "./config";

let firebaseApp = firebase.initializeApp(config.firebase);

/*/LOCAL DEBUGGING CLOUD FUNCTIONS
if (process.env.NODE_ENV === "development") {
  console.log("TEST");
  const functionsEmulatorPort =
    process.env.REACT_APP_EMU_FUNCTIONS_URL || "http://localhost:5000";
  firebaseApp.functions().useFunctionsEmulator(functionsEmulatorPort);
}
*/

export default firebaseApp;
