import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './styles.scss';

class ButtonClass extends Component {

    /** 
     * @method render
     */
    render() {
        return (
            <div className="button-container">
                <Button
                    type={this.props.type}
                    variant="contained"
                    className={"button " + this.props.className + " " + (this.props.disabled ? "disabled" : "")}
                    onClick={this.props.onClick}
                    fullWidth={this.props.fullWidth}
                    disabled={this.props.disabled}
                >
                    {this.props.text}
                    {
                        this.props.loading ?
                        <div className="spinner-container">
                            <CircularProgress className="spinner" />
                        </div>
                        :
                        <div></div>
                    }
                </Button>
            </div>
        );
    }

}

export default ButtonClass;