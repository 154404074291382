import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import router from './router';
import routes from './routes';
import { changeRoute } from './actions/appActions';
import { getSiteContent } from './actions/siteActions';
import { autoLoginReq } from './actions/userActions';
import AppRouter from './managers/appRouter';
import DialogManager from './managers/DialogManager';
import SiteLoader from './components/siteLoader';
import Footer from './components/footer';
import Menu from './components/menu';
import * as helpers from './modules/helpers';
import version from './version';
import './master.scss';
import * as FullStory from '@fullstory/browser';

//ADMIN - admin@admin.co.za - $up3rn0v@
console.log('%c Version Number: '+ version, 'background: #222; color: #FFFFFF; font-size: 15px; padding: 10px');

if (process.env.NODE_ENV !== "development") {
    FullStory.init({ orgId: 'F4EVT' });
}

//see if a token was passed in
let token = helpers.getParameterByName("token");
if (token !== "") {
    localStorage.setItem("token", token);
}

//check route the user is requesting
let requestedRoute = window.location.pathname;

//check access per route
for (let i = 0; i < routes.length; i++) {
    let item = routes[i];
    if (requestedRoute === item.route) {
        //found route

        if (item.secure) {
            //this is a secure route

            if (localStorage.getItem("token") !== null) {
                //auto login
                store.dispatch(autoLoginReq(false));
            } else {
                //not login info
                store.dispatch(changeRoute("login"))
            }
            
        }

        if(item.website) {
            //this is the website

            if (localStorage.getItem("token") !== null) {
                //login and redirect to dashboard
                store.dispatch(autoLoginReq(true));
            }
        }

    }
}



//gets all site content
store.dispatch(getSiteContent());

//set global function to change route
document.addEventListener('click', function (e) {
    if (e.target.className === "custom-link") {

        //get the actions
        const customAction = document.getElementById(e.target.id).getAttribute("custom-action");;

        //is this an internal route. no need to refresh page
        if (e.target.type === "internalRoute") {
            store.dispatch(changeRoute(customAction));
        }

        //we need to go somewhere else OR email
        if (e.target.type === "externalRoute") {
            window.location.href = customAction;
        }
    }
});

//redner application
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <div>
                <DialogManager />
                <AppRouter />
                <SiteLoader />
                <Menu />
                <div
                    className="body-image"
                    style={{
                        backgroundImage: 'url(' + require('./images/body.jpg') + ')'
                    }}
                >
                </div>
                {/* <video autoPlay muted loop id="myVideo">
                    <source src={require('./images/back-video.mp4')} type="video/mp4" />
                </video> */}
                {router}
                <Footer />
            </div>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//import * as serviceWorker from './serviceWorker';
//serviceWorker.unregister();
