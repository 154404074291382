export default function (state = {
	menuOpen: false,
	accountMenuOpen: false,
	disableUI: true,
	showSiteLoading: true,
	homeContent: null,
	whattoexpect: null,
	theOriginStory: null,
	inMemoriam: null,
	codeOfConductContent: null,
	applyContent: null,
	leadershipTeam: null,
	sponsers: null,
	testimonialsContent: null,
	keywords: null,
	originalKeywords: null,
	windowDimensions: {
		innerHeight: window.innerHeight,
		scrollHeight: document.body.scrollHeight
	},
	dialog: null,
	webinars: null,
	timeslots: null,
	legalData: null
}, action) {
	switch (action.type) {
		case 'SET_WINDOW_DIMENSIONS':
			return {
				...state,
				windowDimensions: action.payload
			}
		case 'TOGGLE_DISABLE_UI':
			return {
				...state,
				disableUI: action.payload
			}
		case 'TOGGLE_SITE_LOADING': {
			return {
				...state,
				disableUI: action.payload,
				showSiteLoading: action.payload,
			}
		}
		case "SET_HOME_CONTENT":
			return {
				...state,
				homeContent: action.payload,
			}
		case "SET_WHAT_TO_EXPECT_CONTENT":
			return {
				...state,
				whattoexpect: action.payload,
			}
		case "SET_THE_ORIGINAL_STORY_CONTENT":
			return {
				...state,
				theOriginStory: action.payload,
			}
		case "SET_IN_MEMORIAM_CONTENT":
			return {
				...state,
				inMemoriam: action.payload,
			}
		case "SET_CODE_OF_CONDUCT_CONTENT":
			return {
				...state,
				codeOfConductContent: action.payload,
			}
		case "SET_APPLY_CONTENT":
			return {
				...state,
				applyContent: action.payload,
			}
		case "SET_LEADERSHIP_TEAM":
			return {
				...state,
				leadershipTeam: action.payload,
			}
		case "SET_SPONSORS":
			return {
				...state,
				sponsors: action.payload,
			}
		case "SET_TESTIMONIALS_CONTENT":
			return {
				...state,
				testimonialsContent: action.payload,
			}
		case "SET_DIALOG":
			return {
				...state,
				dialog: action.payload,
			}
		case "TOGGLE_MENU":
			return {
				...state,
				menuOpen: action.payload
			}
		case "TOGGLE_ACCOUNT_MENU":
			return {
				...state,
				accountMenuOpen: action.payload
			}
		case "SET_WEBINARS":
			return {
				...state,
				webinars: action.payload
			}
		case "SET_KEYWORDS":
			return {
				...state,
				keywords: action.payload,
				originalKeywords: action.payload
			}
		case "ADD_KEYWORD":


			let keywords = [...state.keywords[action.payload.keyword]]

			keywords.push(action.payload.value)

			return {
				...state,
				keywords: {
					...state.keywords,
					[action.payload.keyword]: keywords
				}
			}
		case "RESET_KEYWORDS":
			return {
				...state,
				keywords: {
					...state.keywords,
					[action.payload]: state.originalKeywords[action.payload]
				}
			}
		case "SET_TIMESLOT":
			return {
				...state,
				timeslots: action.payload
			}
		case "SET_LEGAL_WORDS":
			return {
				...state,
				legalData: action.payload
			}
		default:
	}
	return state;
}
