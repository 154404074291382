import firebase from '../firebase';
const storageRef = firebase.storage().ref();

export default (location, file) => {
	return new Promise((resolve, reject) => {

		//create upload task
		const uploadTask = storageRef.child(location).put(file);

		// Register three observers:
		// 1. 'state_changed' observer, called any time the state changes
		// 2. Error observer, called on failure
		// 3. Completion observer, called on successful completion
		uploadTask.on('state_changed', function (snapshot) {

			// Observe state change events such as progress, pause, and resume
			// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
			// let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

		}, function (error) {
			// Handle unsuccessful uploads
			return reject("We seem to be having issues connecting to our servers. Please check your internet connection.");
		}, function () {

			// Handle successful uploads on complete
			// For instance, get the download URL: https://firebasestorage.googleapis.com/...
			uploadTask.snapshot.ref.getDownloadURL()
				.then((downloadURL) => {

					return resolve(downloadURL);

				});

		});


	})
}