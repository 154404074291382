import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { validateField } from "../../modules/formValidator";
import "./styles.scss";

class InputField extends Component {
  state = {
    value: "",
    initialValue: null
  };

  /**
   * @method componentDidMount
   */
  componentDidMount() {
    if (this.props.defaultValue) {
      if (this.props.defaultValue !== "") {
        this.updateField(this.props.defaultValue, "", false);
      }
    }
    this.setState({ initialValue: this.props.data.value });
    this.setState({ value: this.props.data.value });
  }

  componentDidUpdate(prevProps) {
    if (this.state.initialValue !== this.props.data.value) {
      this.setState({ initialValue: this.props.data.value });
      this.setState({ value: this.props.data.value });
    } else {
      if (prevProps.data.value !== this.props.data.value) {
        this.setState({ value: this.props.data.value });
        console.log({ stateUpdate: this.props.data.value })
      }
    }
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      validateField(
        this.props.form,
        this.props.field,
        this.props.data.validations,
        this.props.data.value
      );
      this.updateField(this.state.value, "", false);
    }
  }

  /**
   * @method handleChange
   */
  handleChange(e) {
    let value = e.target.value;

    //character limit
    if (this.props.maxLength) {
      if (value.length > +this.props.maxLength) {
        return;
      }
    }

    //word limit
    if (!this.wordLimit(value)) {
      return;
    }

    this.setState({ value });
  }

  /**
   * @method updateField
   */
  updateField(value, errorMsg, validated) {
    this.props.update({
      form: this.props.form,
      field: this.props.field,
      data: {
        ...this.props.data,
        validated: validated,
        errorMsg: errorMsg,
        value: value,
      },
    });
  }

  /**
   * @method wordLimit
   */
  wordLimit(value) {
    if (!this.props.wordLimit) {
      return true;
    }

    let words = value.split(/\s+/);
    let maxWords = this.props.wordLimit;
    let numWords = words.length;

    if (numWords > maxWords) {
      return false;
    }

    return true;
  }

  /**
   * @method getLabel
   */
  getLabel() {
    if (this.props.data.errorMsg !== "") {
      if (this.props.label === "") {
        return this.props.data.errorMsg;
      }

      return this.props.label + " - " + this.props.data.errorMsg;
    }

    return this.props.label;
  }

  /**
   * @method onBlur
   */
  onBlur() {
    validateField(
      this.props.form,
      this.props.field,
      this.props.data.validations,
      this.props.data.value
    );
    this.updateField(this.state.value, "", false);
  }

  /**
   * @method render
   */
  render() {
    return (
      <div className="input-field-container">
        <TextField
          label={this.getLabel()}
          className={
            "input-field " + (this.props.data.errorMsg !== "" ? "error" : "")
          }
          value={this.state.value}
          placeholder={this.props.label}
          onKeyDown={this.handleKeyDown}
          onChange={this.handleChange.bind(this)}
          margin="normal"
          type={this.props.type}
          multiline={this.props.multiline}
          maxLength={this.props.maxLength}
          disabled={this.props.disabled}
          error={this.props.data.errorMsg !== ""}
          onBlur={this.onBlur.bind(this)}
          fullWidth
        />
      </div>
    );
  }
}

export default InputField;
