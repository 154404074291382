import firebase from '../firebase';

/** 
 * @method getMentorsReq
 */
export const getMentorsReq = () => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "SET_MENTORS", payload: null });
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get all applications
			const getMentors = firebase.functions().httpsCallable('getMentorsReq');
			const getMentorsRes = await getMentors();
			
			if(getMentorsRes.data.success) {
				dispatch({ type: "SET_MENTORS", payload: getMentorsRes.data.mentors });
			} else {
				dispatch({ type: "SET_MENTORS", payload: [] });
			}


		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method getMentorsLimitedReq
 */
export const getMentorsLimitedReq = () => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "SET_MENTORS_LIMITED", payload: null });
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get all applications
			const getMentors = firebase.functions().httpsCallable('getMentorsLimitedReq');
			const getMentorsRes = await getMentors();
			
			if(getMentorsRes.data.success) {
				dispatch({ type: "SET_MENTORS_LIMITED", payload: getMentorsRes.data.mentors });
			} else {
				dispatch({ type: "SET_MENTORS_LIMITED", payload: [] });
			}


		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method checkIfAlreadySendFeedbackReq
 */
export const checkIfAlreadySendFeedbackReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const checkIfAlreadySendFeedback = firebase.functions().httpsCallable('checkIfAlreadySendFeedbackReq');
			const checkIfAlreadySendFeedbackRes = await checkIfAlreadySendFeedback({
				token: localStorage.getItem("token"),
				email: data.email,
				type: "mentor"
			});

			if(checkIfAlreadySendFeedbackRes.data.success) {
				data.success(checkIfAlreadySendFeedbackRes.data);
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}


/** 
 * @method mentorFeedbackReq
 */
export const mentorFeedbackReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//add record to the database
			const mentorFeedback = firebase.functions().httpsCallable('mentorFeedbackReq');
			const mentorFeedbackRes = await mentorFeedback({
				token: localStorage.getItem("token"),
				docID: data.docID,
				date: data.date,
				submit: data.submit
			});
			
			if(mentorFeedbackRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}