import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputField from '../../components/inputField';
import KeywordField from '../../components/keywordField';
import DropDown from '../../components/dropDown';
import Button from '../../components/button';
import RadioButton from '../../components/radioButton';
import Success from '../../components/success';
import Failed from '../../components/failed';
import AutoComplete from '../../components/autoComplete';
import CheckBox from '../../components/checkBox';
import getOptions from '../../modules/getOptions';
import { validateForm } from "../../modules/formValidator";
import { changeRoute } from '../../actions/appActions';
import { updateProfileReq } from '../../actions/userActions';
import './styles.scss';

class Profile extends Component {

    state = {
        view: "start",
        errorMsg: "",
        setFields: false,
        profileNewPic: null,
        profilePicSrc: "",
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.setFields();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.setFields();
    }

    /** 
     * @method setFields
     */
    setFields() {
        //we need to ignore the fields that do not apply to the user and set default values
        if (!this.state.setFields && this.props.loggedIn) {

            this.setState({
                setFields: true
            })

            //mentor
            if (this.props.user.role === "mentor") {

                //ignore
                this.props.addIgnore({ form: "profile", field: "fieldOfStudy" });
                this.props.addIgnore({ form: "profile", field: "degreeStudying" });
                this.props.addIgnore({ form: "profile", field: "yearOfStudy" });
                this.props.addIgnore({ form: "profile", field: "aboutYourself" });
                this.props.addIgnore({ form: "profile", field: "whyYouAreStudyingPhysics" });
                this.props.addIgnore({ form: "profile", field: "whyYouWantToJoin" });

                //set values
                this.updateField("bio", this.props.user.additionalInfo.bio);
                this.updateField("position", this.props.user.additionalInfo.position);
                this.updateField("researchField", this.props.user.additionalInfo.researchField);
                this.updateField("showBio", this.props.user.additionalInfo.showBio);
                this.updateField("highestQualification", this.props.user.additionalInfo.highestQualification);

            } else {
                //mentee

                //ignore
                this.props.addIgnore({ form: "profile", field: "bio" })
                this.props.addIgnore({ form: "profile", field: "position" })
                this.props.addIgnore({ form: "profile", field: "researchField" })
                this.props.addIgnore({ form: "profile", field: "showBio" })
                this.props.addIgnore({ form: "profile", field: "highestQualification" })

                //set values
                this.updateField("fieldOfStudy", this.props.user.additionalInfo.fieldOfStudy);
                this.updateField("degreeStudying", this.props.user.additionalInfo.degreeStudying);
                this.updateField("yearOfStudy", this.props.user.additionalInfo.yearOfStudy);
                this.updateField("aboutYourself", this.props.user.additionalInfo.aboutYourself);
                this.updateField("whyYouAreStudyingPhysics", this.props.user.additionalInfo.whyYouAreStudyingPhysics);
                this.updateField("whyYouWantToJoin", this.props.user.additionalInfo.whyYouWantToJoin);

            }

            //set profile pic
            this.setState({
                profilePicSrc: this.props.user.additionalInfo.image,
            })

            //set fields that apply for both
            this.updateField("email", this.props.user.email);
            this.updateField("firstName", this.props.user.additionalInfo.firstName);
            this.updateField("surname", this.props.user.additionalInfo.surname);
            this.updateField("nationality", this.props.user.additionalInfo.nationality);
            this.updateField("firstLang", this.props.user.additionalInfo.firstLang);
            this.updateField("image", this.props.user.additionalInfo.image);
            this.updateField("country", this.props.user.additionalInfo.country);
            this.updateField("university", this.props.user.additionalInfo.university);
            this.updateField("interests", this.props.user.additionalInfo.interests);
            this.updateField("timezone", this.props.user.additionalInfo.timezone);
        }
    }

    /**
     * @method updateField
     */
    updateField(field, value) {
        if(value === undefined || value === null) {
            value = "";
        }
        this.props.updateField({
            form: "profile",
            field: field,
            data: {
                validated: true,
                errorMsg: "",
                value: value
            }
        })
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("profile");
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above including profile picture."
            })
            return;
        }

        //default form
        let submit = {
            newEmail: this.props.profile.email.value,
            emailChanged: this.props.user.email !== this.props.profile.email.value,
            firstName: this.props.profile.firstName.value,
            surname: this.props.profile.surname.value,
            nationality: this.props.profile.nationality.value,
            firstLang: this.props.profile.firstLang.value,
            country: this.props.profile.country.value,
            university: this.props.profile.university.value,
            interests: this.props.profile.interests.value,
            timezone: this.props.profile.timezone.value,
        }

        if (this.props.user.role === "mentor") {
            submit.bio = this.props.profile.bio.value;
            submit.position = this.props.profile.position.value;
            submit.researchField = this.props.profile.researchField.value;
            submit.showBio = this.props.profile.showBio.value;
            submit.highestQualification = this.props.profile.highestQualification.value;
        } else {
            //mentee
            submit.fieldOfStudy = this.props.profile.fieldOfStudy.value;
            submit.degreeStudying = this.props.profile.degreeStudying.value;
            submit.yearOfStudy = this.props.profile.yearOfStudy.value;
            submit.aboutYourself = this.props.profile.aboutYourself.value;
            submit.whyYouAreStudyingPhysics = this.props.profile.whyYouAreStudyingPhysics.value;
            submit.whyYouWantToJoin = this.props.profile.whyYouWantToJoin.value;
        }

        //all good lets submit
        this.props.updateProfileReq({
            submit: submit,
            files: {
                image: this.state.profileNewPic
            },
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("profile");
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }

    /**
     * @method changeProfilePic
     */
    changeProfilePic() {
        this.refs.profileUpload.click();
    }

    /**
     * @method profileUploaded
     */
    profileUploaded(e) {
        e.stopPropagation();
        e.preventDefault();

        //get file
        let file = e.target.files[0];

        if (file === undefined) {
            return;
        }

        if (!file.type.includes("image")) {
            this.props.setDialog({
                title: "Error",
                text: "You can only upload images files.",
                actions: [
                    {
                        text: "OK",
                        action: () => { }
                    }
                ]
            })
            return;
        }

        this.updateField("image", file);

        const reader = new FileReader();
        reader.onload = (e) => {
            this.setState({
                profileNewPic: file,
                profilePicSrc: e.target.result
            })
        };
        reader.readAsDataURL(file)

    }

    /**
     * @method getForm
     */
    getForm() {

        if (!this.props.loggedIn || this.props.keywords === null) {
            return <div></div>
        }

        //email
        const email = (
            <div className="row">
                <InputField
                    type="text"
                    label="Email address"
                    form="profile"
                    field="email"
                    data={this.props.profile.email}
                    update={this.props.updateField}
                    disabled={this.props.disableUI}
                />
            </div>
        )

        //names
        const names = (
            <div>
                <div className="row">
                    <InputField
                        type="text"
                        label="First Name"
                        form="profile"
                        field="firstName"
                        data={this.props.profile.firstName}
                        update={this.props.updateField}
                        disabled={this.props.disableUI}
                    />
                </div>
                <div className="row">
                    <InputField
                        type="text"
                        label="Family name (surname)"
                        form="profile"
                        field="surname"
                        data={this.props.profile.surname}
                        update={this.props.updateField}
                        disabled={this.props.disableUI}
                    />
                </div>
            </div>
        )

        //nationality
        const nationality = (
            <div className="row">
                <p className="extra-label">
                    Nationality
                </p>
                <AutoComplete
                    label=""
                    suggestions={getOptions("countries")}
                    noAddKeywords
                    form="profile"
                    field="nationality"
                    data={this.props.profile.nationality}
                    update={this.props.updateField}
                    disabled={this.props.disableUI}
                    contains
                />
            </div>
        )

        //first lang
        const firstLang = (
            <div className="row">
                <p className="extra-label">
                    First Language
                </p>
                <AutoComplete
                    label=""
                    suggestions={this.props.keywords.languages.map((data) => {
                        return { label: data }
                    })}
                    addSuggestion={(value) => {
                        this.props.addKeyword({
                            value: value,
                            keyword: "languages"
                        })
                    }}
                    resetKeywords={() => {
                        this.props.resetKeywords("languages");
                    }}
                    form="profile"
                    field="firstLang"
                    data={this.props.profile.firstLang}
                    update={this.props.updateField}
                    disabled={this.props.disableUI}
                    contains
                />
            </div>
        )

        //JSX for the timezone handler
        const timezone = (
            <div className="row">
                <DropDown
                    label="GMT timezone offset"
                    form="profile"
                    field="timezone"
                    data={this.props.profile.timezone}
                    update={this.props.updateField}
                    options={getOptions("timezone")}
                    disabled={this.props.disableUI}
                />
            </div>
        )

        //JSX for the profile pic handler
        const altPic = "https://firebasestorage.googleapis.com/v0/b/thesupernovafoundation.appspot.com/o/profilePictures%2Falt-pic.jpeg?alt=media&token=7eaf09a5-47b5-4778-9ff8-56be8ab31cb6";
        let picSrc = this.state.profilePicSrc === "" ? altPic : this.state.profilePicSrc;
        const profilePic = (
            <div className="profile-pic" onClick={this.changeProfilePic.bind(this)}>
                <img key={picSrc} alt="" src={picSrc} />
                <div className="change">
                    <div className="overlay"></div>
                    <span>CHANGE</span>
                </div>
                <input
                    ref="profileUpload"
                    type="file"
                    style={{
                        display: "none"
                    }}
                    accept="image/*"
                    onChange={this.profileUploaded.bind(this)}
                />
            </div>
        )

        //JSX for the interests handler
        const interests = (
            <div className="row multi">
                <p className="extra-label">
                    keywords describing what you're interested in (e.g. black holes, cosmology, gravitational waves):
            </p>
                <KeywordField
                    suggestions={this.props.keywords.interests.map((data) => {
                        return { label: data }
                    })}
                    form="profile"
                    field="interests"
                    data={this.props.profile.interests}
                    addSuggestion={(value) => {
                        this.props.addKeyword({
                            value: value,
                            keyword: "interests"
                        })
                    }}
                    resetKeywords={() => {
                        this.props.resetKeywords("interests");
                    }}
                    update={this.props.updateField}
                    disabled={this.props.disableUI}
                />
            </div>
        )

        //return form for a mentor
        if (this.props.user.role === "mentor") {
            return (
                <div>
                    {profilePic}
                    {email}
                    {names}
                    {nationality}
                    {firstLang}
                    <div className="row">
                        <p className="extra-label">
                            Country of current residence
                        </p>
                        <AutoComplete
                            label=""
                            suggestions={getOptions("countries")}
                            noAddKeywords
                            form="profile"
                            field="country"
                            data={this.props.profile.country}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                            contains
                        />
                    </div>
                    <div className="row">
                        <p className="extra-label">
                            University or Institute
                        </p>
                        <AutoComplete
                            suggestions={this.props.keywords.universities.map((data) => {
                                return { label: data }
                            })}
                            addSuggestion={(value) => {
                                this.props.addKeyword({
                                    value: value,
                                    keyword: "universities"
                                })
                            }}
                            resetKeywords={() => {
                                this.props.resetKeywords("universities");
                            }}
                            form="profile"
                            field="university"
                            data={this.props.profile.university}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                        />
                    </div>
                    <div className="row radio">
                        <RadioButton
                            label="Highest qualification achieved"
                            form="profile"
                            field="highestQualification"
                            data={this.props.profile.highestQualification}
                            update={this.props.updateField}
                            options={getOptions("highestQualification")}
                            disabled={this.props.disableUI}
                            otherOn
                        />
                    </div>
                    <div className="row">
                        <InputField
                            type="text"
                            label="Position"
                            form="profile"
                            field="position"
                            data={this.props.profile.position}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                        />
                    </div>
                    <div className="row">
                        <DropDown
                            label="Research Field"
                            form="profile"
                            field="researchField"
                            data={this.props.profile.researchField}
                            update={this.props.updateField}
                            options={getOptions("researchField")}
                            disabled={this.props.disableUI}
                        />
                    </div>
                    {interests}
                    {timezone}
                    <div className="row multi">
                        <p className="extra-label">
                            {"One paragraph bio for our website. "}
                            <span
                                className="link"
                                onClick={() => {

                                    if (this.props.disableUI) {
                                        return;
                                    }

                                    this.props.changeRoute("mentorbios")
                                }}
                            >
                                Click here to see examples.
                            </span>
                        </p>
                        <InputField
                            type="text"
                            label=""
                            form="profile"
                            field="bio"
                            data={this.props.profile.bio}
                            update={this.props.updateField}
                            multiline
                            disabled={this.props.disableUI}
                        />
                    </div>
                    <div className="row">
                        <CheckBox
                            label={<p>Make bio public (Mentor bios page)</p>}
                            form="profile"
                            field="showBio"
                            data={this.props.profile.showBio}
                            update={this.props.updateField}
                            disabled={this.props.disableUI}
                        />
                    </div>
                </div>
            )
        }

        //return form for a mentee
        return (
            <div>
                {profilePic}
                {email}
                {names}
                {nationality}
                {firstLang}
                <div className="row">
                    <p className="extra-label">
                        Country of current residence
                    </p>
                    <AutoComplete
                        label=""
                        suggestions={getOptions("countries")}
                        noAddKeywords
                        form="profile"
                        field="country"
                        data={this.props.profile.country}
                        update={this.props.updateField}
                        disabled={this.props.disableUI}
                        contains
                    />
                </div>
                <div className="row">
                    <p className="extra-label">
                        University or Institute
                    </p>
                    <AutoComplete
                        suggestions={this.props.keywords.universities.map((data) => {
                            return { label: data }
                        })}
                        addSuggestion={(value) => {
                            this.props.addKeyword({
                                value: value,
                                keyword: "universities"
                            })
                        }}
                        resetKeywords={() => {
                            this.props.resetKeywords("universities");
                        }}
                        form="profile"
                        field="university"
                        data={this.props.profile.university}
                        update={this.props.updateField}
                        disabled={this.props.disableUI}
                    />
                </div>
                <div className="row">
                    <DropDown
                        label="Field of Study"
                        form="profile"
                        field="fieldOfStudy"
                        data={this.props.profile.fieldOfStudy}
                        update={this.props.updateField}
                        options={getOptions("researchField")}
                        disabled={this.props.disableUI}
                    />
                </div>
                {interests}
                <div className="row radio">
                    <RadioButton
                        label="Degree you are studying towards:"
                        form="profile"
                        field="degreeStudying"
                        data={this.props.profile.degreeStudying}
                        update={this.props.updateField}
                        options={getOptions("degrees")}
                        disabled={this.props.disableUI}
                        otherOn
                    />
                </div>
                <div className="row radio">
                    <RadioButton
                        label="Year of study in your current degree:"
                        form="profile"
                        field="yearOfStudy"
                        data={this.props.profile.yearOfStudy}
                        update={this.props.updateField}
                        options={getOptions("yearsOfStudy")}
                        disabled={this.props.disableUI}
                    />
                </div>
                {timezone}
                <div className="row multi">
                    <p className="extra-label">
                        Tell us a bit about yourself, especially your
                        studies, your interests and the successes and
                        difficulties you have faced in your career
                        (maximum 200 words):
                    </p>
                    <InputField
                        type="text"
                        label=""
                        form="profile"
                        field="aboutYourself"
                        data={this.props.profile.aboutYourself}
                        update={this.props.updateField}
                        multiline
                        wordLimit="200"
                        disabled={this.props.disableUI}
                    />
                </div>
                <div className="row multi">
                    <p className="extra-label">
                        Tell us why you chose to study Physics and what
                        you're hoping to do with your degree (maximum 200
                        words)
                    </p>
                    <InputField
                        type="text"
                        label=""
                        form="profile"
                        field="whyYouAreStudyingPhysics"
                        data={this.props.profile.whyYouAreStudyingPhysics}
                        update={this.props.updateField}
                        multiline
                        wordLimit="200"
                        disabled={this.props.disableUI}
                    />
                </div>
                <div className="row multi">
                    <p className="extra-label">
                        Tell us why you want to join the Supernova Foundation
                        and what you hope to get from mentoring (maximum 200
                        words)
                    </p>
                    <InputField
                        type="text"
                        label=""
                        form="profile"
                        field="whyYouWantToJoin"
                        data={this.props.profile.whyYouWantToJoin}
                        update={this.props.updateField}
                        multiline
                        wordLimit="200"
                        disabled={this.props.disableUI}
                    />
                </div>
            </div>
        )
    }

    /**
     * @method checkDisableButton
     */
    checkDisableButton() {

        if (!this.props.loggedIn) {
            return true;
        }

        if (this.props.disableUI) {
            return true;
        }

        if (this.state.profileNewPic !== null) {
            return false;
        }

        if (
            this.props.user.email !== this.props.profile.email.value ||
            this.props.user.additionalInfo.firstName !== this.props.profile.firstName.value ||
            this.props.user.additionalInfo.surname !== this.props.profile.surname.value ||
            this.props.user.additionalInfo.nationality !== this.props.profile.nationality.value ||
            this.props.user.additionalInfo.firstLang !== this.props.profile.firstLang.value ||
            this.props.user.additionalInfo.country !== this.props.profile.country.value ||
            this.props.user.additionalInfo.university !== this.props.profile.university.value ||
            this.props.user.additionalInfo.interests.toString() !== this.props.profile.interests.value.toString() ||
            this.props.user.additionalInfo.timezone !== this.props.profile.timezone.value ||
            this.props.user.additionalInfo.country !== this.props.profile.country.value ||
            this.props.user.additionalInfo.university !== this.props.profile.university.value
        ) {
            return false;
        }

        if (this.props.user.role === "mentor") {
            if (
                this.props.user.additionalInfo.bio !== this.props.profile.bio.value ||
                this.props.user.additionalInfo.position !== this.props.profile.position.value ||
                this.props.user.additionalInfo.researchField !== this.props.profile.researchField.value
            ) {
                return false;
            }
        } else {
            //mentee
            if (
                this.props.user.additionalInfo.fieldOfStudy !== this.props.profile.fieldOfStudy.value ||
                this.props.user.additionalInfo.degreeStudying !== this.props.profile.degreeStudying.value ||
                this.props.user.additionalInfo.yearOfStudy !== this.props.profile.yearOfStudy.value ||
                this.props.user.additionalInfo.aboutYourself !== this.props.profile.aboutYourself.value ||
                this.props.user.additionalInfo.whyYouAreStudyingPhysics !== this.props.profile.whyYouAreStudyingPhysics.value ||
                this.props.user.additionalInfo.whyYouWantToJoin !== this.props.profile.whyYouWantToJoin.value
            ) {
                return false;
            }
        }

        //we do not have changes
        return true;

    }

    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="Profile updated."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Something went wrong. Please contact support."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        console.log("University: " + this.props.profile.university.value);

        return (
            <div className="route profile">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Profile
                            </h1>
                            <div className="line"></div>
                        </div>
                        <p>
                            Note: some fields are
                            keyword-based meaning that you may need to click on the pop-up 
                            box before it will be entered into the field. Please contact
                            admin@supernovafoundation.org if you have any problems.
                        </p>
                        <form onSubmit={this.submit.bind(this)}>
                            {this.getForm()}
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="save"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                            <div className="change-password form-button">
                                <Button
                                    type="button"
                                    text="change password"
                                    className="second"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                    onClick={() => {
                                        this.props.changeRoute("changepassword")
                                    }}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        user: state.user.user,
        profile: state.forms.profile,
        loggedIn: state.user.loggedIn,
        keywords: state.app.keywords
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addIgnore: (data) => dispatch({ type: "ADD_IGNORE", payload: data }),
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({ type: "CLEAR_FORM", payload: from }),
        updateProfileReq: (data) => dispatch(updateProfileReq(data)),
        setDialog: (data) => dispatch({ type: "SET_DIALOG", payload: data }),
        changeRoute: (route) => dispatch(changeRoute(route)),
        addKeyword: (data) => dispatch({ type: "ADD_KEYWORD", payload: data }),
        resetKeywords: (data) => dispatch({ type: "RESET_KEYWORDS", payload: data })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);