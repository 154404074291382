export default [
    "Astrophysics and Cosmology",
    "High Energy Physics",
    "Medical Physics",
    "Biophysics",
    "Earth Sciences",
    "Condensed Matter Physics",
    "Optics",
    "Theoretical Physics",
    "Outreach and Communication",
    "Physics"
]