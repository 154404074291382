import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import './styles.scss';
import InputField from '../inputField';

class RadioButton extends Component {

    state = {
        otherClicked: false,
        inputValue: ""
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        if (this.props.defaultValue) {
            if (this.props.defaultValue !== "") {
                this.updateField(this.props.defaultValue);
            }
        }
    }

    /**
     * @method handleChange
     */
    handleChange(e) {
        this.updateField(e.target.value);
    }

    /**
     * @method updateField
     */
    updateField(value) {

        this.setState({
            otherClicked: (value === "Other")
        })

        if(value === "Other") {
            value = this.state.inputValue;
        }

        this.props.update({
            form: this.props.form,
            field: this.props.field,
            data: {
                ...this.props.data,
                validated: false,
                errorMsg: "",
                value: value
            }
        })
    }

    /**
     * @method getValue
     */
    getValue() {

        if(this.state.otherClicked) {
            return "Other"
        }

        return this.props.data.value;
    }

    /** 
     * @method render
     */
    render() {
        return (
            <div className="radio-button-container">
                <FormControl component="fieldset" className="radio-button">
                    <FormLabel component="legend">{this.props.label}</FormLabel>
                    <RadioGroup
                        aria-label={this.props.label}
                        name={this.props.label}
                        className="radio-button-group"
                        value={this.getValue()}
                        onChange={this.handleChange.bind(this)}
                        disabled={this.props.disabled}
                    >
                        {
                            this.props.options.map((data, index) => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        value={data.value}
                                        control={
                                            <Radio
                                                className={
                                                    "radio-item " + 
                                                    (data.value === this.props.data.value ? "checked" : "") +
                                                    " " +
                                                    (this.props.data.errorMsg !== "" ? "error" : "")
                                                }
                                            />
                                        }
                                        label={data.label}
                                        disabled={this.props.disabled}
                                    />
                                )
                            })
                        }
                        {
                            this.props.otherOn ?
                            <FormControlLabel
                                key={this.props.options.length}
                                value="Other"
                                control={
                                    <Radio
                                        className={
                                            "radio-item " + 
                                            (this.state.otherClicked ? "checked" : "") +
                                            " " +
                                            (this.props.data.errorMsg !== "" ? "error" : "")
                                        }
                                    />
                                }
                                label="Other"
                                disabled={this.props.disabled}
                            />
                            :
                            <div></div>
                        }
                    </RadioGroup>
                    {
                        this.state.otherClicked ?
                        <InputField
                            type="text"
                            label="Other"
                            form={this.props.form}
                            field={this.props.field}
                            data={this.props.data}
                            update={this.props.update}
                            disabled={this.props.disableUI}
                        />
                        :
                        <div></div>
                    }
                </FormControl>
            </div>
        );
    }

}

export default RadioButton;