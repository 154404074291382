import Home from './routes/home';
import WhatToExpect from './routes/whatToExpect';
import TheOriginStory from './routes/theOriginStory';
import CodeOfConduct from './routes/codeOfConduct';
import Apply from './routes/apply';
import Testimonials from './routes/testimonials';
import MenteeApplication from './routes/menteeApplication';
import LeadershipTeam from './routes/leadershipTeam';
import MentorApplication from './routes/mentorApplication';
import MentorBios from './routes/mentorBios';
import InMemoriam from './routes/inMemoriam';
import Sponsors from './routes/sponsors';
import Login from './routes/login';
import RegisterAdmin from './routes/registerAdmin';
import Dashboard from './routes/dashboard';
import Applications from './routes/applications';
import SendEmail from './routes/sendEmail';
import Suggestions from './routes/suggestions';
import Profile from './routes/profile';
import Webinars from './routes/webinars';
import ChangePassword from './routes/changePassword';
import FindMentees from './routes/findMentees';
import ExportData from './routes/exportData';
import MenteeFeedback from './routes/menteeFeedback';
import MentorFeedback from './routes/mentorFeedback';
import RequestMentoringSession from './routes/requestMentoringSession';
import ConfirmMentoringSession from './routes/confirmMentoringSession';
import DisableUser from './routes/disableUser';
import PasswordReset from './routes/passwordReset';
import Your from './routes/your';

export default [
    //UNSECURE ROUTES
    {route: "/", exact: true, secure: false, component: Home, website: true},
    {route: "/whattoexpect", exact: false, secure: false, component: WhatToExpect, website: true},
    {route: "/theoriginstory", exact: false, secure: false, component: TheOriginStory, website: true},
    {route: "/codeofconduct", exact: false, secure: false, component: CodeOfConduct, website: true},
    {route: "/apply", exact: false, secure: false, component: Apply, website: true},
    {route: "/testimonials", exact: false, secure: false, component: Testimonials, website: true},
    {route: "/menteeapplication", exact: false, secure: false, component: MenteeApplication, website: true},
    {route: "/leadershipteam", exact: false, secure: false, component: LeadershipTeam, website: true},
    {route: "/mentorapplication", exact: false, secure: false, component: MentorApplication, website: true},
    {route: "/mentorbios", exact: false, secure: false, component: MentorBios, website: true},
    {route: "/inmemoriam", exact: false, secure: false, component: InMemoriam, website: true},
    {route: "/sponsors", exact: false, secure: false, component: Sponsors, website: true},
    
    //AUTH
    {route: "/login", exact: false, secure: false, component: Login},
    {route: "/passwordreset", exact: false, secure: false, component: PasswordReset},

    //SECURE ROUTES
    {route: "/dashboard", exact: false, secure: true, component: Dashboard},
    {route: "/changepassword", exact: false, secure: true, component: ChangePassword},
 
    //SECURE ROUTES - MENTOR & MENTEE ONLY
    {route: "/suggestions", exact: false, secure: true, component: Suggestions},
    {route: "/profile", exact: false, secure: true, component: Profile},
    {route: "/webinars", exact: false, secure: true, component: Webinars},
    {route: "/confirmmentoringsession", exact: false, secure: true, component: ConfirmMentoringSession},
    {route: "/your", exact: false, secure: true, component: Your},

    //SECURE ROUTES - MENTEE ONLY
    {route: "/menteefeedback", exact: false, secure: true, component: MenteeFeedback, role: "mentee"},
    {route: "/requestmentoringsession", exact: false, secure: true, component: RequestMentoringSession, role: "mentee"},

    //SECURE ROUTES - MENTOR ONLY
    {route: "/findmentees", exact: false, secure: true, component: FindMentees, role: "mentor"},
    {route: "/mentorfeedback", exact: false, secure: true, component: MentorFeedback, role: "mentor"},

    //SECURE ROUTES - ADMIN ONLY
    {route: "/registeradmin", exact: false, secure: true, component: RegisterAdmin, role: "admin"},
    {route: "/applications", exact: false, secure: true, component: Applications, role: "admin"},
    {route: "/sendemail", exact: false, secure: true, component: SendEmail, role: "admin"},
    {route: "/exportdata", exact: false, secure: true, component: ExportData, role: "admin"},
    {route: "/disableuser", exact: false, secure: true, component: DisableUser, role: "admin"},

]
