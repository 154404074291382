import {combineReducers} from 'redux';
import appReducer from './appReducer';
import userReducer from './userReducer';
import formsReducer from './formsReducer';
import applicationsReducer from './applicationsReducer';
import menteesReducer from './menteesReducer';
import mentorsReducer from './mentorsReducer';

const allReducers = combineReducers({
	app: appReducer,
	user: userReducer,
	forms: formsReducer,
	applications: applicationsReducer,
	mentees: menteesReducer,
	mentors: mentorsReducer
})

export default allReducers
