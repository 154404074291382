import firebase from '../firebase';
import { uploadProfilePicture } from '../modules/helpers';

/** 
 * @method menteeApplicationReq
 */
export const menteeApplicationReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//upload profile pic
			data.submit.image = await uploadProfilePicture(data);

			//add record to the database
			const menteeApplication = firebase.functions().httpsCallable('menteeApplicationReq');
			const menteeApplicationRes = await menteeApplication(data.submit);

			if(menteeApplicationRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method mentorApplicationReq
 */
export const mentorApplicationReq = (data) => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//upload profile pic
			data.submit.image = await uploadProfilePicture(data);

			//add record to the database
			const mentorApplication = firebase.functions().httpsCallable('mentorApplicationReq');
			const mentorApplicationRes = await mentorApplication(data.submit);
			
			if(mentorApplicationRes.data.success) {
				data.success();
			} else {
				data.failed();
			}

		} catch (error) {
			console.log(error);
			data.failed();
		}

		//stop loading
		dispatch({ type: "TOGGLE_DISABLE_UI", payload: false });

	}
}

/** 
 * @method getApplicationsReq
 */
export const getApplicationsReq = () => {
	return async (dispatch) => {

		try {
			//start loading
			dispatch({ type: "SET_APPLICATIONS", payload: null });
			dispatch({ type: "TOGGLE_DISABLE_UI", payload: true });

			//get all applications
			const getApplications = firebase.functions().httpsCallable('getApplicationsReq');
			const getApplicationsRes = await getApplications({
				token: localStorage.getItem("token")
			});
			
			if(getApplicationsRes.data.success) {
				dispatch({ type: "SET_APPLICATIONS", payload: getApplicationsRes.data.applications });
			} else {
				dispatch({ type: "SET_APPLICATIONS", payload: [] });
			}


		} catch (error) {
			console.log(error);
		}

		//stop loading
		dispatch({type: "TOGGLE_SITE_LOADING", payload: false});

	}
}

/** 
 * @method approveMentee
 */
export const approveMentee = (data) => {
	return async (dispatch, getState) => {

		try {

			dispatch({type: "TOGGLE_SITE_LOADING", payload: true});

			//add token for security
			data.token = localStorage.getItem("token");

			//get all applications
			const createMentee = firebase.functions().httpsCallable('createMenteeReq');
			await createMentee(data);

			//get all applications again
			dispatch(getApplicationsReq());

		} catch (error) {
			console.log(error);

			dispatch({type: "TOGGLE_SITE_LOADING", payload: false});
			dispatch({
				type: "SET_DIALOG",
				payload: {
					title: "Error",
					text: "Internal server error. Please contact support.",
					actions: [
						{
							text: "OK",
							action: () => {}
						}
					]
				}
			})
		}

	}
}

/** 
 * @method approveMentor
 */
export const approveMentor = (data) => {
	return async (dispatch, getState) => {

		try {

			dispatch({type: "TOGGLE_SITE_LOADING", payload: true});

			//add token for security
			data.token = localStorage.getItem("token");

			//get all applications
			const createMentor = firebase.functions().httpsCallable('createMentorReq');
			await createMentor(data);

			//get all applications again
			dispatch(getApplicationsReq());

		} catch (error) {
			console.log(error);

			dispatch({type: "TOGGLE_SITE_LOADING", payload: false});
			dispatch({
				type: "SET_DIALOG",
				payload: {
					title: "Error",
					text: "Internal server error. Please contact support.",
					actions: [
						{
							text: "OK",
							action: () => {}
						}
					]
				}
			})
		}

	}
}

/** 
 * @method rejectMentee
 */
export const rejectMentee = (data) => {
	return async (dispatch, getState) => {

		try {

			dispatch({type: "TOGGLE_SITE_LOADING", payload: true});

			//add token for security
			data.token = localStorage.getItem("token");

			//get all applications
			const deleteMentee = firebase.functions().httpsCallable('deleteMenteeReq');
			await deleteMentee(data);

			//get all applications again
			dispatch(getApplicationsReq());

		} catch (error) {
			console.log(error);

			dispatch({type: "TOGGLE_SITE_LOADING", payload: false});
			dispatch({
				type: "SET_DIALOG",
				payload: {
					title: "Error",
					text: "Internal server error. Please contact support.",
					actions: [
						{
							text: "OK",
							action: () => {}
						}
					]
				}
			})
		}

	}
}

/** 
 * @method rejectMentor
 */
export const rejectMentor = (data) => {
	return async (dispatch, getState) => {

		try {

			dispatch({type: "TOGGLE_SITE_LOADING", payload: true});

			//add token for security
			data.token = localStorage.getItem("token");

			//get all applications
			const deleteMentor = firebase.functions().httpsCallable('deleteMentorReq');
			await deleteMentor(data);

			//get all applications again
			dispatch(getApplicationsReq());

		} catch (error) {
			console.log(error);

			dispatch({type: "TOGGLE_SITE_LOADING", payload: false});
			dispatch({
				type: "SET_DIALOG",
				payload: {
					title: "Error",
					text: "Internal server error. Please contact support.",
					actions: [
						{
							text: "OK",
							action: () => {}
						}
					]
				}
			})
		}

	}
}