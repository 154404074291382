import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '../../components/button';
import Success from '../../components/success';
import Failed from '../../components/failed';
import DropDown from '../../components/dropDown';
import { validateForm } from "../../modules/formValidator";
import { requestMentoringSessionReq } from '../../actions/menteeActions'
import AddCircle from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';
import Close from '@material-ui/icons/Close';
import './styles.scss';
import getOptions from '../../modules/getOptions';

class RequestMentoringSession extends Component {

    state = {
        view: "start",
        errorMsg: "",
        checkDone: false,
    }

    /**
     * @method componentDidMount
     */
    componentDidMount() {
        this.checkMentor();
    }

    /**
     * @method componentDidMount
     */
    componentDidUpdate() {
        this.checkMentor();
    }


    /**
     * @method checkMentor
     */
    checkMentor() {
        if (!this.state.checkDone && this.props.loggedIn) {

            if (this.props.user.additionalInfo.mentor.length === 0) {
                //this user has no mentees linked to their profile
                return this.setState({
                    view: "noMentor",
                    checkDone: true
                })
            }

            this.setState({
                checkDone: true
            });

        }
    }

    /**
     * @method submit
     */
    submit(e) {
        e.preventDefault();

        //clear errors
        this.setState({
            errorMsg: ""
        })

        //validate form
        const validate = validateForm("requestMentoringSession")
        if (!validate) {
            this.setState({
                errorMsg: "Please check all the fields above."
            })
            return;
        }
        const submit= {
            mentee: this.props.user.email,
            mentor: this.props.user.additionalInfo.mentor,
            timeslots: this.props.requestMentoringSession.timeslots.value,
            timezone: this.props.requestMentoringSession.timezone.value
        }
        console.log(submit);
        //all good lets submit
        this.props.requestMentoringSessionReq({
            submit: {
                mentee: this.props.user.email,
                mentor: this.props.user.additionalInfo.mentor,
                timeslots: this.props.requestMentoringSession.timeslots.value,
                timezone: this.props.requestMentoringSession.timezone.value
            },
            name: this.props.user.additionalInfo.firstName + " " + this.props.user.additionalInfo.surname,
            success: this.success.bind(this),
            failed: this.failed.bind(this)
        })
    }

    /**
     * @method componentWillUnmount
     */
    componentWillUnmount() {
        this.props.clearForm("requestMentoringSession");
    }

    /**
     * @method success
     */
    success() {
        this.setState({
            view: "success"
        })
    }

    /**
     * @method failed
     */
    failed() {
        this.setState({
            view: "failed"
        })
    }


    /**
     * @method updateField
     */
    updateField(value) {
        this.props.updateField({
            form: "requestMentoringSession",
            field: "timeslots",
            data: {
                ...this.props.data,
                validated: false,
                errorMsg: "",
                value: value
            }
        })
    }


    /** 
     * @method render
     */
    render() {

        if (this.state.view === "success") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Success
                                title="Success!"
                                msg="An email has been sent to your mentor to confirm the time for this session."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "failed") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Failed!"
                                msg="Something went wrong. Please contact support."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.view === "noMentor") {
            return (
                <div className="route">
                    <div className="paper">
                        <div className="back"></div>
                        <div className="content">
                            <Failed
                                title="Warning"
                                warning
                                msg="You have not been matched to a mentor yet."
                                routeAfter="dashboard"
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="route request-mentoring-session">
                <div className="paper">
                    <div className="back"></div>
                    <div className="content">
                        <div className="heading">
                            <h1>
                                Request Mentoring Session
                            </h1>
                            <div className="line"></div>
                        </div>
                        
                        <p className="helper-text">Please provide the timeslots you are available for a session. We recommend proposing 5-7 timeslots as your mentor is likely to be quite busy.</p>
                        
                        <form onSubmit={this.submit.bind(this)}>
                            {
                                
                                this.props.requestMentoringSession.timeslots.value.map((data, index) => {
                                    return (
                                        
                                        <div key={index} className="row">
                                            <div className="input-field-container">
                                                <TextField
                                                    id={"datetime-local-" + index}
                                                    label="Proposed time"
                                                    type="datetime-local"
                                                    className={"input-field " + (this.props.requestMentoringSession.timeslots.errorMsg !== "" ? "error" : "")}
                                                    value={data}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={(e) => {
                                                        let currentValue = [...this.props.requestMentoringSession.timeslots.value];
                                                        // console.log('requestMentoringSession index, onChange, ln 219');
                                                        // console.log('mentoringSession.timeslots.value')
                                                        // console.log(currentValue)
                                                        // console.log('e.target.value');
                                                        // console.log(e.target.value);
                                                        currentValue[index] = e.target.value;
                                                        this.updateField(currentValue);
                                                        this.setState({
                                                            errorMsg: ""
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <Close
                                                className="close"
                                                onClick={() => {
                                                    let currentValue = [...this.props.requestMentoringSession.timeslots.value];
                                                    currentValue.splice(index, 1);
                                                    this.updateField(currentValue);
                                                    this.setState({
                                                        errorMsg: ""
                                                    });
                                                }}
                                            />
                                        </div>
                                    )
                                })
                                
                            }
                            <div className="row add-timeslot" onClick={() => {
                                let currentValue = [...this.props.requestMentoringSession.timeslots.value]
                                currentValue.push("");
                                this.updateField(currentValue);
                                this.setState({
                                    errorMsg: ""
                                });
                            }}>
                                <AddCircle />
                                <span>Add a timeslot</span>
                            </div>
                            {
                                this.state.errorMsg !== "" ?
                                    <p className="error">
                                        {this.state.errorMsg}
                                    </p>
                                    :
                                    <div></div>
                            }
                            
                            <div className="row">
                                <DropDown
                                    label="Please indicate the GMT offset of your timezone"
                                    field="timezone"
                                    form="requestMentoringSession"
                                    data={this.props.requestMentoringSession.timezone}
                                    update={this.props.updateField}
                                    options={getOptions("timezone")}
                                    disabled={this.props.disableUI}
                                    
                                />
                            </div>
                            <div className="form-button">
                                <Button
                                    type="submit"
                                    text="send"
                                    fullWidth={true}
                                    disabled={this.props.disableUI}
                                    loading={this.props.disableUI}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disableUI: state.app.disableUI,
        requestMentoringSession: state.forms.requestMentoringSession,
        loggedIn: state.user.loggedIn,
        user: state.user.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateField: (data) => dispatch({ type: "UPDATE_FIELD", payload: data }),
        clearForm: (from) => dispatch({ type: "CLEAR_FORM", payload: from }),
        requestMentoringSessionReq: (data) => dispatch(requestMentoringSessionReq(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestMentoringSession);